import { createContext, useState } from "react";
import { Bank } from "../hooks/useBanksList";

type Context = {
  bank: Bank | null;
  setBank: React.Dispatch<React.SetStateAction<Bank | null>>;
};

const SelectedBankContext = createContext<Context>({
  bank: null,
  setBank() {},
});

const SelectedBankProvider: React.FC = (props) => {
  const [bank, setBank] = useState<Bank | null>(null);

  const value = { bank, setBank };

  return <SelectedBankContext.Provider value={value} {...props} />;
};

export { SelectedBankProvider, SelectedBankContext };
