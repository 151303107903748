import { createContext, useState } from "react";

type Context = {
  timeout: boolean;
  setTimedout: React.Dispatch<React.SetStateAction<boolean>>;
};

const TimeoutContext = createContext<Context>({
  timeout: false,
  setTimedout() {},
});

const TimeoutProvider: React.FC = (props) => {
  const [timeout, setTimedout] = useState(false);

  const value = { timeout, setTimedout };

  return <TimeoutContext.Provider value={value} {...props} />;
};

export { TimeoutProvider, TimeoutContext };
