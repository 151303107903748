import React, { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { ResourcesContext } from "../../../context/consumer";
import parse from "html-react-parser";
import { TextResourcesContext } from "../../../context/textResourcesContext";
import {
  getResource,
  getTextWithPopulatePlaceholders,
} from "../../../utils/helpers";
const Paragraph = styled.p`
  line-height: 2rem;
  span {
    color: ${(props) => props.theme.colors.secondaryAccentText};
  }
`;

function ParagraphsComponent({ name }: any) {
  const { resources } = useContext(ResourcesContext);
  const { data } = useContext(TextResourcesContext);
  const theme: any = useTheme();
  const { t } = useTranslation();

  return (
    <div>
      <Paragraph id="introHeaderA">
        {parse(
          getResource(data, "CJ_introScreen_paragraphA").replace(
            "<0>{{name}}</0>",
            `<span>${name}</span>`
          )
        )}
      </Paragraph>
      <Paragraph id="introHeaderB">
        {parse(
          getResource(data, "CJ_introScreen_paragraphB").replace(
            "<0>{{name}}</0>",
            `<span>${name}</span>`
          )
        )}
      </Paragraph>
      {theme.settings?.intro?.freeServiceTextVisible && (
        <Paragraph id="introTermsHeaderA">
          {parse(
            getResource(data, "CJ_introScreen_freeServiceText").replace(
              "<0>{{name}}</0>",
              `<span>${name}</span>`
            )
          )}
        </Paragraph>
      )}
      {theme.settings.intro.layout === "v1" && (
        <Paragraph id="introTermsHeaderB">
          {getResource(data, "CJ_introScreen_pleaseRead")}
        </Paragraph>
      )}
    </div>
  );
}

export default ParagraphsComponent;
