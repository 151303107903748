import { useContext, useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import styled, { useTheme } from "styled-components/macro";
import { useHistory, useLocation } from "react-router";

//contexts
import { SelectedBankContext } from "../../context/selectedBank";

//hooks
import { useLender } from "../../hooks/useLender";
import { useRejectConsent } from "../../hooks/useRejectConsent";
import { useOrchestrationToken } from "../../hooks/useOrchestrationToken";

//components
import Progressbar from "../../components/progressbar/progressbar.component";

//layout components
import {
  ScreenContentWrapper,
  ScreenContainer,
  Content,
} from "../layout/screens.layout";

import Navbar from "../../components/navbar/navbar.component";
import CheckboxesComponent from "./components/CheckboxesComponent";
import ButtonsComponent from "./components/ButtonsComponent";
import PermissionHeader from "./components/PermissionHeader";
import PanelComponent from "./components/PanelComponent";
import MoreInformationComponent from "./components/MoreInformationComponent";
import AssuranceItems from "../../components/assuranceSection/AssuranceItems";
import useScreenSize from "../../hooks/useScreenSize";
import { getResource } from "../../utils/helpers";
import { TextResourcesContext } from "../../context/textResourcesContext";

export const CollapsableIconHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    margin-bottom: 0;
  }
`;

export const CollapsableIconContainer = styled.span`
  margin-right: 8px;

  & svg {
    margin-top: -4px;
    width: 100%;
    height: 100%;
    max-width: 20px;
    max-height: 20px;
  }
`;

export const ButtonsFooterContainer = styled.div`
  display: flex;
  padding: 8px 0;
  justify-content: space-between;
  width: 100%;

  margin: 0 auto;

  & .btn {
    padding: 0.8rem 3rem;
    margin-top: 0;
  }

  & .btn:first-of-type {
    margin-right: 16px;
  }
  @media (max-width: 640px) {
    width: 100%;
  }
`;

const ConsentScreen = () => {
  const { t } = useTranslation();
  const { bank } = useContext(SelectedBankContext);
  const { name: lenderName } = useLender();
  const history = useHistory();
  const screenSize = useScreenSize();
  const orchestrationToken = useOrchestrationToken();
  const theme: any = useTheme();

  const state = useLocation<{ consentToken: string }>();
  const { data } = useContext(TextResourcesContext);

  let consentToken = "";

  if (state?.state?.consentToken) {
    consentToken = state.state.consentToken;
  }

  useEffect(() => {
    document.body.style.overflow = "auto";
    if (!bank) {
      return history.push(`/select?orchestrationToken=${orchestrationToken}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    execute: rejectConsent,
    error: rejectConsentError,
    loading: rejectConsentLoading,
  } = useRejectConsent(consentToken);

  const [consent, setConsent] = useState(false);
  const [agree, setAgree] = useState(false);
  const bankName = bank ? bank.name : "";
  useEffect(() => {
    document.title = getResource(data, "CJ_consentScreen_tabTitle");
  }, [data]);
  return (
    <ScreenContentWrapper
      $currentScreenWidth={screenSize.width}
      style={{ ...theme.settings.screenContainer, minHeight: "auto" }}
    >
      {theme?.settings.customBackground ? (
        <div
          className="custom-bg"
          style={theme.settings.customBackground?.styles}
        >
          {" "}
        </div>
      ) : null}
      <div>
        <Navbar />
        <ScreenContainer style={{ minHeight: "auto" }}>
          <Progressbar />
          <Content className="mb-0 mt-4">
            {theme.settings.consent.layout == "v1" && (
              <>
                <PermissionHeader bankName={bankName} bankLogo={bank?.logo} />

                <MoreInformationComponent lenderName={lenderName} />
                <CheckboxesComponent
                  agree={agree}
                  consent={consent}
                  setAgree={setAgree}
                  setConsent={setConsent}
                  lenderName={lenderName}
                />

                <ButtonsComponent
                  rejectConsentLoading={rejectConsentLoading}
                  rejectConsent={rejectConsent}
                  consent={consent}
                  agree={agree}
                  rejectConsentError={rejectConsentError}
                  consentToken={consentToken}
                />
              </>
            )}

            {theme.settings.consent.layout == "v2" && (
              <div className="row">
                <div className="col-12 col-md-5 mt-5">
                  <MoreInformationComponent lenderName={lenderName} />
                </div>
                <PanelComponent>
                  <PermissionHeader bankName={bankName} bankLogo={bank?.logo} />

                  <p
                    className="mb-0"
                    style={{ color: theme.colors.text.toString() }}
                  >
                    {t("consentScreen.confirm")}
                  </p>
                  <CheckboxesComponent
                    agree={agree}
                    consent={consent}
                    setAgree={setAgree}
                    setConsent={setConsent}
                    lenderName={lenderName}
                  />
                  <ButtonsComponent
                    rejectConsentLoading={rejectConsentLoading}
                    rejectConsent={rejectConsent}
                    consent={consent}
                    agree={agree}
                    rejectConsentError={rejectConsentError}
                    consentToken={consentToken}
                  />
                </PanelComponent>
              </div>
            )}
          </Content>
          {theme.settings?.assuranceSection?.visible && (
            <Content className="mt-0">
              <AssuranceItems />
            </Content>
          )}
        </ScreenContainer>
      </div>
    </ScreenContentWrapper>
  );
};

export default ConsentScreen;
