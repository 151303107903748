import { useContext, useEffect, useState } from "react";
import { TimeoutContext } from "../context/timeout";

const useError = () => {
  const { setTimedout } = useContext(TimeoutContext);

  const [error, setError] = useState<any>(null);

  useEffect(() => {
    if (error?.response?.status === 401) {
      setTimedout(true);
    }
  }, [error, setTimedout]);

  return [error, setError];
};

export { useError };
