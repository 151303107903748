import React, { useContext, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import Collapsable from "../../../components/collapse/collapse.component";

import { CollapsableIconHeader } from "../../consent";
import Modal from "../../../components/modal/Modal";
import { ModalWrapper } from "../../select/select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import ContentComponent from "./MoreInformationSectionComponents/ContentComponent";
import { resourceUsage } from "process";
import {
  getResource,
  getTextWithPopulatePlaceholders,
} from "../../../utils/helpers";
import { ResourcesContext } from "../../../context/consumer";
import { TextResourcesContext } from "../../../context/textResourcesContext";
import parse from "html-react-parser";
const MoreInformationWrapper = styled.div`
  white-space: pre-line;
`;
const AddMoreText = styled.p`
  font-size: 1.4rem;
  line-height: 1.2;
  margin-bottom: 0;
`;
const CollapsableBody = styled.p`
  margin-bottom: 0;
  letter-spacing: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2rem;
`;
function MoreInformationComponent() {
  const [showMoreInformationModal, setShowMoreInformationModal] =
    useState(false);
  const { resources } = useContext(ResourcesContext);
  const { data } = useContext(TextResourcesContext);
  const { t } = useTranslation();
  const theme: any = useTheme();

  const collasableArr = [
    <Collapsable
      parentScreen="intro"
      headerStyle={{
        ...theme?.settings?.intro?.accordionSettings?.header?.styles,
      }}
      header={
        <CollapsableIconHeader>
          <AddMoreText id="introScreenQuestion2">
            {getResource(data, "CJ_introScreen_moreInfoAboutOBTitle")}
          </AddMoreText>
        </CollapsableIconHeader>
      }
      iconId="introScreenChevron2"
    >
      <p> {getResource(data, "CJ_introScreen_moreInfoAboutOBDesc1")}</p>

      <p> {getResource(data, "CJ_introScreen_moreInfoAboutOBDesc2")}</p>

      <p> {getResource(data, "CJ_introScreen_moreInfoAboutOBDesc3")}</p>

      <p> {getResource(data, "CJ_introScreen_moreInfoAboutOBDesc4")}</p>

      <p> {getResource(data, "CJ_introScreen_moreInfoAboutOBDesc5")}</p>
    </Collapsable>,
    <Collapsable
      parentScreen="intro"
      headerStyle={{
        ...theme?.settings?.intro?.accordionSettings?.header?.styles,
      }}
      header={
        <CollapsableIconHeader>
          <AddMoreText id="introScreenQuestion1">
            {getResource(data, "CJ_introScreen_moreInfoAboutExperianTitle")}
          </AddMoreText>
        </CollapsableIconHeader>
      }
      iconId="introScreenChevron1"
    >
      <p> {getResource(data, "CJ_introScreen_moreInfoAboutExperianDesc1")}</p>

      <p> {getResource(data, "CJ_introScreen_moreInfoAboutExperianDesc2")}</p>

      <p> {getResource(data, "CJ_introScreen_moreInfoAboutExperianDesc3")}</p>

      <p> {getResource(data, "CJ_introScreen_moreInfoAboutExperianDesc4")}</p>
    </Collapsable>,
    <Collapsable
      parentScreen="intro"
      headerStyle={{
        ...theme?.settings?.intro?.accordionSettings?.header?.styles,
      }}
      header={
        <CollapsableIconHeader>
          <AddMoreText id="introScreenQuestion3">
            {getResource(data, "CJ_introScreen_moreInfoAboutOBLicenseTitle")}
          </AddMoreText>
        </CollapsableIconHeader>
      }
      iconId="introScreenChevron3"
    >
      <p> {getResource(data, "CJ_introScreen_moreInfoAboutOBLicenseDesc1")}</p>

      <p> {getResource(data, "CJ_introScreen_moreInfoAboutOBLicenseDesc2")}</p>

      <p>
        {" "}
        {(() => {
          let text = getResource(
            data,
            "CJ_introScreen_moreInfoAboutOBLicenseDesc3"
          );
          let regex = /<0>(.*?)<\/0>/g;

          let mathes = [];
          let match;
          while ((match = regex.exec(text)) !== null) {
            mathes.push(match[1]);
          }

          text = text.replace(
            "<0>",
            `<a style={theme?.settings?.intro?.texts?.moreInformationSectionLink?.style} href="${mathes[0]}" rel="noreferrer" target="_blank">`
          );

          text = text.replace("</0>", "</a>");
          return parse(text);
        })()}
      </p>
      {/* <Trans i18nKey={"introScreen.infoSectionDescription3_3"}>
          <a
            style={
              theme?.settings?.intro?.texts?.moreInformationSectionLink?.style
            }
            href="http://registers.centralbank.ie/"
            rel="noreferrer"
            target="_blank"
          >
            &nbsp;
          </a>
        </Trans> */}

      <p> {getResource(data, "CJ_introScreen_moreInfoAboutOBLicenseDesc4")}</p>
    </Collapsable>,
  ];

  const settings = theme?.settings?.intro?.accordionSettings?.accordions;

  return (
    <MoreInformationWrapper>
      <div>
        {theme.settings?.intro?.texts?.moreInformationSection?.version ===
        "2" ? (
          <div
            className="d-flex align-items-center"
            style={{
              ...{
                borderBottom: "1px solid #26478D",
              },
              ...theme.settings?.intro?.texts?.moreInformationSection?.container
                ?.style,
            }}
          >
            {!theme.settings?.intro?.texts?.moreInformationSection?.title
              ?.icon ? (
              <FontAwesomeIcon
                icon={faArrowRight}
                className="mr-3"
                size={"2x"}
              />
            ) : theme.settings?.intro?.texts?.moreInformationSection?.title
                ?.icon !== "none" ? (
              <span
                className="mr-3"
                style={{
                  background: `url(${theme.settings?.intro?.texts?.moreInformationSection?.title?.icon})`,
                  backgroundRepeat: "no-repeat",
                  width: "16px",
                  height: "16px",
                  display: "inline-block",
                }}
              ></span>
            ) : null}
            <h4
              className="mb-0 cursor-pointer"
              onClick={() => setShowMoreInformationModal(true)}
              style={{
                ...{
                  color: theme?.colors?.text,
                },
                ...theme.settings?.intro?.texts?.moreInformationSection?.title
                  ?.styles,
              }}
            >
              {getResource(data, "CJ_introScreen_moreInfoHeader")}
            </h4>
          </div>
        ) : (
          <p
            className="mb-0"
            style={
              theme.settings?.intro?.texts?.moreInformationSection?.title
                ?.styles
            }
          >
            {getResource(data, "CJ_introScreen_moreInfoHeader")}
          </p>
        )}

        <Modal>
          <ModalWrapper className={showMoreInformationModal ? "visible" : ""}>
            <div
              className="modal-body w-100 overflow-hidden"
              style={{ height: "80%" }}
            >
              <div className="modal-header">
                <span
                  className="close-btn ml-auto"
                  onClick={() => {
                    setShowMoreInformationModal(false);
                    document.body.style.overflow = "auto";
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    size="lg"
                    color={
                      theme?.colors?.icons?.default ??
                      "var(--exp-dark-grey) !important"
                    }
                  />
                </span>
              </div>

              <ContentComponent />
            </div>
          </ModalWrapper>
        </Modal>
      </div>
      {theme.settings?.intro?.texts?.moreInformationSection?.version !== "2" &&
        collasableArr?.map((collapsable, index) => {
          const collapsableStyle = settings?.[index];
          const mergedStyle = {
            ...collapsable.props.style,
            ...collapsableStyle,
          };

          return React.cloneElement(collapsable, { style: mergedStyle });
        })}
    </MoreInformationWrapper>
  );
}

export default MoreInformationComponent;
