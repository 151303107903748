import React, { useContext } from "react";
import Snakbar from "../../components/snackbar";
import { SnackbarContext } from "../../context/snackbar";

interface Props {}

const SnackbarContainer: React.FC<Props> = () => {
  const { show, openSnackbar } = useContext(SnackbarContext);

  return show ? <Snakbar openSnackbar={openSnackbar} /> : null;
};

export default SnackbarContainer;
