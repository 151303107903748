import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useContext, useEffect, useState } from "react";
import styled, { keyframes, useTheme } from "styled-components/macro";
import { Bank } from "../../hooks/useBanksList";
import { Image } from "../image/image.component";
import Loader from "../loading/loading-small.component";
import List from "react-virtualized/dist/es/List";
import AutoSizer from "react-virtualized/dist/es/AutoSizer";
import { Consent } from "../../hooks/useConsentedBanks";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import TopBanks from "./TopBanksComponent";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as PlusIcon } from "../../assets/plus-icon.svg";
import { ReactComponent as ErrorIcon } from "../../assets/error-icon.svg";
import { ResourcesContext } from "../../context/consumer";
import Fuse from "fuse.js";
import GroupBanksComponent from "./GroupBanksComponent";
import parse from "html-react-parser";
const Container = styled.div`
  label {
    color: ${(props) => props.theme.colors.primaryAccentText};
  }
`;

export const InputContainer = styled.div`
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
`;

const MenuContainer = styled.div<{ isLoading: boolean }>`
  // height: 280px;
  padding-bottom: 4px;
  padding-top: 4px;
  margin-bottom: 8px;
  position: relative;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  scroll-behavior: smooth;
  border: 1px solid var(--exp-medium-grey);
  ${({ isLoading }) => isLoading && "background-color: rgba(0, 0, 0, 0.04);"}
`;

const OptionContainerOuter = styled.div`
  color: inherit;
  cursor: default;
  display: flex;
  align-items: center;
  font-size: inherit;
  padding: 4px 0;
  width: 99.5% !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  font-size: 16px;
  & > img {
    height: 42px;
  }
`;

const OptionContainer = styled(OptionContainerOuter)<{
  disabled?: boolean;
  selected: boolean;
  hasHover: boolean;
}>`
  cursor: pointer !important;
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.colors.banks.bankText};
  padding: 0 7px;
  ${({ selected, theme }) =>
    selected &&
    `background-color: ${theme.colors.banks.bankSelectedBackground};
    
    `}
  ${({ selected, theme }) =>
    selected && `color: ${theme.colors.banks.bankSelectedText}`}}
 
  ${({ disabled }) =>
    disabled && "background-color: var(--exp-light-grey); cursor: default;"}
  &:hover {
    background-color: ${({ selected, disabled, hasHover, theme }) =>
      hasHover &&
      !selected &&
      !disabled &&
      theme.colors.banks.bankHoverBackground};
  }
  span {
    margin-right: auto;
  }
  &.Completed {
    color: ${(props) => props.theme.colors.banks.successBankText};
    background-color: ${(props) =>
      props.theme.colors.banks.successBankBackground};
    &:hover {
      background: ${(props) =>
        darken(0.02, props.theme.colors.banks.successBankBackground)};
      color: ${(props) => props.theme.colors.banks.successBankHoverText};
    }
  }
  &.Processing {
    color: ${(props) => props.theme.colors.banks.processingBankText};
    background-color: ${(props) =>
      props.theme.colors.banks.processingBankBackground};
    &:hover {
      background: ${(props) =>
        darken(0.02, props.theme.colors.banks.processingBankBackground)};
      color: ${(props) => props.theme.colors.banks.processingBankText};
    }
  }
  &.Error {
    color: ${(props) => props.theme.colors.banks.failedBankText};
    background-color: ${(props) =>
      props.theme.colors.banks.failedBankBackground};
    &:hover {
      background: ${(props) =>
        darken(0.02, props.theme.colors.banks.failedBankBackground)};
      color: ${(props) => props.theme.colors.banks.failedBankText};
    }
  }
  &.selected {
    &.Error {
      background: ${(props) =>
        darken(0.05, props.theme.colors.banks.failedBankBackground)};
    }
    &.Completed {
      background: ${(props) =>
        darken(0.05, props.theme.colors.banks.successBankBackground)};
    }
    &.Processing {
      background: ${(props) =>
        darken(0.05, props.theme.colors.banks.processingBankBackground)};
    }
  }
`;

export const Input = styled.input<{ $background?: string }>`
  // color: ${(props) => props.theme.colors.text};
  width: 100%;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding-left: 12px;
  background: ${(props) => props.$background}
  outline: none;
  font-size: 1.6rem;
  -webkit-appearance: none;

  &:focus {
    -webkit-box-shadow: 0 0 0 1px var(--exp-light-blue);
    box-shadow: 0 0 0 1px var(--exp-light-blue);
  }
`;
const SearchIconContainer = styled.div`
  position: absolute;
  right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translate3d(0, -50%, 0);
`;
const SearchIcon = styled(FontAwesomeIcon)`
  color: ${(props) =>
    props.theme.colors.icons.default || "var(--exp-light-blue)"};
`;
const spinnerAnimation = keyframes`
 from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const IconSpinner = styled.div`
  animation: ${spinnerAnimation} 0.9s linear infinite;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;

  &:before {
    content: "";
    border-bottom: solid 1px #888;
    width: 80px;
    @media (max-width: 767px) {
      width: auto;
      flex: 1 1 auto;
    }
  }

  &:after {
    content: "";
    flex: 1 1 auto;
    border-bottom: solid 1px #888;
    width: 100px;
    @media (max-width: 767px) {
      width: auto;
    }
  }

  span {
    flex: 0 1 auto;
    padding: 0 15px 0 15px;
    color: ${(props) => props.theme.colors.text};
  }
`;
interface OptionProps {
  bank: Bank;
  onClick: () => void;
  selected: boolean;
  style: any;
  tabIndex?: number;
  consents: any[];
}

const OptionLabel: React.FC<OptionProps> = ({
  bank,
  onClick,
  selected,
  tabIndex = 1,
  consents = [],
  ...rest
}) => {
  const consentedBank = consents.find(
    (c) => c.providerCode === bank.providerCode
  );
  const { t } = useTranslation();
  const theme: any = useTheme();
  const iconCheck = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      style={{ minWidth: "30px", maxWidth: "30px", maxHeight: "30px" }}
    >
      <path
        fill={theme.colors.icons.default}
        d="M32.13,38H8.26a6,6,0,0,1-6-6V8.06a6,6,0,0,1,6-6H32.13a6,6,0,0,1,6,6V31.94A6,6,0,0,1,32.13,38ZM8.26,3.89A4.18,4.18,0,0,0,4.08,8.06V31.94a4.18,4.18,0,0,0,4.18,4.17H32.13a4.18,4.18,0,0,0,4.18-4.17V8.06a4.18,4.18,0,0,0-4.18-4.17Z"
      />
      <path
        fill={theme.colors.icons.default}
        d="M16.92,31.17a.93.93,0,0,1-.65-.27l-8-8a.92.92,0,1,1,1.3-1.3l7.23,7.23L32.54,9.18A.91.91,0,0,1,33.83,9a.92.92,0,0,1,.15,1.3L17.64,30.82a.93.93,0,0,1-.67.35Z"
      />
    </svg>
  );

  const iconX = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      style={{ minWidth: "30px", maxWidth: "30px", maxHeight: "30px" }}
    >
      <path
        fill={theme.colors.icons.default}
        d="M32.13,2H8.26a6,6,0,0,0-6,6V31.94a6,6,0,0,0,6,6H32.13a6,6,0,0,0,6-6V8.06A6,6,0,0,0,32.13,2Zm4.18,29.9a4.18,4.18,0,0,1-4.18,4.17H8.26a4.18,4.18,0,0,1-4.18-4.17V8.06A4.18,4.18,0,0,1,8.26,3.89H32.13a4.18,4.18,0,0,1,4.18,4.17Z"
      />
      <path
        fill={theme.colors.icons.default}
        d="M31.07,9.21a.93.93,0,0,0-1.31,0L20.2,18.7,10.71,9.13a.92.92,0,0,0-1.31,1.3L18.89,20,9.33,29.48a.93.93,0,0,0,0,1.31.91.91,0,0,0,.65.27.92.92,0,0,0,.65-.27l9.56-9.49,9.49,9.57a.92.92,0,0,0,.65.27.91.91,0,0,0,.65-.27.92.92,0,0,0,0-1.3L21.5,20l9.56-9.49A.93.93,0,0,0,31.07,9.21Z"
      />
    </svg>
  );

  const iconPointer = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      style={{ minWidth: "30px", maxWidth: "30px", maxHeight: "30px" }}
    >
      <path
        fill={theme.colors.icons.default}
        d="M31.19,39.17a1,1,0,0,1-.84-1.5A18.72,18.72,0,0,0,33.17,28a16.31,16.31,0,0,0-.53-4.33,1.88,1.88,0,0,0-2.56-1.39l-.94.4-.36-1a1.85,1.85,0,0,0-1.72-1.19,1.8,1.8,0,0,0-1.11.38l-1,.75-.51-1.12a1.85,1.85,0,0,0-2.62-.81l-1.49.9V14.33a1.84,1.84,0,1,0-3.67,0V26.51a1.37,1.37,0,0,1-1.37,1.37l-.29,0-.08,0-3.09-1.26a1.78,1.78,0,0,0-.81-.19,1.83,1.83,0,0,0-.72,3.52l.06,0c4,2.09,6.51,4.4,8.29,7.75a1,1,0,0,1-1.74.93c-1.57-3-3.8-5-7.43-6.92A3.8,3.8,0,0,1,11,24.41a3.89,3.89,0,0,1,1.64.37l2,.83V14.33a3.8,3.8,0,0,1,7.6,0V17.5a3.59,3.59,0,0,1,.47,0,3.8,3.8,0,0,1,2.88,1.33,3.85,3.85,0,0,1,1.39-.26,3.8,3.8,0,0,1,3.13,1.65,4.18,4.18,0,0,1,.61-.05,3.93,3.93,0,0,1,3.74,3v0A18.55,18.55,0,0,1,35.13,28,20.67,20.67,0,0,1,32,38.7,1,1,0,0,1,31.19,39.17Z"
      />
      <path
        fill={theme.colors.icons.default}
        d="M18.62,8.88a1,1,0,0,1-1-1V1.82a1,1,0,1,1,2,0V7.89A1,1,0,0,1,18.62,8.88Z"
      />
      <path
        fill={theme.colors.icons.default}
        d="M31.38,15.23H25.31a1,1,0,1,1,0-2h6.07a1,1,0,1,1,0,2Z"
      />
      <path
        fill={theme.colors.icons.default}
        d="M11.93,15.43H5.85a1,1,0,1,1,0-2h6.08a1,1,0,1,1,0,2Z"
      />
      <path
        fill={theme.colors.icons.default}
        d="M23.26,10.56a1,1,0,0,1-.7-1.68l4.3-4.3A1,1,0,0,1,28.25,6L24,10.27A1,1,0,0,1,23.26,10.56Z"
      />
      <path
        fill={theme.colors.icons.default}
        d="M14,10.56a1,1,0,0,1-.69-.29L9,6A1,1,0,0,1,9,4.58a1,1,0,0,1,1.39,0l4.3,4.3a1,1,0,0,1,0,1.39A1,1,0,0,1,14,10.56Z"
      />
    </svg>
  );

  const iconProccesing = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      style={{
        minWidth: "30px",
        maxWidth: "30px",
        maxHeight: "30px",
        display: "block",
        width: "40px",
      }}
    >
      <path
        fill={theme.colors.icons.default}
        d="M23.63,39.27a1,1,0,0,1-.52-.15L19.49,36.9a1,1,0,0,1-.47-.81,1,1,0,0,1,.17-.59l2.2-3.59a1,1,0,0,1,1.37-.32A1,1,0,0,1,23.08,33l-1.71,2.78,2.78,1.71a1,1,0,0,1-.52,1.83Z"
      />
      <path
        fill={theme.colors.icons.default}
        d="M14.11,35.87a.94.94,0,0,1-.37-.07A16.72,16.72,0,0,1,20,3.57a1,1,0,0,1,0,2A14.75,14.75,0,0,0,14.48,34,1,1,0,0,1,15,35.25,1,1,0,0,1,14.11,35.87Z"
      />
      <path
        fill={theme.colors.icons.default}
        d="M20.17,37a1,1,0,0,1,0-2A14.74,14.74,0,0,0,25.46,6.6a1,1,0,0,1,.74-1.84,16.73,16.73,0,0,1,5.68,27.3A16.57,16.57,0,0,1,20.18,37Z"
      />
      <path
        fill={theme.colors.icons.default}
        d="M17.18,8.71a1,1,0,0,1-.66-.25,1,1,0,0,1-.08-1.4l2.18-2.43L16.2,2.45a1,1,0,0,1-.08-1.39A1,1,0,0,1,17.52,1l3.16,2.84a1,1,0,0,1,.33.85,1.11,1.11,0,0,1-.27.58L17.92,8.38A1,1,0,0,1,17.18,8.71Z"
      />
    </svg>
  );

  return (
    <OptionContainer
      className={`${consentedBank?.status || ""}${selected ? " selected" : ""}`}
      tabIndex={selected ? 0 : tabIndex}
      disabled={bank.selected}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onClick();
        }
      }}
      onClick={onClick}
      selected={selected}
      hasHover={bank?.logo ? true : false}
      {...rest}
    >
      {bank?.logo && <Image alt={bank?.name} src={bank?.logo} />}
      {bank?.name && (
        <span className="ml-3">
          {bank?.highlight ? parse(bank?.highlight) : bank?.name}
        </span>
      )}
      {!bank?.logo && (
        <LabelContainer>
          <span>{t(`selectScreen.countryCodes.${bank?.countryCode}`)}</span>
        </LabelContainer>
      )}
      <div>
        {bank?.logo &&
          !["Completed", "Error", "Processing"].includes(
            consentedBank?.status
          ) && <PlusIcon />}
        {consentedBank?.status === "Completed" && t("selectScreen.connected")}
        {consentedBank?.status === "Error" && <ErrorIcon />}
        {consentedBank?.status === "Processing" && (
          <IconSpinner>{iconProccesing}</IconSpinner>
        )}
      </div>
    </OptionContainer>
  );
};

interface Props {
  options: Bank[];
  placeholder: string;
  fieldPlaceholder: string;
  onSelect: (bank: Bank | null) => void;
  consents: Consent[];
  value: Bank | null;
  isLoading: boolean;
  onSelectLoading: boolean;
  consumerType?: string;
  singleConsentMode: boolean;
  id?: string;
  onGroupSelected: (groupCode: string) => void;
  onGroupUnselected: () => void;
}

const Select: React.FC<Props> = ({
  options, //banks
  placeholder,
  fieldPlaceholder,
  consents, // consentedBanks
  onSelect,
  value,
  isLoading,
  onSelectLoading,
  consumerType,
  singleConsentMode,
  id,
  onGroupSelected,
  onGroupUnselected,
}) => {
  const [filter, setFilter] = useState("");
  const [isFirefox, setIsFirefox] = useState<boolean>(false);
  const { resources } = useContext(ResourcesContext);
  const [isSelected, setIsSelected] = useState(false);
  const theme: any = useTheme();
  const groupedOptionsByCountry = (options: any) => {
    const selectedOptions = options().filter(
      (option: { selected: boolean }) => option.selected
    );

    const notSelectedOptions = options().filter(
      (option: { selected: boolean }) => !option.selected
    );

    const countries = Array.from(
      new Set(
        notSelectedOptions.map((item: { countryCode: any }) => item.countryCode)
      )
    );
    let finalArray: any = [];

    selectedOptions.forEach((element: any) => {
      finalArray.push(element);
    });

    countries.forEach((countryCode) => {
      finalArray.push({ countryCode });
      let currentOptionsByCountryCode = notSelectedOptions.filter(
        (option: { countryCode: unknown }) => option.countryCode === countryCode
      );
      currentOptionsByCountryCode.forEach((item: any) => finalArray.push(item));
    });

    return finalArray;
  };

  const [vList, setVList] = useState({
    listHeight: 280,
    listRowHeight: 50,
    overscanRowCount: 5,
    scrollToIndex: undefined,
    showScrollingPlaceholder: false,
    useDynamicRowHeight: false,
  });

  useEffect(() => {
    setVList({
      listHeight: 280,
      listRowHeight: 50,
      overscanRowCount: 5,
      scrollToIndex: undefined,
      showScrollingPlaceholder: false,
      useDynamicRowHeight: false,
    });
  }, [options]);

  useEffect(() => {
    setIsFirefox(navigator.userAgent.toLowerCase().indexOf("firefox") > -1);
  }, []);

  const filteredOptions = () => {
    const banks = [...options]
      .sort((a, b) => {
        const inConsentsA = consents.find(
          (c: { providerCode: string }) => c.providerCode === a.providerCode
        );
        if (inConsentsA?.status !== "Processing") {
          return 1;
        }

        if (inConsentsA?.status === "Processing") {
          return -1;
        }

        return 0;
      })
      .sort((a, b) => {
        const inConsentsA = consents.find(
          (c: { providerCode: string }) => c.providerCode === a.providerCode
        );
        if (inConsentsA?.status !== "Error") {
          return isFirefox ? -1 : 1;
        }
        if (inConsentsA?.status === "Error") {
          return isFirefox ? 1 : -1;
        }

        return 0;
      })
      .sort((a, b) => {
        const inConsentsA = consents.find(
          (c: { providerCode: string }) => c.providerCode === a.providerCode
        );
        if (inConsentsA?.status !== "Completed") {
          return 1;
        }

        if (inConsentsA?.status === "Completed") {
          return -1;
        }

        return 0;
      });

    if (!resources?.lender?.enableFuzzySearch) {
      return banks.filter((bank) => {
        return bank.name.toLowerCase().includes(filter.toLowerCase().trim());
      });
    }

    if (!filter) {
      return banks;
    }

    var fuse = new Fuse(banks, {
      keys: [
        { name: "name", weight: 2 },
        { name: "groupName", weight: 0.4 },
        { name: "keywords", weight: 0.3 },
        { name: "bicCodes", weight: 0.2 },
        { name: "identificationCodes", weight: 0.1 },
      ],
      ignoreLocation: false,
      includeMatches: true,
      threshold: 0.3,
      minMatchCharLength: 1,
      ignoreFieldNorm: true,
      includeScore: true,
      fieldNormWeight: 2,
    });
    var result = fuse.search(filter.toLowerCase().trim());

    var highlighter = function (resultItem: any) {
      resultItem?.matches?.forEach((matchItem: any) => {
        if (matchItem.key === "name") {
          var text = resultItem.item[matchItem.key];

          var result = [];
          var matches = [].concat(matchItem.indices);
          var pair = matches.shift();

          for (var i = 0; i < text.length; i++) {
            var char = text.charAt(i);
            if (pair && i === pair[0]) {
              result.push("<b>");
            }
            result.push(char);
            if (pair && i === pair[1]) {
              result.push("</b>");
              pair = matches.shift();
            }
          }
          resultItem.highlight = result.join("");

          if (resultItem.children && resultItem.children.length > 0) {
            resultItem.children.forEach((child: any) => {
              highlighter(child);
            });
          }
        }
      });
    };

    result.forEach((resultItem) => {
      highlighter(resultItem);
    });

    const finalResult: any = [];
    result.forEach((item: any) => {
      item.item["highlight"] = item.highlight;
      finalResult.push(item.item);
    });
    return finalResult;
  };

  const handleFilter: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    onSelect(null);
    setFilter(e.target.value.replace(/\s+/g, " "));
  };

  const renderRow = ({ index, isScrolling, key, style, consents }: any) => {
    const bank = groupedOptionsByCountry(filteredOptions)[index];
    const lenghtBanks = consents.filter((bank: any) =>
      ["Completed", "Processing"].includes(bank?.status)
    ).length;
    return (
      <OptionLabel
        style={{
          ...style,
          opacity:
            !bank?.selected && singleConsentMode && lenghtBanks ? "0.5" : "1",
        }}
        bank={{ ...bank }}
        onClick={() => {
          if (isSelected) return false;
          if (
            (bank?.countryCode && !bank?.logo) ||
            (!bank?.selected && singleConsentMode && lenghtBanks)
          ) {
            return false;
          } else {
            setIsSelected(true);
            onSelect(bank);
          }
        }}
        key={key}
        selected={
          (!!value && bank.providerCode === value.providerCode) ||
          (bank?.selected && singleConsentMode)
        }
        consents={consents}
      />
    );
  };

  return (
    <div>
      <Container className="form-group exp-form-group">
        {options?.length > 0 && <TopBanks providers={options} />}
        {options?.length > 0 && (
          <GroupBanksComponent
            onFilterByGroup={(groupCode: any) => {
              if (groupCode !== "") {
                onGroupSelected(groupCode);
              } else {
                onGroupUnselected();
              }
            }}
          />
        )}
        {!resources?.lender?.showTopBanksOnly ? (
          <InputContainer className="form-control-select">
            <Input
              $background={"#f2f2f2"}
              id={id}
              type="text"
              value={filter}
              placeholder={fieldPlaceholder}
              onChange={handleFilter}
              style={{ ...theme?.settings?.select?.texts?.searchInput?.styles }}
            />
            <SearchIconContainer>
              {isLoading ? (
                <Loader />
              ) : (
                <SearchIcon icon={faSearch} size="2x" />
              )}
            </SearchIconContainer>
          </InputContainer>
        ) : null}
      </Container>
      {!resources?.lender?.showTopBanksOnly ? (
        <MenuContainer
          isLoading={onSelectLoading}
          style={{ ...theme?.settings?.select?.banksList?.styles }}
        >
          <AutoSizer disableHeight>
            {({ width }: any) => (
              <List
                style={{ outline: "none" }}
                height={vList.listHeight}
                overscanRowCount={vList.overscanRowCount}
                rowCount={groupedOptionsByCountry(filteredOptions).length}
                rowHeight={vList.listRowHeight}
                rowRenderer={(props: any) => renderRow({ ...props, consents })}
                width={width}
              />
            )}
          </AutoSizer>
        </MenuContainer>
      ) : null}
    </div>
  );
};

export { Select };
