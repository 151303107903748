import React from "react";
import styled from "styled-components/macro";
import { useLender } from "../../hooks/useLender";
import { ScreenContainer } from "../layout/screens.layout";

const H2 = styled.h2`
  font-family: cabin, sans-serif;
  font-size: 2rem;
  font-weight: 400;
  text-transform: uppercase;
  color: var(--exp-medium-grey);
  margin-top: 0;
  margin-bottom: 25px;
  position: relative;
  z-index: 2;
`;

const StyledContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  max-width: 520px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
`;

const StyledInnerWrapper = styled.div`
  position: relative;
  height: 240px;
`;

const H3 = styled.h3`
  font-family: cabin, sans-serif;
  position: relative;
  font-size: 1.6rem;
  font-weight: 700;
  bottom: 10px;
  text-transform: uppercase;
  color: var(--exp-medium-grey);
  margin: 0;
  letter-spacing: 3px;
  padding-left: 6px;
`;

interface Props {}

const TimeoutScreen: React.FC<Props> = () => {
  const { url, name } = useLender();
  return (
    <ScreenContainer>
      <StyledContainer>
        <StyledInnerWrapper>
          <H3>Your session has timed out!</H3>
        </StyledInnerWrapper>
        <H2>
          {url && (
            <button
              onClick={() => (window.location.href = url)}
              className="btn btn-lg btn-exp-medium-grey"
            >
              Go back to
            </button>
          )}
          <div>{name}</div>
        </H2>
      </StyledContainer>
    </ScreenContainer>
  );
};

export default TimeoutScreen;
