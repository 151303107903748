import axios from "axios";
import { useState, useCallback, useContext } from "react";
import { SnackbarContext } from "../context/snackbar";
import { useOrchestrationToken } from "./useOrchestrationToken";

const useDownloadPolicy = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(false);
  const { openSnackbar } = useContext(SnackbarContext);
  const orchestrationToken = useOrchestrationToken();
  const refetch = async () => {
    setLoading(true);
    setError!(null);
    downloadPdf();
  };

  const downloadPdf = useCallback(async () => {
    try {
      setLoading(true);
      const response: any = await axios.get(
        `api/orchestrations/${orchestrationToken}/privacy-notice-pdf`,
        { responseType: "blob" }
      );

      //create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", `Experian Privacy Policy.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      setError(error);
      openSnackbar(true);
    } finally {
      setLoading(false);
    }
  }, [setError]);

  return {
    loading,
    error,
    refetch,
  };
};

export { useDownloadPolicy };
