import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState, useContext, CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { Button, ModalWrapper } from "../../screens/select/select";
import Modal from "../modal/Modal";
import { useTheme } from "styled-components";
import { ResourcesContext } from "../../context/consumer";
import {
  getModalCloseIcon,
  getModalIcon,
  getTextWithPopulatePlaceholders,
} from "../../utils/helpers";
import { useExitJourney } from "../../hooks/useExitJourney";
import { useOrchestrationToken } from "../../hooks/useOrchestrationToken";
import { Content } from "../../screens/layout/screens.layout";
import { LoadableButton } from "../button/loadable-button.component";
import { useHistory } from "react-router";
import { ReactComponent as ExitModalIcon } from "../../assets/exit_icon.svg";
interface Props {
  lenderUrl?: string;
  hasFailedBank?: number;
  styles?: CSSProperties | undefined;
  withContainer?: boolean;
}

function ExitButton({
  styles,
  lenderUrl,
  hasFailedBank,
  withContainer,
}: Props) {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const { resources } = useContext(ResourcesContext);
  const theme: any = useTheme();
  const modal = useRef(null);
  const history = useHistory();
  const orchestrationToken = useOrchestrationToken();
  const { execute: exitJourney, loading: exitJourneyLoading } =
    useExitJourney(orchestrationToken);

  return (
    <>
      {withContainer ? (
        <Content
          className="my-0 mb-4 text-right"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <LoadableButton
            style={{
              cursor: "pointer",
              fontStyle: "italic",
              fontSize: "1.4rem",
              alignSelf: "flex-end",
              ...styles,
              ...theme.settings.select?.texts?.selectExitButton?.styles,
            }}
            className=" btn text-uppercase-first-letter"
            onClick={() => {
              setShowModal(true);
              const ref: any = modal;
              if (ref?.current) {
                ref.current.style.top = `${
                  (window.pageYOffset ||
                    document.documentElement.scrollTop ||
                    0) - 76
                }px`;
              }
              document.body.style.overflow = "hidden";
            }}
            variant={
              theme.settings.select?.texts?.selectExitButton?.type == "link"
                ? "link"
                : "secondary"
            }
            isLoading={false}
          >
            {theme.settings?.select?.texts?.selectExitButton
              ? getTextWithPopulatePlaceholders(
                  theme.settings.select.texts.selectExitButton,
                  resources
                )
              : t("common.exit")}
          </LoadableButton>
        </Content>
      ) : (
        <LoadableButton
          style={{
            cursor: "pointer",
            fontStyle: "italic",
            fontSize: "1.4rem",
            alignSelf: "flex-end",
            ...styles,
            ...theme.settings.select?.texts?.selectExitButton?.styles,
          }}
          className={`btn text-uppercase-first-letter ${
            theme.settings.select?.texts?.selectExitButton?.type !== "link"
              ? "btn-block"
              : ""
          }`}
          onClick={() => {
            setShowModal(true);
            const ref: any = modal;
            if (ref?.current) {
              ref.current.style.top = `${
                (window.pageYOffset ||
                  document.documentElement.scrollTop ||
                  0) - 76
              }px`;
            }
            document.body.style.overflow = "hidden";
          }}
          variant={
            theme.settings.select?.texts?.selectExitButton?.type == "link"
              ? "link"
              : "secondary"
          }
          isLoading={false}
        >
          {theme.settings?.select?.texts?.selectExitButton
            ? getTextWithPopulatePlaceholders(
                theme.settings.select.texts.selectExitButton,
                resources
              )
            : t("common.exit")}
        </LoadableButton>
      )}
      <Modal>
        <ModalWrapper className={showModal ? "visible" : ""} ref={modal}>
          <div className="modal-body">
            <div className="modal-header">
              {getModalIcon(
                theme?.colors?.icons?.exitJourneyModalIcon,
                <ExitModalIcon />
              )}
              <span
                className="close-btn"
                onClick={() => {
                  setShowModal(false);
                  document.body.style.overflow = "auto";
                }}
              >
                {getModalCloseIcon(
                  theme?.colors?.icons?.modalCloseIcon,
                  theme?.colors?.icons?.default
                )}
              </span>
            </div>
            <div className="d-flex flex-column w-100">
              <h4 className="font-weight-bold">
                <span className="modal-title">
                  {theme.settings?.select?.texts?.selectExitPopupTitle
                    ? getTextWithPopulatePlaceholders(
                        theme.settings.select.texts.selectExitPopupTitle,
                        resources
                      )
                    : t("selectScreen.exitPopup.title")}
                </span>
              </h4>
              <h4>
                <span className="modal-subtitle">
                  {theme.settings?.select?.texts?.selectExitPopupSubTitle
                    ? getTextWithPopulatePlaceholders(
                        theme.settings.select.texts.selectExitPopupSubTitle,
                        resources
                      )
                    : t("selectScreen.exitPopup.subTitle")}
                </span>
              </h4>
            </div>

            <div className="btns-wrapper">
              <Button
                id="yesExit"
                isLoading={exitJourneyLoading}
                disabled={exitJourneyLoading}
                onClick={() => {
                  !resources?.lender.url
                    ? exitJourney(
                        () =>
                          history.push(
                            `/finish?orchestrationToken=${orchestrationToken}`,
                            {
                              status: "fail",
                            }
                          ),
                        hasFailedBank == 0
                          ? "ConsentsAbandoned"
                          : "ConsentsFailed",
                        "ProcessingFailed"
                      )
                    : exitJourney(
                        () => window.open(lenderUrl, "_self"),
                        hasFailedBank == 0
                          ? "ConsentsAbandoned"
                          : "ConsentsFailed",
                        "ProcessingFailed"
                      );
                }}
                variant={
                  theme.settings.select?.texts?.selectYesExitButton?.type ===
                  "link"
                    ? "link"
                    : "secondary"
                }
                className="btn text-uppercase-first-letter text-center bg-white btn-block"
              >
                {theme.settings?.select?.texts?.selectYesExitButton
                  ? getTextWithPopulatePlaceholders(
                      theme.settings.select.texts.selectYesExitButton,
                      resources
                    )
                  : t("common.yes")}
              </Button>
              <Button
                id="noExit"
                disabled={false}
                isLoading={false}
                onClick={() => {
                  setShowModal(false);
                  document.body.style.overflow = "auto";
                }}
                variant={
                  theme.settings.select?.texts?.selectNoExitButton?.type ===
                  "link"
                    ? "link"
                    : "primary"
                }
                className="btn text-uppercase-first-letter text-center bg-white btn-block"
              >
                {theme.settings?.select?.texts?.selectNoExitButton
                  ? getTextWithPopulatePlaceholders(
                      theme.settings.select.texts.selectNoExitButton,
                      resources
                    )
                  : t("common.no")}
              </Button>
            </div>
          </div>
        </ModalWrapper>
      </Modal>
    </>
  );
}

export default ExitButton;
