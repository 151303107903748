import React from "react";
import styled, { css } from "styled-components";
import Loader from "../loading/loading-small.component";

interface Props {
  isLoading: boolean;
  onClick?: () => void;
  className?: string;
  style?: {};
  disabled?: boolean;
  id?: string;
  variant?: string;
}

const variantStyles = (theme: any, variant = "primary") => {
  const primaryActiveBtnDefault =
    theme.colors.buttons?.primary?.active?.default;
  const primaryActiveBtnHover = theme.colors.buttons?.primary?.active?.hover;
  const primaryInActiveBtn = theme.colors.buttons?.primary?.inActive?.default;

  const secondaryActiveBtnDefault =
    theme.colors.buttons?.secondary?.active?.default;
  const secondaryActiveBtnHover =
    theme.colors.buttons?.secondary?.active?.hover;

  const linkDefault = theme.colors.buttons?.link?.active?.default;
  const linkDefaultHover = theme.colors.buttons?.link?.active?.hover;
  const linkDefaultInActive = theme.colors.buttons?.link?.inActive?.default;
  return {
    primary: css`
      overflow: hidden;
      transition: all 0.3s;
      max-width: 240px !important;

      @media (max-width: 768px) {
        max-width: none !important;
      }
      font-size: ${primaryActiveBtnDefault?.fontSize};
      font-weight: ${primaryActiveBtnDefault?.fontWeight};
      line-height: ${primaryActiveBtnDefault?.lineHeight};
      color: ${primaryActiveBtnDefault?.textColor ?? "#fff !important"};
      background: ${primaryActiveBtnDefault?.backgroundColor};
      border: none !important;
      box-shadow: ${primaryActiveBtnDefault?.boxShadow};
      &:not(:disabled) {
        &.active,
        &.focus,
        &:active,
        &:focus,
        &:hover {
          color: ${primaryActiveBtnHover?.textColor};
          background: ${primaryActiveBtnHover?.backgroundColor};
          box-shadow: ${primaryActiveBtnHover?.boxShadow};
          font-size: ${primaryActiveBtnHover?.fontSize};
          font-weight: ${primaryActiveBtnHover?.fontWeight};
          line-height: ${primaryActiveBtnHover?.lineHeight};
        }
        &:before {
          background: ${primaryActiveBtnHover?.backgroundColor};
        }
      }
      &:disabled {
        background: ${primaryInActiveBtn?.backgroundColor};
        color: ${primaryInActiveBtn?.textColor};
        box-shadow: ${primaryInActiveBtn?.boxShadow};
        opacity: 1 !important;
        font-size: ${primaryInActiveBtn?.fontSize};
        font-weight: ${primaryInActiveBtn?.fontWeight};
        line-height: ${primaryInActiveBtn?.lineHeight};
      }
      .spinner-border {
        color: #fff;
      }
    `,
    secondary: css`
    overflow: hidden;
      max-width: 240px !important; 
       @media (max-width: 768px) {
        max-width: none !important;
      }
      color: ${secondaryActiveBtnDefault?.textColor};
      background: ${secondaryActiveBtnDefault?.backgroundColor};
      box-shadow: ${secondaryActiveBtnDefault?.boxShadow};
      font-size: ${secondaryActiveBtnDefault?.fontSize};
      font-weight: ${secondaryActiveBtnDefault?.fontWeight};
      line-height: ${secondaryActiveBtnDefault?.lineHeight};
      border: none !important;
      &:not(:disabled) {
        &.active,
        &.focus,
        &:active,
        &:focus,
        &:hover {
          color: ${secondaryActiveBtnHover?.textColor};
          box-shadow:${secondaryActiveBtnHover?.boxShadow};   
          background: ${secondaryActiveBtnHover?.backgroundColor};
          font-size: ${secondaryActiveBtnHover?.fontSize};
      font-weight: ${secondaryActiveBtnHover?.fontWeight};
      line-height: ${secondaryActiveBtnHover?.lineHeight};
        }

        
      .spinner-border {
        color: #fff;
      }
    `,
    link: css`
      overflow: hidden;

      color: ${linkDefault?.textColor};
      background: ${linkDefault?.backgroundColor};
      box-shadow: ${linkDefault?.boxShadow};
      border: none !important;
      text-decoration: ${linkDefault?.textDecoration};
      font-weight: ${linkDefault?.fontWeight};
      font-style: ${linkDefault?.fontStyle};
      font-size: ${linkDefault?.fontSize};
      font-weight: ${linkDefault?.fontWeight};
      line-height: ${linkDefault?.lineHeight};
      &:not(:disabled) {
        &.active,
        &.focus,
        &:active,
        &:focus,
        &:hover {
          color: ${linkDefaultHover?.textColor};
          box-shadow: ${linkDefaultHover?.boxShadow};
          background: ${linkDefaultHover?.backgroundColor};
          text-decoration: ${linkDefaultHover?.textDecoration};
          font-weight: ${linkDefaultHover?.fontWeight};
          font-style: ${linkDefaultHover?.fontStyle};
          font-size: ${linkDefaultHover?.fontSize};
          font-weight: ${linkDefaultHover?.fontWeight};
          line-height: ${linkDefaultHover?.lineHeight};
        }
      }
      &:disabled {
        color: ${linkDefaultInActive?.textColor};
        box-shadow: ${linkDefaultInActive?.boxShadow};
        background: ${linkDefaultInActive?.backgroundColor};
        text-decoration: ${linkDefaultInActive?.textDecoration};
        font-size: ${linkDefaultInActive?.fontSize};
        font-weight: ${linkDefaultInActive?.fontWeight};
        line-height: ${linkDefaultInActive?.lineHeight};
      }

      .spinner-border {
        color: #fff;
      }
    `,
  }[variant];
};

const Button = styled.button<{ variant?: string }>`
  ${({ theme, variant }) => variantStyles(theme, variant)}
`;

const LoadableButton: React.FC<Props> = ({
  isLoading,
  children,
  style,
  variant,
  ...rest
}) => {
  return (
    <Button
      variant={variant}
      type="button"
      {...rest}
      style={style ? style : {}}
    >
      {children} {isLoading && <Loader />}
    </Button>
  );
};

export { LoadableButton };
