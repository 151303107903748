import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import styled, { useTheme } from "styled-components/macro";
import * as signalR from "@microsoft/signalr";

import {
  ScreenContentWrapper,
  ScreenContainer,
  Content,
  ActionFooter,
} from "../layout/screens.layout";

import Progressbar from "../../components/progressbar/progressbar.component";
import { ConsentedBanksContext } from "../../context/consentedBanks";
import ProcessingImage from "../../components/loading/processing-image.component";
import { getForFullDetailsParagraph, getYouCanDownloadPdf } from "./helper";
import { SnackbarContext } from "../../context/snackbar";
import { LoadableButton } from "../../components/button/loadable-button.component";
import { ResourcesContext } from "../../context/consumer";
import { useSummaryData } from "../../hooks/useSummaryData";
import { useOrchestrationFinish } from "../../hooks/useOrchestrationFinish";
import { useDownloadPdf } from "../../hooks/useDownloadPdf";
import Loader from "../../components/loading/loading-small.component";
import { useLender } from "../../hooks/useLender";
import BankSummaries from "../../components/bankSummaries/BankSummaries";
import { ReactComponent as SuccessIcon } from "../../assets/success_icon.svg";
import Navbar from "../../components/navbar/navbar.component";
import { useConsumer } from "../../hooks/useConsumer";
import { ReactComponent as EmailIcon } from "../../assets/email.svg";
import { fetchResources } from "../../hooks/useBootstrap";
import { useOrchestrationToken } from "../../hooks/useOrchestrationToken";
import {
  getResource,
  getTextWithPopulatePlaceholders,
} from "../../utils/helpers";
import { Header as HeaderComponent } from "../intro/components/IntroHeaderComponent";
import AssuranceItems from "../../components/assuranceSection/AssuranceItems";
import WaitForSummaryData from "./components/WaitForSummaryData";
import useScreenSize from "../../hooks/useScreenSize";
import ToggleButton from "../../components/ToggleButton.tsx/ToggleButton";
import { FinishJourneyMessage } from ".";
import { useHistory } from "react-router-dom";
import parse from "html-react-parser";
import { TextResourcesContext } from "../../context/textResourcesContext";
import { TimeoutContext } from "../../context/timeout";
const Header = styled.p`
  text-align: center;

  white-space: pre-line;
`;
const SubHeader = styled.p<{ textAlign?: string }>`
  white-space: pre-line;
  font-size: 1.6rem;
`;
const ButtonsFooterContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
  justify-content: center;

  & .btn {
    margin-top: 0;
  }
`;
const CustomActionFooter = styled(ActionFooter)`
  @media (max-width: 480px) {
    padding: 0;
  }
`;
const SelectedBanksContainer = styled.div`
  margin-bottom: 2rem;
  border: ${(props) => `1px solid ${props.theme.colors.icons.default}`};
  border-radius: 20px;
  padding: 20px 10px;
  font-size: 1.7rem;
  position: relative;
`;

const LoadingContainer = styled.div`
  height: 70px;
  display: flex;
  z-index: 1003;
  justify-content: center;
  align-items: center;
  & > img {
    width: 100%;
    height: 100%;
  }
`;

const PageTitle = styled.h4`
  margin-top: 24px;
  text-align: center;
  font-size: 1.6rem;
  line-height: 2rem;
  color: ${(props) => props.theme.colors.text};
`;

const ButtonTryAgainWrapper = styled.div`
  max-width: 272px;
  margin: 2rem auto 0;
  align-items: center;
  button {
    max-width: 200px;
    margin: 0 auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const ConsentedBank = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  .title {
    width: 100%;
    height: 42px;
    background: #d9ecff;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    font-weight: bold;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    > div {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
  .property {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    font-size: 12px;
  }
  .sep {
    width: 100%;
    height: 1px;
    background: #d9ecff;
    margin: 10px 0;
  }
`;

const BottomTextWrapper = styled.div`
  width: 100%;
  font-size: 14px;
  // margin-bottom: 40px;
  white-space: pre-line;
`;

const LoadingBanks = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;

  & .spinner-border {
    width: 3rem !important;
    height: 3rem !important;
  }
`;
const SummaryBalance = styled.div`

    display:flex;
    justify-content: space-between;
    align-items; center;
    background: var(--exp-light-grey);
    color: ${(props) => props.theme.colors.primaryAccentText};
    padding: 5px 10px;
    border-radius: 8px;
    font-weight: 500;

`;
const iconCheck = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    style={{ maxWidth: "30px", maxHeight: "30px" }}
  >
    <path
      fill="var(--exp-light-blue)"
      d="M32.13,38H8.26a6,6,0,0,1-6-6V8.06a6,6,0,0,1,6-6H32.13a6,6,0,0,1,6,6V31.94A6,6,0,0,1,32.13,38ZM8.26,3.89A4.18,4.18,0,0,0,4.08,8.06V31.94a4.18,4.18,0,0,0,4.18,4.17H32.13a4.18,4.18,0,0,0,4.18-4.17V8.06a4.18,4.18,0,0,0-4.18-4.17Z"
    />
    <path
      fill="var(--exp-light-blue)"
      d="M16.92,31.17a.93.93,0,0,1-.65-.27l-8-8a.92.92,0,1,1,1.3-1.3l7.23,7.23L32.54,9.18A.91.91,0,0,1,33.83,9a.92.92,0,0,1,.15,1.3L17.64,30.82a.93.93,0,0,1-.67.35Z"
    />
  </svg>
);
const Panel = styled.div`
  .icon-wrapper {
    background: ${(props) => props.theme.colors.body};
    padding: 0 5px 0 5px;
  }
`;
interface Props {
  onInit: () => void;
}

const Complete: React.FC<Props> = ({ onInit }) => {
  const { t, i18n } = useTranslation();
  const orchestrationToken = useOrchestrationToken();
  const { consentedBanks } = useContext(ConsentedBanksContext);
  const hasConsentedBanks = consentedBanks.length > 0;
  const screenSize = useScreenSize();
  const { openSnackbar } = useContext(SnackbarContext);
  const { email } = useConsumer();
  const { resources, setError } = useContext(ResourcesContext);
  const { setTimedout } = useContext(TimeoutContext);
  const [newUrl, setNewUrl] = useState<string>("");
  const history = useHistory();
  const { data } = useContext(TextResourcesContext);
  // const { resources: newResources } = useBootstrap();
  const {
    name: lenderName,
    customerSupportEmail,
    lenderPrivacyPolicyLink,
    supportPage,
  } = useLender();
  const [hubConnectionError, setHubConnectionError] = useState<boolean>(false);
  const [passed2Mins, setPassed2Mins] = useState<boolean>(false);
  const [passed5Mins, setPassed5Mins] = useState<boolean>(false);
  const [dataFetching, setDataFetching] = useState<boolean>(true);
  const [fullLoading, setFullLoading] = useState<boolean>(false);
  const [showWaitForSummaryData, setShowWaitForSummaryData] =
    useState<boolean>(true);
  const theme: any = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [connection, setConnection] = useState(() =>
    new signalR.HubConnectionBuilder()
      .withUrl("/hubs/notification", {
        transport: signalR.HttpTransportType.ServerSentEvents,
        // withCredentials: false,
      })
      .configureLogging(signalR.LogLevel.Trace)
      .withAutomaticReconnect()

      .build()
  );
  useEffect(() => {
    document.title = getResource(data, "CJ_summaryScreen_tabTitle");
  }, [data]);
  const orchestrationFinish = useOrchestrationFinish(
    () => (window.location.href = newUrl)
  );

  const downloadPdf = useDownloadPdf();

  const getSummaryData = useSummaryData(false);
  const timeout = useRef<any>(null);
  const fetchResourcesBeforeOrchestrationFinish = () => {
    const fetchData = async () => {
      try {
        const data = await fetchResources(orchestrationToken);
        setNewUrl(data.data.lender.url);
      } catch (error) {
        if (error?.response?.status === 401) {
          setTimedout(true);
        } else {
          setError(true);
        }
      }
    };
    fetchData();
  };

  useEffect(() => {
    newUrl !== "" && orchestrationFinish.refetch();
  }, [newUrl]);

  // const { loading: loadingConsentsList } = useConsentedBanksList(
  //   !hasConsentedBanks
  // );

  // const { execute, loading, error } = useLockConsents(orchestrationToken);

  const getLocaleString = () => {
    if (i18n?.language === "it") {
      return "it-IT";
    }
    return "en-US";
  };
  useEffect(() => {
    const downloadLink = document.getElementsByClassName("download-link")[0];
    const downloadLink1 = document.getElementsByClassName("download-link")[1];
    downloadLink?.addEventListener("click", (e) => {
      e.preventDefault();
      downloadPdf.refetch();
    });
    downloadLink1?.addEventListener("click", (e) => {
      e.preventDefault();
      downloadPdf.refetch();
    });
    return () => {
      downloadLink?.removeEventListener("click", () => {});
      downloadLink1?.removeEventListener("click", () => {});
    };
  }, [downloadPdf]);
  const startConnection = () =>
    connection
      .start()
      .then(() => connection.invoke("start-polling-for-status"))
      .catch((error: any) => {
        setHubConnectionError(true);
      });

  useEffect(() => {
    if (!getSummaryData.data) {
      startConnection();
      connection.on("summary-status-received", (data: any) => {
        connection.stop();
        getSummaryData.refetch();
        setDataFetching(false);
      });
    }
    if (orchestrationFinish.error || downloadPdf.error) {
      openSnackbar(true);
    }
    const timer1 = setTimeout(() => {
      setPassed2Mins(true);
      setDataFetching(false);
    }, 120000);
    const timer2 = setTimeout(() => {
      setPassed2Mins(false);
      setPassed5Mins(true);
    }, 300000);
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSummaryData.data, orchestrationFinish.error, downloadPdf.error]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onInit(), []);
  console.log({ loading: getSummaryData.loading });
  useEffect(() => {
    if (hubConnectionError && !getSummaryData.data) {
      timeout.current = window.setInterval(() => {
        getSummaryData.refetch();
      }, 5000);
    }

    return () => clearInterval(timeout.current);
  }, [getSummaryData.data, hubConnectionError]);
  useEffect(() => {
    if (getSummaryData.data) {
      setFullLoading(true);
      setHubConnectionError(false);
      setTimeout(() => {
        setShowWaitForSummaryData(false);
      }, 1000);
    }
  }, [getSummaryData]);

  const checkIfSummaryDataHasBalanceField = (bankSummaries: any) => {
    const summaryDataFields = theme.settings.summary?.texts?.summaryData;
    const containsBalanceFields = summaryDataFields?.some(
      (field: { name: string }) =>
        field.name === "lastTransactionAmount" || field.name === "balance"
    );

    return containsBalanceFields;
  };

  return (
    <ScreenContentWrapper
      $currentScreenWidth={screenSize.width}
      style={{
        ...theme.settings.screenContainer,
        minWidth: !getSummaryData.data ? "50vw" : null,
      }}
    >
      {theme?.settings.customBackground ? (
        <div
          className="custom-bg"
          style={theme.settings.customBackground?.styles}
        >
          {" "}
        </div>
      ) : null}
      <div className="w-100">
        <Navbar />
        <ScreenContainer>
          <Progressbar onLastStep={getSummaryData?.data && true} />

          <Content
            style={{
              position: "relative",
              justifyContent: "center",
            }}
            className="mt-4"
          >
            {showWaitForSummaryData ? (
              <WaitForSummaryData
                hasProcessing={!fullLoading}
                onClick={() => {
                  !resources?.lender.url
                    ? history.push(
                        `/finish?orchestrationToken=${orchestrationToken}`,
                        { status: "fail" }
                      )
                    : fetchResourcesBeforeOrchestrationFinish();
                }}
                onCompletedPercentages={() => setShowWaitForSummaryData(false)}
              />
            ) : null}
            {!showWaitForSummaryData &&
              theme.settings.summary?.thankyouText?.visible && (
                <HeaderComponent
                  id="titleSummary"
                  className="with-custom-font page-title"
                  style={{
                    ...{
                      whiteSpace: "pre-wrap",
                    },
                  }}
                >
                  {theme.settings?.summary?.texts?.titleSummary
                    ? getTextWithPopulatePlaceholders(
                        theme.settings.summary.texts.titleSummary,
                        resources
                      )
                    : t("summaryScreen.title")}
                </HeaderComponent>
              )}
            {
              !hubConnectionError ? (
                <>
                  {!getSummaryData.data ? null : getSummaryData.loading ? (
                    <LoadingBanks>
                      <Loader />
                    </LoadingBanks>
                  ) : (
                    <>
                      {!showWaitForSummaryData ? (
                        <div className="row">
                          <div className="col-12">
                            {(!getSummaryData!.data!.bankSummaries ||
                              (getSummaryData!.data!.bankSummaries &&
                                Object.keys(getSummaryData!.data!.bankSummaries)
                                  .length === 0)) && (
                              <Header id="summaryA">
                                {t("summaryScreen.noConsent", {
                                  customerSupportEmail,
                                })}
                              </Header>
                            )}
                          </div>
                          <div className="col-12 col-md-5">
                            {getSummaryData?.data?.bankSummaries && (
                              <>
                                <SubHeader
                                  id="summarySubheaderA"
                                  className="mt-4"
                                >
                                  {theme.settings?.summary?.texts
                                    ?.summarySubheaderA ? (
                                    getTextWithPopulatePlaceholders(
                                      theme.settings.summary.texts
                                        .summarySubheaderA,
                                      resources
                                    )
                                  ) : (
                                    <Trans
                                      i18nKey="summaryScreen.titleParagraphTwo"
                                      tOptions={{
                                        lenderName,
                                      }}
                                    ></Trans>
                                  )}
                                </SubHeader>
                              </>
                            )}
                            {getSummaryData!.data!.bankSummaries &&
                              Object.keys(getSummaryData!.data!.bankSummaries)
                                .length > 0 && (
                                <>
                                  <Panel className=" d-md-none mt-5 col-12 col-md-7 mt-5 px-0">
                                    {/* MOBILE AND TABLET */}
                                    <SelectedBanksContainer>
                                      {(() => {
                                        if (
                                          checkIfSummaryDataHasBalanceField(
                                            getSummaryData?.data.bankSummaries
                                          )
                                        ) {
                                          if (
                                            theme.settings.summary?.toggleButton
                                              ?.visibility
                                          ) {
                                            return (
                                              <ToggleButton
                                                label={
                                                  theme.settings?.summary?.texts
                                                    ?.toggleButtonLabel
                                                    ? getTextWithPopulatePlaceholders(
                                                        theme.settings.summary
                                                          .texts
                                                          .toggleButtonLabel,
                                                        resources
                                                      )
                                                    : t(
                                                        "summaryScreen.accountBalance"
                                                      )
                                                }
                                                state={
                                                  theme.settings.summary
                                                    ?.toggleButton?.default ===
                                                  "on"
                                                    ? "on"
                                                    : "off"
                                                }
                                              />
                                            );
                                          }
                                        }
                                      })()}
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "-25px",
                                          display: "flex",
                                          justifyContent: "center",
                                          width: "100%",
                                        }}
                                      >
                                        <div className="icon-wrapper">
                                          <SuccessIcon
                                            style={{ width: "40px" }}
                                          />
                                        </div>
                                      </div>
                                      <BankSummaries
                                        bankSummaries={
                                          getSummaryData?.data.bankSummaries
                                        }
                                      />
                                    </SelectedBanksContainer>
                                    <div className="d-block d-md-none">
                                      {theme.settings?.summary
                                        ?.downloadSummaryButtonVisible ? (
                                        <div>
                                          <SubHeader
                                            id="summaryA"
                                            className="mt-4"
                                          >
                                            {parse(
                                              getYouCanDownloadPdf(
                                                getResource(
                                                  data,
                                                  "CJ_summaryScreen_downloadPdfParagraph"
                                                )
                                              )
                                            )}
                                          </SubHeader>
                                        </div>
                                      ) : (
                                        <>
                                          <SubHeader
                                            id="summaryA"
                                            className="mt-4"
                                          >
                                            <Trans
                                              i18nKey="summaryScreen.sendCopyToEmail"
                                              tOptions={{
                                                lenderName,
                                              }}
                                            >
                                              <b></b>
                                            </Trans>
                                          </SubHeader>
                                          <div
                                            className="d-flex align-items-center mt-3"
                                            style={{
                                              wordWrap: "break-word",
                                              gap: "1rem",
                                            }}
                                          >
                                            <div>
                                              <EmailIcon
                                                width={30}
                                                height={30}
                                              />
                                            </div>
                                            <div
                                              style={{
                                                width: "80%",
                                                fontSize: "1.6rem",
                                              }}
                                            >
                                              <a href={`mailto:${email}`}>
                                                {email}
                                              </a>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                    <SubHeader className="d-block d-md-none">
                                      {parse(
                                        getForFullDetailsParagraph(
                                          getResource(
                                            data,
                                            "CJ_summaryScreen_lendersPPparagraph"
                                          ),
                                          lenderPrivacyPolicyLink || "",
                                          lenderName
                                        )
                                      )}
                                      <br />
                                      {theme.settings?.summary?.texts
                                        ?.bottomText ? (
                                        getTextWithPopulatePlaceholders(
                                          theme.settings.summary.texts
                                            .bottomText,
                                          resources
                                        )
                                      ) : (
                                        <Trans
                                          i18nKey={
                                            customerSupportEmail || supportPage
                                              ? "completeScreen.bottomText"
                                              : "completeScreen.bottomTextWithoutContacts"
                                          }
                                          tOptions={{
                                            lenderName,
                                            customerSupportEmail,
                                          }}
                                        >
                                          <b></b>
                                          <a
                                            href={`${
                                              customerSupportEmail
                                                ? `mailto:${customerSupportEmail}`
                                                : supportPage
                                            }`}
                                            target={`${
                                              !customerSupportEmail
                                                ? `_blank`
                                                : `_self`
                                            }`}
                                          ></a>
                                        </Trans>
                                      )}
                                    </SubHeader>

                                    {!resources?.processCompleted &&
                                    !resources?.lender
                                      ?.hideReturnToLenderButton ? (
                                      <CustomActionFooter className="mt-5 mt-md-0">
                                        <ButtonsFooterContainer>
                                          <LoadableButton
                                            id="completeFinish"
                                            isLoading={
                                              orchestrationFinish.loading
                                            }
                                            className="btn btn-lg exp-default text-center w-100"
                                            onClick={() => {
                                              !resources?.lender.url
                                                ? history.push(
                                                    `/finish?orchestrationToken=${orchestrationToken}`,
                                                    {
                                                      status: "success",
                                                    }
                                                  )
                                                : fetchResourcesBeforeOrchestrationFinish();
                                            }}
                                            disabled={
                                              orchestrationFinish.loading
                                            }
                                            variant={
                                              theme.settings.summary?.texts
                                                ?.summaryFinishButton?.type ===
                                              "link"
                                                ? "link"
                                                : "primary"
                                            }
                                          >
                                            {theme.settings.summary?.texts
                                              ?.summaryFinishButton ? (
                                              getTextWithPopulatePlaceholders(
                                                theme.settings.summary.texts
                                                  .summaryFinishButton,
                                                resources
                                              )
                                            ) : (
                                              <Trans
                                                i18nKey="summaryScreen.returnTo"
                                                tOptions={{
                                                  lenderName,
                                                }}
                                              >
                                                <b></b>
                                              </Trans>
                                            )}
                                          </LoadableButton>
                                        </ButtonsFooterContainer>
                                      </CustomActionFooter>
                                    ) : null}
                                    {!resources?.processCompleted &&
                                    resources?.lender
                                      ?.hideReturnToLenderButton ? (
                                      <Content className="mt-5">
                                        <FinishJourneyMessage
                                          style={
                                            theme?.settings?.summary?.texts
                                              ?.finishJourneyMessage?.styles
                                          }
                                        >
                                          {theme.settings.summary?.texts
                                            ?.finishJourneyMessage
                                            ? getTextWithPopulatePlaceholders(
                                                theme.settings.summary.texts
                                                  .finishJourneyMessage,
                                                resources
                                              )
                                            : t(
                                                "summaryScreen.finishJourneyMessage"
                                              )}
                                        </FinishJourneyMessage>
                                      </Content>
                                    ) : null}
                                  </Panel>
                                  <div className="d-none d-md-block">
                                    {theme.settings?.summary
                                      ?.downloadSummaryButtonVisible ? (
                                      <div>
                                        <SubHeader
                                          id="summaryA"
                                          className="mt-4"
                                        >
                                          {parse(
                                            getYouCanDownloadPdf(
                                              getResource(
                                                data,
                                                "CJ_summaryScreen_downloadPdfParagraph"
                                              )
                                            )
                                          )}
                                        </SubHeader>
                                      </div>
                                    ) : (
                                      <>
                                        <SubHeader
                                          id="summaryA"
                                          className="mt-4"
                                        >
                                          <Trans
                                            i18nKey="summaryScreen.sendCopyToEmail"
                                            tOptions={{
                                              lenderName,
                                            }}
                                          >
                                            <b></b>
                                          </Trans>
                                        </SubHeader>
                                        <div
                                          className="d-flex align-items-center mt-3"
                                          style={{
                                            wordWrap: "break-word",
                                            gap: "1rem",
                                          }}
                                        >
                                          <div>
                                            <EmailIcon width={30} height={30} />
                                          </div>
                                          <div
                                            style={{
                                              width: "80%",
                                              fontSize: "1.6rem",
                                            }}
                                          >
                                            <a
                                              style={{
                                                ...theme?.settings?.summary
                                                  ?.links?.styles,
                                              }}
                                              href={`mailto:${email}`}
                                            >
                                              {email}
                                            </a>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                  <SubHeader className="d-none d-md-block ">
                                    {parse(
                                      getForFullDetailsParagraph(
                                        getResource(
                                          data,
                                          "CJ_summaryScreen_lendersPPparagraph"
                                        ),
                                        lenderPrivacyPolicyLink || "",
                                        lenderName
                                      )
                                    )}
                                    <br />
                                    {theme.settings?.summary?.texts
                                      ?.bottomText ? (
                                      getTextWithPopulatePlaceholders(
                                        theme.settings.summary.texts.bottomText,
                                        resources
                                      )
                                    ) : (
                                      <Trans
                                        i18nKey={
                                          customerSupportEmail || supportPage
                                            ? "completeScreen.bottomText"
                                            : "completeScreen.bottomTextWithoutContacts"
                                        }
                                        tOptions={{
                                          lenderName,
                                          customerSupportEmail,
                                        }}
                                      >
                                        <b></b>
                                        <a
                                          style={{
                                            ...theme?.settings?.summary?.links
                                              ?.styles,
                                          }}
                                          href={`${
                                            customerSupportEmail
                                              ? `mailto:${customerSupportEmail}`
                                              : supportPage
                                          }`}
                                          target={`${
                                            !customerSupportEmail
                                              ? `_blank`
                                              : `_self`
                                          }`}
                                        ></a>
                                      </Trans>
                                    )}
                                  </SubHeader>
                                </>
                              )}
                          </div>
                          <Panel className="d-none d-md-block col-12 col-md-7 mt-5">
                            {/* DESKTOP */}
                            <SelectedBanksContainer>
                              <div
                                style={{
                                  position: "absolute",
                                  top: "-25px",
                                  display: "flex",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                              >
                                <div className="icon-wrapper">
                                  <SuccessIcon style={{ width: "40px" }} />
                                </div>
                              </div>

                              {(() => {
                                if (
                                  checkIfSummaryDataHasBalanceField(
                                    getSummaryData?.data.bankSummaries
                                  )
                                ) {
                                  if (
                                    theme.settings.summary?.toggleButton
                                      ?.visibility
                                  ) {
                                    return (
                                      <ToggleButton
                                        label={
                                          theme.settings?.summary?.texts
                                            ?.toggleButtonLabel
                                            ? getTextWithPopulatePlaceholders(
                                                theme.settings.summary.texts
                                                  .toggleButtonLabel,
                                                resources
                                              )
                                            : t("summaryScreen.accountBalance")
                                        }
                                        state={
                                          theme.settings.summary?.toggleButton
                                            ?.default === "on"
                                            ? "on"
                                            : "off"
                                        }
                                      />
                                    );
                                  }
                                }
                              })()}

                              <BankSummaries
                                bankSummaries={
                                  getSummaryData?.data.bankSummaries
                                }
                              />
                            </SelectedBanksContainer>
                            {!resources?.processCompleted &&
                            !resources?.lender?.hideReturnToLenderButton ? (
                              <ActionFooter>
                                <ButtonsFooterContainer>
                                  <LoadableButton
                                    id="completeFinish"
                                    isLoading={orchestrationFinish.loading}
                                    className="btn btn-lg  text-center w-100"
                                    onClick={() => {
                                      !resources?.lender.url
                                        ? history.push(
                                            `/finish?orchestrationToken=${orchestrationToken}`,
                                            {
                                              status: "success",
                                            }
                                          )
                                        : fetchResourcesBeforeOrchestrationFinish();
                                    }}
                                    disabled={orchestrationFinish.loading}
                                    variant={
                                      theme.settings.summary?.texts
                                        ?.summaryFinishButton?.type === "link"
                                        ? "link"
                                        : "primary"
                                    }
                                  >
                                    {theme.settings.summary?.texts
                                      ?.summaryFinishButton ? (
                                      getTextWithPopulatePlaceholders(
                                        theme.settings.summary.texts
                                          .summaryFinishButton,
                                        resources
                                      )
                                    ) : (
                                      <Trans
                                        i18nKey="summaryScreen.returnTo"
                                        tOptions={{
                                          lenderName,
                                        }}
                                      >
                                        <b></b>
                                      </Trans>
                                    )}
                                  </LoadableButton>
                                </ButtonsFooterContainer>
                              </ActionFooter>
                            ) : null}
                            {!resources?.processCompleted &&
                            resources?.lender?.hideReturnToLenderButton ? (
                              <Content className="mt-5">
                                <FinishJourneyMessage
                                  style={
                                    theme?.settings?.summary?.texts
                                      ?.finishJourneyMessage?.styles
                                  }
                                >
                                  {theme.settings.summary?.texts
                                    ?.finishJourneyMessage
                                    ? getTextWithPopulatePlaceholders(
                                        theme.settings.summary.texts
                                          .finishJourneyMessage,
                                        resources
                                      )
                                    : t("summaryScreen.finishJourneyMessage")}
                                </FinishJourneyMessage>
                              </Content>
                            ) : null}
                          </Panel>
                        </div>
                      ) : null}
                    </>
                  )}
                </>
              ) : null
              // <ButtonTryAgainWrapper style={{ maxWidth: "100%" }}>
              //   <PageTitle style={{ marginBottom: "20px" }}>
              //     {t("completeScreen.connectionServerError")}
              //   </PageTitle>
              //   <LoadableButton
              //     className="btn exp-default  text-uppercase-first-letter text-center  btn-block"
              //     isLoading={false}
              //     onClick={() => {
              //       setHubConnectionError(false);
              //       connection.stop();
              //       startConnection();
              //     }}
              //   >
              //     {t("common.tryAgain")}
              //   </LoadableButton>
              // </ButtonTryAgainWrapper>
            }
          </Content>
          {theme.settings?.assuranceSection?.visible &&
            showWaitForSummaryData && (
              <Content className="mt-0">
                <AssuranceItems />
              </Content>
            )}
        </ScreenContainer>
      </div>
    </ScreenContentWrapper>
  );
};

export default Complete;
