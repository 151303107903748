import { createGlobalStyle, css } from "styled-components/macro";

const toStyleAttribute = (obj) => {
  console.log({ obj });
  const test = Object.keys(obj)
    .map((key) => {
      // Camel case property names to CSS selector names.
      return key.replace(/([A-Z])/g, "-$1").toLowerCase() + ":" + obj[key];
    })
    .join(";");

  console.log({ test });
  return test;
};

export const GlobalStyles = createGlobalStyle`

  @font-face {
    font-family: ${({ theme }) =>
      `${theme.fontFamily ? theme.fontFamily : theme.font}`};
    src: ${({ theme }) => `url(${theme.font}) format(${theme.fontType})`};
  }
  @font-face {
    font-family: ${({ theme }) =>
      `${
        theme?.settings?.texts?.pageTitle?.fontFamilyForTitles
          ? theme?.settings?.texts?.pageTitle?.fontFamilyForTitles
          : theme?.settings?.texts?.pageTitle?.fontForTitles
      }`};
    src: ${({ theme }) =>
      `url(${theme?.settings?.texts?.pageTitle?.fontForTitles}) format(${theme?.settings?.texts?.pageTitle?.fontTypeForTitles})`};
  }
  * {
    font-family: ${({ theme }) =>
      `${theme.fontFamily ? theme.fontFamily : theme.font}`};
  }

  .with-custom-font {
    ${(props) =>
      props.theme?.settings?.texts?.pageTitle?.fontFamilyForTitles &&
      css`
        font-family: ${({ theme }) =>
          `${
            theme?.settings?.texts?.pageTitle?.fontFamilyForTitles
              ? theme?.settings?.texts?.pageTitle?.fontFamilyForTitles
              : theme?.settings?.texts?.pageTitle?.fontForTitles
          }`};
      `}
  }
  body {
    background: ${({ theme }) => theme.colors.body};
    color: ${({ theme }) => theme?.settings?.texts?.paragraph?.color};
    // font-family: ${({ theme }) => theme.font};
    transition: all 0.50s linear;
   
  }

  p {
   ${(props) =>
     props?.theme?.settings?.texts?.paragraph?.styles &&
     toStyleAttribute(props?.theme?.settings?.texts?.paragraph?.styles)} 
  }
  .small-text {
    ${(props) =>
      props?.theme?.settings?.texts?.smallText?.styles &&
      toStyleAttribute(props?.theme?.settings?.texts?.smallText?.styles)}  
  }
  .page-title {
     ${({ theme }) =>
       theme?.settings?.texts?.pageTitle?.styles &&
       toStyleAttribute(theme?.settings?.texts?.pageTitle?.styles)};
  }
  .accent-text {
    color: ${(props) => props.theme.colors.secondaryAccentText};
  }
  .btn {
    padding: ${(props) => props.theme.colors.buttons?.padding};
  }
  button[type=button] {
    border-radius: ${(props) => props.theme.colors.buttons.borderRadius};
    &:before{
      border-radius: ${(props) => props.theme.colors.buttons.borderRadius};
    }
    
  }

  svg:not(.chevron) {
    fill: ${(props) => props?.theme?.colors?.icons?.default};
    color: ${(props) => props?.theme?.colors?.icons?.default};
  }
  
  .modal-body {
    border-radius: ${(props) => props?.theme?.settings?.popupsBorderRadius};

    button {
      max-width: none !important;
    }
  }

  .download-link {
    color: #007bff;
    cursor: pointer;
   ${(props) =>
     props?.theme?.settings?.summary?.links &&
     toStyleAttribute(props?.theme?.settings?.summary?.links?.styles)}   
  }

  .modal-header{
    border: none;
  }
   .modal-title {
    ${(props) =>
      props?.theme?.settings?.modals?.modalTitle &&
      toStyleAttribute(props?.theme?.settings?.modals?.modalTitle?.styles)}   
    }
    .modal-subtitle {
    ${(props) =>
      props?.theme?.settings?.modals?.modalSubTitle &&
      toStyleAttribute(props?.theme?.settings?.modals?.modalSubTitle?.styles)}
    }
`;
