import React, { useState } from "react";
import styled, { useTheme } from "styled-components/macro";
import { useLender } from "../../hooks/useLender";
import { useTranslation } from "react-i18next";
import ExperianLogo from "../../assets/ExperianLogo.png";
import NeedHelpPopup from "../needHelp/needHelpPopup";
import NeedHelpLinkComponent from "../needHelp/NeedHelpLinkComponent";
import Modal from "../modal/Modal";
const Nav = styled.nav`
  height: var(--section-height);
  padding: 0 0 10px;
  height: 66px;

  .inner-wrapper {
    width: 100%;
    max-width: ${(props) =>
      props.theme?.settings?.screenContainer?.maxWidth ?? "810px"};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 2px solid var(--exp-light-grey);

    .fe-version {
      display: flex;
      align-items: center;
      font-size: 12px;
    }

    .img-logo {
      max-width: 8.8rem;
      max-height: 4.2rem;

      @media (min-width: 1024px) {
        max-width: 12.8rem;
        height: 100%;
      }
    }
  }
`;
const LogosContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;

  .experian-logo {
    max-width: 10.8rem;
    @media (min-width: 1024px) {
      max-width: 12.8rem;
      height: 100%;
    }
  }
`;

const NeedHelpLinkContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  a {
    font-size: 1.2rem;
    text-decoration: underline;
    color: ${(props) => props.theme.colors.text};
  }
`;

interface Props {}

const Navbar: React.FC<Props> = () => {
  const { t } = useTranslation();
  const [hasError, setHasError] = useState(false);
  const { logoUrl, name, supportPage } = useLender();
  const theme: any = useTheme();
  const [showNeedHelpModal, setShowNeedHelpModal] = useState(false);
  const additionalStyle = !theme.settings?.header?.visible
    ? {
        borderRight: "2px solid #e7e7e7",
        paddingRight: "2.4rem",
      }
    : undefined;
  return (
    <Nav className="navbar navbar-local">
      <div className="inner-wrapper" style={theme?.settings?.subHeader?.styles}>
        <LogosContainer>
          <img
            alt="Experian logo"
            className="experian-logo"
            style={additionalStyle}
            src={ExperianLogo}
            onError={() => setHasError(true)}
          />
          {hasError || !logoUrl ? (
            <div className="d-flex align-items-center mr-3">{name}</div>
          ) : !theme.settings?.header?.visible ? (
            <div style={{ alignSelf: "center", paddingLeft: "2.4rem" }}>
              <img
                alt="Lender logo"
                className="img-logo w-100"
                src={logoUrl}
                onError={() => setHasError(true)}
              />
            </div>
          ) : null}
        </LogosContainer>
        <NeedHelpLinkContainer>
          {supportPage &&
            !theme.settings?.header?.visible &&
            theme.settings?.needHelp?.type !== "popup" && (
              <a href={supportPage} target="_blank">
                <NeedHelpLinkComponent />
              </a>
            )}
          {supportPage &&
            !theme.settings?.header?.visible &&
            theme.settings?.needHelp?.type == "popup" && (
              <a
                style={{
                  cursor: "pointer",
                  ...theme?.settings?.needHelpText?.styles,
                }}
                onClick={() => setShowNeedHelpModal(true)}
              >
                <NeedHelpLinkComponent />
              </a>
            )}
        </NeedHelpLinkContainer>

        {showNeedHelpModal ? (
          <Modal>
            <NeedHelpPopup onClose={() => setShowNeedHelpModal(false)} />
          </Modal>
        ) : null}
      </div>
    </Nav>
  );
};

export default Navbar;
