import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { ResourcesContext } from "../../context/consumer";
import { getTextWithPopulatePlaceholders } from "../../utils/helpers";

const NeedHelpLinkComponent = () => {
  const { t } = useTranslation();
  const { resources } = useContext(ResourcesContext);
  const theme: any = useTheme();
  const text = theme.settings?.needHelpText
    ? getTextWithPopulatePlaceholders(theme.settings.needHelpText, resources)
    : t("slugs.needHelp");

  return <>{text}</>;
};

export default NeedHelpLinkComponent;
