import React, { useContext, useEffect, useState } from "react";
import ParagraphComponent from "./ParagraphComponent";
import { Trans, useTranslation } from "react-i18next";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as OpenBankingIcon_1 } from "../../../../assets/openBankingIcons/icon_1.svg";
import { ReactComponent as OpenBankingIcon_2 } from "../../../../assets/openBankingIcons/icon_2.svg";
import { ReactComponent as OpenBankingIcon_3 } from "../../../../assets/openBankingIcons/icon_3.svg";
import { ReactComponent as OpenBankingIcon_4 } from "../../../../assets/openBankingIcons/icon_4.svg";
import { ReactComponent as OpenBankingIcon_5 } from "../../../../assets/openBankingIcons/icon_5.svg";
import parse from "html-react-parser";

import { ReactComponent as ExperianIcon_1 } from "../../../../assets/experianIcons/icon_1.svg";
import { ReactComponent as ExperianIcon_2 } from "../../../../assets/experianIcons/icon_2.svg";
import { ReactComponent as ExperianIcon_3 } from "../../../../assets/experianIcons/icon_3.svg";
import { ReactComponent as ExperianIcon_4 } from "../../../../assets/experianIcons/icon_4.svg";

import { ReactComponent as LicenceIcon_1 } from "../../../../assets/licenceIcons/icon_1.svg";
import { ReactComponent as LicenceIcon_2 } from "../../../../assets/licenceIcons/icon_2.svg";
import { ReactComponent as LicenceIcon_3 } from "../../../../assets/licenceIcons/icon_3.svg";
import { ReactComponent as LicenceIcon_4 } from "../../../../assets/licenceIcons/icon_4.svg";

import { ReactComponent as Shield } from "../../../../assets/shield.svg";
import { useTheme } from "styled-components";
import { ImageContainer } from "../../../../components/assuranceSection/AssuranceItems";

import { TextResourcesContext } from "../../../../context/textResourcesContext";
import { getResource } from "../../../../utils/helpers";
function ContentComponent() {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const { data } = useContext(TextResourcesContext);
  console.log({ ContentComponent: data });
  const imageSizes = { height: "30px", minWidth: "30px" };
  const moreInformationIcons =
    theme?.settings?.intro?.texts?.moreInformationSection?.icons;
  const itemsArray: any = [
    {
      title: getResource(data, "CJ_introScreen_moreInfoAboutOBTitle"),
      paragraphs: [
        {
          icon: !moreInformationIcons?.aboutOpenBanking?.[0]?.icon ? (
            <OpenBankingIcon_1 style={imageSizes} />
          ) : (
            <ImageContainer
              style={imageSizes}
              $icon={moreInformationIcons?.aboutOpenBanking?.[0]?.icon}
            />
          ),
          text: getResource(data, "CJ_introScreen_moreInfoAboutOBDesc1"),
        },
        {
          icon: !moreInformationIcons?.aboutOpenBanking?.[1]?.icon ? (
            <OpenBankingIcon_2 style={imageSizes} />
          ) : (
            <ImageContainer
              style={imageSizes}
              $icon={moreInformationIcons?.aboutOpenBanking?.[1]?.icon}
            />
          ),
          text: getResource(data, "CJ_introScreen_moreInfoAboutOBDesc2"),
        },
        {
          icon: !moreInformationIcons?.aboutOpenBanking?.[2]?.icon ? (
            <OpenBankingIcon_3 style={imageSizes} />
          ) : (
            <ImageContainer
              style={imageSizes}
              $icon={moreInformationIcons?.aboutOpenBanking?.[2]?.icon}
            />
          ),
          text: getResource(data, "CJ_introScreen_moreInfoAboutOBDesc3"),
        },
        {
          icon: !moreInformationIcons?.aboutOpenBanking?.[3]?.icon ? (
            <OpenBankingIcon_4 style={imageSizes} />
          ) : (
            <ImageContainer
              style={imageSizes}
              $icon={moreInformationIcons?.aboutOpenBanking?.[3]?.icon}
            />
          ),
          text: getResource(data, "CJ_introScreen_moreInfoAboutOBDesc4"),
        },
        {
          icon: !moreInformationIcons?.aboutOpenBanking?.[4]?.icon ? (
            <OpenBankingIcon_5 style={imageSizes} />
          ) : (
            <ImageContainer
              style={imageSizes}
              $icon={moreInformationIcons?.aboutOpenBanking?.[4]?.icon}
            />
          ),
          text: getResource(data, "CJ_introScreen_moreInfoAboutOBDesc5"),
        },
      ],
    },
    {
      title: getResource(data, "CJ_introScreen_moreInfoAboutExperianTitle"),
      paragraphs: [
        {
          icon: !moreInformationIcons?.aboutExperian?.[0]?.icon ? (
            <ExperianIcon_1 style={imageSizes} />
          ) : (
            <ImageContainer
              style={imageSizes}
              $icon={moreInformationIcons?.aboutExperian?.[0]?.icon}
            />
          ),

          text: getResource(data, "CJ_introScreen_moreInfoAboutExperianDesc1"),
        },
        {
          icon: !moreInformationIcons?.aboutExperian?.[1]?.icon ? (
            <ExperianIcon_2 style={imageSizes} />
          ) : (
            <ImageContainer
              style={imageSizes}
              $icon={moreInformationIcons?.aboutExperian?.[1]?.icon}
            />
          ),
          text: getResource(data, "CJ_introScreen_moreInfoAboutExperianDesc2"),
        },
        {
          icon: !moreInformationIcons?.aboutExperian?.[2]?.icon ? (
            <ExperianIcon_3 style={imageSizes} />
          ) : (
            <ImageContainer
              style={imageSizes}
              $icon={moreInformationIcons?.aboutExperian?.[2]?.icon}
            />
          ),
          text: getResource(data, "CJ_introScreen_moreInfoAboutExperianDesc3"),
        },
        {
          icon: !moreInformationIcons?.aboutExperian?.[3]?.icon ? (
            <ExperianIcon_4 style={imageSizes} />
          ) : (
            <ImageContainer
              style={imageSizes}
              $icon={moreInformationIcons?.aboutExperian?.[3]?.icon}
            />
          ),
          text: getResource(data, "CJ_introScreen_moreInfoAboutExperianDesc4"),
        },
      ],
    },
    {
      title: getResource(data, "CJ_introScreen_moreInfoAboutOBLicenseTitle"),
      paragraphs: [
        {
          icon: !moreInformationIcons?.aboutOurLicence?.[0]?.icon ? (
            <LicenceIcon_1 style={imageSizes} />
          ) : (
            <ImageContainer
              style={imageSizes}
              $icon={moreInformationIcons?.aboutOurLicence?.[0]?.icon}
            />
          ),

          text: getResource(data, "CJ_introScreen_moreInfoAboutOBLicenseDesc1"),
        },
        {
          icon: !moreInformationIcons?.aboutOurLicence?.[1]?.icon ? (
            <LicenceIcon_2 style={imageSizes} />
          ) : (
            <ImageContainer
              style={imageSizes}
              $icon={moreInformationIcons?.aboutOurLicence?.[1]?.icon}
            />
          ),
          text: getResource(data, "CJ_introScreen_moreInfoAboutOBLicenseDesc2"),
        },
        {
          icon: !moreInformationIcons?.aboutOurLicence?.[2]?.icon ? (
            <LicenceIcon_3 style={imageSizes} />
          ) : (
            <ImageContainer
              style={imageSizes}
              $icon={moreInformationIcons?.aboutOurLicence?.[2]?.icon}
            />
          ),
          text: (() => {
            let text = getResource(
              data,
              "CJ_introScreen_moreInfoAboutOBLicenseDesc3"
            );
            let regex = /<0>(.*?)<\/0>/g;

            let mathes = [];
            let match;
            while ((match = regex.exec(text)) !== null) {
              mathes.push(match[1]);
            }

            text = text.replace(
              "<0>",
              `<a style={theme?.settings?.intro?.texts?.moreInformationSectionLink?.style} href="${mathes[0]}" rel="noreferrer" target="_blank">`
            );

            text = text.replace("</0>", "</a>");
            return parse(text);
          })(),
        },
        {
          icon: !moreInformationIcons?.aboutOurLicence?.[3]?.icon ? (
            <LicenceIcon_4 style={imageSizes} />
          ) : (
            <ImageContainer
              style={imageSizes}
              $icon={moreInformationIcons?.aboutOurLicence?.[3]?.icon}
            />
          ),
          text: getResource(data, "CJ_introScreen_moreInfoAboutOBLicenseDesc4"),
        },
      ],
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentItem, setCurrentItem] = useState(itemsArray[currentIndex]);

  const checkIndex = (index: number) => {
    if (index > itemsArray.length - 1) {
      return 0;
    }
    if (index < 0) {
      return itemsArray.length - 1;
    }
    return index;
  };

  const prev = () => {
    setCurrentIndex((currentIndex) => {
      return checkIndex(currentIndex - 1);
    });
  };
  const next = () => {
    setCurrentIndex((currentIndex) => {
      return checkIndex(currentIndex + 1);
    });
  };

  useEffect(() => {
    setCurrentItem(itemsArray[currentIndex]);
  }, [currentIndex]);
  useEffect(() => {
    setCurrentItem(itemsArray[0]);
  }, []);
  return (
    <>
      <div
        className="d-flex mb-3 px-5 align-items-center mt-3"
        style={{
          justifyContent: "space-evenly",
          width: "80%",
          margin: "auto",
        }}
      >
        <FontAwesomeIcon
          style={{
            cursor: "pointer",
            visibility: currentIndex > 0 ? "visible" : "hidden",
          }}
          onClick={prev}
          icon={faChevronLeft}
        ></FontAwesomeIcon>

        <h4 className="m-0 with-custom-font modal-title">
          {currentItem.title}
        </h4>

        {currentIndex < itemsArray.length - 1 ? (
          <FontAwesomeIcon
            onClick={next}
            style={{ cursor: "pointer" }}
            icon={faChevronRight}
          ></FontAwesomeIcon>
        ) : null}
      </div>
      <div
        className="p-3"
        style={{
          overflow: "auto",
          height: "calc(100% - 100px)",
        }}
      >
        {currentItem?.paragraphs.map((paragraph: any) => {
          return (
            <ParagraphComponent text={paragraph?.text} image={paragraph.icon} />
          );
        })}
      </div>
    </>
  );
}

export default ContentComponent;
