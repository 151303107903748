import React from "react";
import PropTypes from "prop-types";
import ClientSideError from "../../screens/errors";

/**
 * Error boundary is a new React feature, that when an error on the client occurs (e.g trying to map over null)
 * Renders the component instead of umounting the whole application, thus introducing a better UX!
 */
export default class ErrorBoundry extends React.Component {
  /** State whether an clientside error occured */
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(e: any) {
    // TODO: logging service error, info
    console.log(e);
  }

  static propTypes = {
    children: PropTypes.node,
  };

  /** As the error boundary wraps the application, depending on the state it either renders all of its children or an error page */
  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    return hasError ? <ClientSideError /> : children;
  }
}
