import { AxiosResponse } from "axios";
import { useState, useEffect, useCallback } from "react";
import api from "../utils/api";

export type ConsentStatus = "Accepted" | "Error";

export type Consent = {
  id: string;
  name: string;
  logo: string;
  status: ConsentStatus;
  providerCode: string;
};

const useOrchestrationFinish = (cb: () => void) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(false);
  const [success, setSuccess] = useState(false);

  const refetch = async () => {
    setLoading(true);
    setError!(null);
    sendOrchestrationFinish();
  };

  const sendOrchestrationFinish = useCallback(async () => {
    try {
      setLoading(true);
      await orchestrationFinish();
      setSuccess(true);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [setError]);

  useEffect(() => {
    if (success) {
      cb();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return {
    loading,
    error,
    refetch,
  };
};

const orchestrationFinish = (): Promise<AxiosResponse<{}>> =>
  api.put(`/api/orchestrations/finish`);

export { useOrchestrationFinish, orchestrationFinish };
