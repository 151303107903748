import { useState, useCallback } from "react";

import api from "../utils/api";
import { AxiosResponse } from "axios";
import { useError } from "./useError";

type AcceptResponse = {};

const useExitConsent = (orchestrationToken: string) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useError();

  const clearError = useCallback(() => setError!(null), [setError]);
  const doExitConsent = useCallback(
    async (cb: () => void) => {
      try {
        setLoading(true);
        clearError();
        await exitConsent(orchestrationToken);
        cb();
      } catch (error) {
        setError!(error);
      } finally {
        setLoading(false);
      }
    },
    [orchestrationToken, setError, clearError]
  );

  const refetch = (cb: () => void) => {
    setLoading(true);
    setError!(null);
    doExitConsent(cb);
  };

  return {
    loading,
    error,
    clearError,
    execute: doExitConsent,
    refetch,
  };
};

const exitConsent = (
  orchestrationToken: string
): Promise<AxiosResponse<AcceptResponse>> =>
  api.patch(`/api/orchestrations/${orchestrationToken}/lock-consent`);

export { useExitConsent, exitConsent };
