import { useContext, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import Checkbox from "../../../components/checkbox";
import { ResourcesContext } from "../../../context/consumer";
import {
  getResource,
  getTextWithPopulatePlaceholders,
} from "../../../utils/helpers";
import {
  ActionFooter,
  ActionFooterContainer,
} from "../../layout/screens.layout";
import parse from "html-react-parser";
import { TextResourcesContext } from "../../../context/textResourcesContext";
const TermsContainer = styled(ActionFooter)`
  background: ${(props) => props.theme?.colors?.primaryAccentBackground};
`;

const Paragraph = styled.p`
  line-height: 2rem;
  text-align: justify;
  font-size: 1.4rem !important;
  .terms-link,
  .policy-link {
    color: ${(props) =>
      props.theme.colors.primaryAccentText || "var(--exp-light-blue)"};
    text-decoration: underline;
    font-weight: 500;
    font-style: normal;
  }
`;

function CheckboxesComponent({
  setAgreeTerms,
  setShowModal,
  setShowModalPrivacyPolicy,
  agreeTerms,
}: any) {
  const theme: any = useTheme();
  const { resources } = useContext(ResourcesContext);
  const { data } = useContext(TextResourcesContext);
  useEffect(() => {
    const terms = document.getElementById("termsAction");
    terms?.addEventListener("click", () => {
      setShowModal(true);
    });

    const privacy = document.getElementById("privacyAction");
    privacy?.addEventListener("click", () => {
      setShowModalPrivacyPolicy(true);
    });
    return () => {
      terms?.removeEventListener("click", setShowModal(false));

      privacy?.removeEventListener("click", setShowModalPrivacyPolicy(false));
    };
  }, []);

  const getTermsAndPrivacyPolicy = (text: string) => {
    const replacements = [
      {
        search: "<0>",
        replace: "<span className='terms-link' id='termsAction'>",
      },
      { search: "</0>", replace: "</span>" },
      {
        search: "<0>",
        replace: "<span className='policy-link' id='privacyAction'>",
      },
      { search: "</0>", replace: "</span>" },
    ];

    replacements.forEach((replacement) => {
      text = text.replace(replacement.search, replacement.replace);
    });

    return text;
  };

  return (
    <>
      <TermsContainer
        className={theme.settings.intro.layout == "v2" ? "bg-none" : ""}
      >
        <ActionFooterContainer style={{ alignItems: "flex-start" }}>
          <Checkbox
            id="agreeTerms"
            checked={agreeTerms}
            onChange={() => setAgreeTerms((t: any) => !t)}
          />
          <Paragraph
            as="span"
            id="introTheTerms"
            className="ml-3 cursor-pointer small-text"
            onClick={() => setAgreeTerms((t: any) => !t)}
          >
            {parse(
              getTermsAndPrivacyPolicy(
                getResource(data, "CJ_introScreen_iHaveReadAndAccepted")
              )
            )}
          </Paragraph>
        </ActionFooterContainer>
      </TermsContainer>
    </>
  );
}

export default CheckboxesComponent;
