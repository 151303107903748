import React, { ReactNode, useEffect, useRef, useState } from "react";
import styled, { useTheme } from "styled-components/macro";
import { animated, useSpring } from "react-spring";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
interface Props {
  header: ReactNode;
  headerStyle?: any;
  defaultOpen?: boolean;
  style?: any;
  iconId?: string;
  parentScreen: string;
}

const Header = styled.div<{ $parentScreen: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.6rem;
  min-height: 32px;
  border-radius: 8px;
  background-color: ${(props) =>
    props.theme?.settings?.[props.$parentScreen]?.accordionSettings?.header
      ?.headerBackground};
  padding: 0 12px;
  cursor: pointer;
`;
const ChevronDownIcon = styled(FontAwesomeIcon)``;

const Collapsable: React.FC<Props> = ({
  header,
  headerStyle,
  children,
  style,
  defaultOpen = false,
  iconId = undefined,
  parentScreen,
}) => {
  const [open, setOpen] = useState(defaultOpen);
  const [contentMaxHeight, setContentMaxHeight] = useState(0);
  const ref = useRef<any>();
  const theme: any = useTheme();
  useEffect(() => {
    const calcContentMaxHeight = () => {
      ref && setContentMaxHeight(1000); //setContentMaxHeight(ref.current!.scrollHeight + 20);
    };

    calcContentMaxHeight();

    // window.addEventListener("resize", () => calcContentMaxHeight());

    // return () => window.removeEventListener("resize", calcContentMaxHeight());
  }, [ref, contentMaxHeight]);

  useEffect(() => {
    if (defaultOpen) {
      setOpen(true);
    }
  }, [defaultOpen]);

  const { transform, ...props } = useSpring({
    opacity: open ? 1 : 0,
    maxHeight: open ? `${contentMaxHeight}px` : "0px",
    transform: open ? "rotate(180deg)" : "rotate(0deg)",
    config: { duration: 250 },
  });

  const renderCustomIcons = (parentScreen: string) => {
    const openIcon =
      theme?.settings?.[parentScreen]?.accordionSettings?.openIcon;

    const closeIcon =
      theme?.settings?.[parentScreen]?.accordionSettings?.closeIcon;
    return (
      <span
        style={{
          backgroundImage: open ? openIcon : closeIcon,
          backgroundRepeat: "no-repeat",
          height: "2rem",
          width: "2rem",
        }}
      ></span>
    );
  };
  return (
    <div
      tabIndex={0}
      onKeyDown={(e) => e.key === "Enter" && setOpen(!open)}
      style={{
        marginBottom: parentScreen === "intro" ? "8px" : 0,
        width: "100%",
        ...style,
      }}
    >
      <Header
        style={{ ...headerStyle }}
        data-testid="c-header"
        onClick={() => setOpen((open) => !open)}
        $parentScreen={parentScreen}
      >
        {header}

        {theme?.settings?.[parentScreen]?.accordionSettings?.openIcon ? (
          renderCustomIcons(parentScreen)
        ) : (
          <animated.span style={{ transform }}>
            <ChevronDownIcon icon={faChevronDown} size="lg" id={iconId} />
          </animated.span>
        )}
      </Header>
      <animated.div
        data-testid="c-body"
        ref={ref}
        style={{
          overflow: "hidden",
          padding: "0 12px",
          fontSize: "1.4rem",
          lineHeight: 1.3,
          ...props,
        }}
      >
        {open && <div className="mt-2"> </div>}
        {children}
      </animated.div>
    </div>
  );
};

export default Collapsable;
