import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import backend from "i18next-xhr-backend";

// import translationsEN from "../assets/locales/en/translation.json";
// import translationsES from "../assets/locales/es/translation.json";

const initI18n = (language: string) => {
  // const resources = {
  //   en: {
  //     translation: translationsEN,
  //   },
  //   es: {
  //     translation: translationsES,
  //   },
  // };

  return i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(backend)
    .init({
      // resources,
      lng: language.toLowerCase(),
      fallbackLng: "en",

      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });
};

export default initI18n;
