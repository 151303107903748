import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme, css } from "styled-components";
import { LoadableButton } from "../../../components/button/loadable-button.component";

import { Footer, FooterContainer } from "../../layout/screens.layout";
import { scrollTop } from "../../../utils/scrollTop";
import { useHistory } from "react-router";
import { useAcceptConsent } from "../../../hooks/useAcceptConsent";
import { SnackbarContext } from "../../../context/snackbar";
import {
  getModalCloseIcon,
  getModalIcon,
  getTextWithPopulatePlaceholders,
} from "../../../utils/helpers";
import { ResourcesContext } from "../../../context/consumer";
import Modal from "../../../components/modal/Modal";

import { Button, ModalWrapper } from "../../select/select";
import { SelectedBankContext } from "../../../context/selectedBank";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as InstructionsModalIcon } from "../../../assets/instructions_modal_icon.svg";
const ButtonsFooter = styled(Footer)`
  height: auto;
  ${(props) =>
    props.theme.settings.consent.layout === "v1" &&
    css`
      justify-content: flex-end;
      @media (max-width: 780px) {
        justify-content: center;
      }
    `}
`;
const ButtonsFooterContainer = styled(FooterContainer)<{ maxWidth: string }>`
  display: flex;
  padding: 8px 0;
  justify-content: space-between;
  max-width: ${({ maxWidth }) => maxWidth ?? "500px"};
  ${(props) =>
    props.theme.settings.consent.layout === "v1" &&
    css`
      justify-content: flex-end;

      align-items: center;
      gap: 0.5rem;
      @media (max-width: 780px) {
        flex-direction: column;
        button {
          max-width: none;
          width: 100%;
          margin-right: 0;
        }
      }
    `}
  width: 100%;
  ${(props) =>
    props.theme.settings.consent.layout === "v2" &&
    css`
      flex-direction: column;
    `}
  gap: 1rem;
  & .btn {
    padding: 0.8rem 3rem;
    margin-top: 0;
  }

  @media (max-width: 640px) {
    width: 100%;
  }
  ${(props) =>
    props.theme.settings.consent.layout === "v1" &&
    css`
      justify-content: end;

      @media (max-width: 780px) {
        justify-content: center;
        flex-direction: column;
        button {
          max-width: none;
          width: 100%;
          margin-right: 0;
        }
      }
    `}
`;
function ButtonsComponent({
  rejectConsentLoading,
  rejectConsent,
  consent,
  agree,
  consentToken,
  rejectConsentError,
}: any) {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const history = useHistory();
  const { bank } = useContext(SelectedBankContext);
  const { execute, error, loading } = useAcceptConsent(consentToken);
  const { openSnackbar } = useContext(SnackbarContext);
  const { resources } = useContext(ResourcesContext);
  const [showModal, setShowModal] = useState(false);
  const modal = useRef(null);
  useEffect(() => {
    if (error || rejectConsentError) {
      openSnackbar(true);
    }
  }, [error, rejectConsentError, openSnackbar]);

  return (
    <ButtonsFooter
      style={{ bottom: "1.5rem" }}
      className={`mt-4 consent ${
        theme.settings.intro.layout == "v2" && "bg-none"
      }`}
    >
      <ButtonsFooterContainer
        maxWidth={
          theme.settings.consent?.texts?.consentBackButton?.type === "link" &&
          theme.settings.consent.layout === "v1"
            ? "none"
            : "500px"
        }
      >
        <LoadableButton
          id="consentReject"
          isLoading={rejectConsentLoading}
          disabled={rejectConsentLoading || loading}
          style={{
            maxWidth:
              theme.settings.consent?.texts?.consentBackButton?.type ===
                "link" && theme.settings.consent.layout === "v1"
                ? "max-content"
                : "none",
          }}
          className={`${
            !theme?.colors?.buttons?.secondary ? " exp-outline-default" : ""
          } btn text-uppercase-first-letter text-center  btn-block`}
          onClick={() => {
            rejectConsent(() => scrollTop(() => history.goBack()));
          }}
          variant={
            theme.settings.consent?.texts?.consentBackButton?.type === "link"
              ? "link"
              : "secondary"
          }
        >
          {theme.settings?.consent?.texts?.consentBackButton
            ? getTextWithPopulatePlaceholders(
                theme.settings.consent.texts.consentBackButton,
                resources
              )
            : t("common.back")}
        </LoadableButton>
        <LoadableButton
          id="consentContinue"
          isLoading={loading}
          style={{
            maxWidth:
              theme.settings.consent.layout === "v1" &&
              theme.settings.consent?.texts?.consentContinueButton?.type ===
                "link"
                ? "250px"
                : "none",
          }}
          variant={
            theme.settings.consent?.texts?.consentContinueButton?.type ===
            "link"
              ? "link"
              : "primary"
          }
          className={`${
            !theme?.colors?.buttons?.secondary ? "exp-default bg-white" : ""
          } btn text-uppercase-first-letter text-center  btn-block`}
          disabled={!consent || !agree || loading || rejectConsentLoading}
          onClick={() => {
            if (bank?.consentInstructionText) {
              setShowModal(true);
            } else {
              execute((url: string) => {
                window.location.href = url;
              });
            }
          }}
        >
          {theme.settings?.consent?.texts?.consentContinueButton
            ? getTextWithPopulatePlaceholders(
                theme.settings.consent.texts.consentContinueButton,
                resources
              )
            : t("common.continueToProvider")}
        </LoadableButton>
      </ButtonsFooterContainer>

      <Modal>
        <ModalWrapper className={showModal ? "visible" : ""} ref={modal}>
          <div className="modal-body">
            <div className="modal-header">
              {getModalIcon(
                theme?.colors?.icons?.instructionsModalIcon,
                <InstructionsModalIcon />
              )}

              <span
                className="close-btn"
                onClick={() => {
                  setShowModal(false);
                  document.body.style.overflow = "auto";
                }}
              >
                {getModalCloseIcon(
                  theme?.colors?.icons?.modalCloseIcon,
                  theme?.colors?.icons?.default
                )}
              </span>
            </div>
            <div>
              {" "}
              <h4>
                {bank?.consentInstructionText &&
                  parse(bank!.consentInstructionText)}
              </h4>
            </div>
            <div className="btns-wrapper">
              <Button
                id="yesExit"
                isLoading={false}
                disabled={false}
                onClick={() => {
                  execute((url: string) => {
                    window.location.href = url;
                  });
                }}
                className="btn exp-outline-default  text-uppercase-first-letter text-center bg-white btn-block"
                variant={
                  theme.settings?.consent?.texts?.consentDefaultInstructionModal
                    ?.continueButton?.type === "link"
                    ? "link"
                    : "primary"
                }
              >
                {theme.settings?.consent?.texts?.consentDefaultInstructionModal
                  ?.continueButton
                  ? getTextWithPopulatePlaceholders(
                      theme.settings.consent.texts
                        .consentDefaultInstructionModal?.continueButton,
                      resources
                    )
                  : t("common.continue")}
              </Button>
            </div>
          </div>
        </ModalWrapper>
      </Modal>
    </ButtonsFooter>
  );
}

export default ButtonsComponent;
