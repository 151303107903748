import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ResourcesContext } from "../../../context/consumer";

const AddMissingBankWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;

  div {
    width: 100%;
  }

  input,
  select {
    height: 3rem;
    font-size: 1.2rem;
  }

  .form-group {
    display: flex;
    align-items: center;
  }
`;

interface Props {
  setBankName: (bankName: string) => void;
  setCountry: (country: string) => void;
  country?: string | null;
}

interface Country {
  name: string;
  market?: string;
  slug: string;
}

function AddMissingBank({ setBankName, country, setCountry }: Props) {
  const { t } = useTranslation();
  const { resources } = useContext(ResourcesContext);
  const countries: [Country] = t("selectScreen.countries", {
    returnObjects: true,
  });

  useEffect(() => {
    const defaultCountryName = countries.filter(
      (country: Country) => country?.market == resources?.lender.market
    )[0].slug;
    country == null && setCountry(defaultCountryName);
  }, [resources, countries]);

  return (
    <AddMissingBankWrapper>
      <div>
        <div className="form-group row mx-0">
          <label htmlFor="bankName" className="col-sm-4 col-form-label p-0 ">
            {t("selectScreen.bankNameLabel")}
          </label>
          <div className="col-sm-8 p-0">
            <input
              onChange={(e) => setBankName(e.target.value)}
              type="text"
              className="form-control"
              id="bankName"
              placeholder="Bank"
              maxLength={100}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="form-group row mx-0">
          <label htmlFor="bankName" className="col-sm-4 col-form-label p-0">
            {t("selectScreen.countryLabel")}
          </label>
          <div className="col-sm-8 p-0">
            <select
              className="form-control mr-sm-2"
              id="inlineFormCustomSelect"
              defaultValue={
                countries.filter(
                  (country: Country) =>
                    country?.market == resources?.lender.market
                )[0].slug
              }
              onChange={(e) => setCountry(e.target.value)}
            >
              {countries.map((country: Country) => {
                return (
                  <option key={country.name} value={country.slug}>
                    {country.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
    </AddMissingBankWrapper>
  );
}

export default AddMissingBank;
