import React, { useEffect, useRef, useState } from "react";

import { Switch, Route, useLocation } from "react-router-dom";

import IntroScreen from "./screens/intro";
import NotFound from "./screens/not_found";
import SelectScreen from "./screens/select";
import ConsentScreen from "./screens/consent";
import CompleteScreen from "./screens/complete";
import FinishScreen from "./screens/finish";
import styled, { useTheme } from "styled-components";
import SelectScreenV2 from "./screens/select/select";
import Complete from "./screens/complete/complete";
import useMediaQuery from "./hooks/useMediaQuery";

const Wrapper = styled.div<{ $isMobile: boolean }>`
  display: flex;
  justify-content: center;
  flex-grow: 2;
  align-items: center;
  padding-bottom: ${(props) => (props.$isMobile ? "2rem" : "13rem")};
  padding-top: ${(props) =>
    getPaddingTop(
      props.theme?.settings?.header?.visible,
      props.theme?.settings?.header?.minHeight
    )};
`;

const getPaddingTop = (headerVisible: any, headerMinHeight: any) => {
  const defaultHeaderHeight = "var(--section-height)";

  if (headerVisible) {
    return `calc(2rem + ${headerMinHeight ?? defaultHeaderHeight})`;
  } else {
    return "2rem";
  }
};

const AnimatedScreens: React.FC = () => {
  const screens: { [key: string]: number } = {
    "/": 0,
    "/consent": 1,
    // "/finish": 2,
    "/complete": 2,
  };
  const location = useLocation();
  const [mounted, setMounted] = useState(false);
  const [completeScreenReached, setCompleteScreenReached] =
    useState<boolean>(false);
  const theme: any = useTheme();

  useEffect(() => {
    setMounted(true);
  }, []);

  const prevLocation = useRef(0);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const isMobile = useMediaQuery("(max-width: 480px)");

  prevLocation.current = screens[location.pathname];

  return (
    <Wrapper $isMobile={isMobile}>
      <div
        style={{
          opacity: 1,
          transform: "translate(0px, 0px)",
          padding: `${isDesktop ? "0 2rem" : "0px 1rem"}`,
          zIndex: 1000,
        }}
      >
        <Switch>
          <Route path="/" exact component={IntroScreen} />
          <Route
            path="/select"
            exact
            component={(props: any) =>
              theme.settings?.select?.layout === "v1" ? (
                <SelectScreen
                  {...props}
                  shouldRedirect={
                    completeScreenReached &&
                    location.pathname.indexOf("select") > -1
                  }
                />
              ) : (
                <SelectScreenV2
                  {...props}
                  shouldRedirect={
                    completeScreenReached &&
                    location.pathname.indexOf("select") > -1
                  }
                />
              )
            }
          />
          <Route path="/consent" component={ConsentScreen} />
          <Route
            path="/complete"
            render={(props: any) =>
              theme.settings?.summary?.layout === "v1" ? (
                <CompleteScreen
                  {...props}
                  onInit={() => setCompleteScreenReached(true)}
                />
              ) : (
                <Complete
                  {...props}
                  onInit={() => setCompleteScreenReached(true)}
                />
              )
            }
          />

          <Route
            path="/finish"
            render={(props: any) =>
              props?.history?.location?.state?.status ? (
                <FinishScreen
                  status={props?.history?.location?.state?.status}
                />
              ) : (
                <NotFound />
              )
            }
          />
          <Route component={NotFound} />
        </Switch>
      </div>
    </Wrapper>
  );
};

export default AnimatedScreens;
