import React from "react";

import styled from "styled-components/macro";

const Spinner = styled.div`
  color: #fff;
`;

const Loader: React.FC = () => <Spinner className="spinner-border" />;

export default Loader;
