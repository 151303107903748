import React from "react";

interface Props {}

const Logo: React.FC<Props> = () => (
  <>
    <svg
      className="logo-abbr"
      id="5e591825-2004-4903-91da-a7788f718cec"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 398.96 506.84"
    >
      <path
        className="c469733b-fdc0-491e-9a31-9b205a9a06f5"
        d="M248,119.25H190.54a30.9,30.9,0,0,1-30.91-30.91V30.9A30.9,30.9,0,0,1,190.54,0H248a30.91,30.91,0,0,1,30.9,30.9V88.34A30.91,30.91,0,0,1,248,119.25"
      />
      <path
        className="c1e772a31-79a7-4afe-92db-fb9e7c5e3059"
        d="M99.39,248H34.77A34.75,34.75,0,0,1,0,213.25V148.62a34.78,34.78,0,0,1,34.77-34.77H99.39a34.79,34.79,0,0,1,34.78,34.77v64.63A34.75,34.75,0,0,1,99.39,248"
      />
      <path
        className="b5b9105f-5039-404f-8f6f-e06a6ff630bc"
        d="M93.23,382.13H53.76A21.26,21.26,0,0,1,32.5,360.87V321.43a21.27,21.27,0,0,1,21.26-21.29H93.23a21.26,21.26,0,0,1,21.26,21.29v39.44a21.24,21.24,0,0,1-21.26,21.26"
      />
      <path
        className="ebf821e1-ec93-447f-8eec-6c2002c731a5"
        d="M199.2,506.84H152.53a25.1,25.1,0,0,1-25.11-25.11V435.06a25.1,25.1,0,0,1,25.11-25.12H199.2a25.1,25.1,0,0,1,25.12,25.12v46.66a25.1,25.1,0,0,1-25.12,25.11"
      />
      <path
        className="ebf821e1-ec93-447f-8eec-6c2002c731a5"
        d="M370.21,142.75H337.88a17.39,17.39,0,0,1-17.39-17.38V93.09a17.4,17.4,0,0,1,17.39-17.41h32.33a17.39,17.39,0,0,1,17.37,17.41v32.29a17.38,17.38,0,0,1-17.37,17.38"
      />
      <path
        className="c5ad9972a-8704-416d-b416-4d6608bf7ff8"
        d="M361,273c-.72-47.18-42.85-87.52-92.55-87.52-51.87,0-93.29,41.42-93.29,92.56a92.83,92.83,0,0,0,93.29,93.27,91,91,0,0,0,64.47-26.28,15.33,15.33,0,0,0-10.09-27c-4.67,0-9,2.88-12.24,6.13-10.45,11.16-25.93,16.2-42.14,16.2-31,0-56.17-21.22-61.59-50.74h139c.11,0,.2,0,.3,0h.42c.26,0,.51-.12.78-.16a10.55,10.55,0,0,0,1.58-.31A16.6,16.6,0,0,0,361,273m-92.55-56.91A61.54,61.54,0,0,1,327.51,259H209c7.92-25,31.16-42.9,59.42-42.9"
      />
      <path
        className="c5ad9972a-8704-416d-b416-4d6608bf7ff8"
        d="M363.17,371.42a2,2,0,0,1-2-2V348.72H356.5a2,2,0,0,1-2-2,2,2,0,0,1,2-2H369.9a2,2,0,0,1,2,2,2,2,0,0,1-2,2H365.2v20.74a2,2,0,0,1-2,2"
      />
      <path
        className="c5ad9972a-8704-416d-b416-4d6608bf7ff8"
        d="M387.15,371.45a2.12,2.12,0,0,1-2-1.58l-5.77-17v16.54a2,2,0,0,1-2,2,2,2,0,0,1-2-2V347.58a3.14,3.14,0,0,1,3.06-3.06,3.19,3.19,0,0,1,2.93,2.26l5.85,17.11,5.79-17.12a3.21,3.21,0,0,1,3-2.25,3.14,3.14,0,0,1,3.06,3.06v21.88a2,2,0,0,1-2,2,2,2,0,0,1-2-2V353l-3.58,10.56c-2,6-2.12,6.29-2.15,6.35a2.23,2.23,0,0,1-2,1.58"
      />
    </svg>
  </>
);

export default Logo;
