import React, { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { ResourcesContext } from "../../../context/consumer";
import { TextResourcesContext } from "../../../context/textResourcesContext";
import { useConsumer } from "../../../hooks/useConsumer";
import parse from "html-react-parser";

import {
  getResource,
  getTextWithPopulatePlaceholders,
} from "../../../utils/helpers";

const Warning = styled.p`
  color: var(--exp-red) !important;
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  font-weight: 400;
`;

export const Header = styled.h1<{ color?: string }>`
  font-weight: bold;

  white-space: pre-line;
`;

interface IntroHeaderComponentProps {
  name: string;
}

function IntroHeaderComponent({ name }: IntroHeaderComponentProps) {
  const theme: any = useTheme();
  const { t } = useTranslation();
  const consumerInfo = useConsumer();
  const { resources } = useContext(ResourcesContext);
  const { data } = useContext(TextResourcesContext);

  const greatingText = getResource(data, "CJ_introScreen_pageTitle");

  const getGreetingText = (text: string) => {
    let placeholder = "";

    const placeholders = [
      "{{email}}",
      "{{firstName}}",
      "{{lastName}}",
      "{{middleName}}",
      "{{companyName}}",
      "{{fullName}}",
    ];

    placeholders.forEach((placeholder1) => {
      if (text.includes(placeholder1)) {
        placeholder = placeholder1;
      }
    });

    if (placeholder === "") return text;
    let greetingByParameter = "";
    switch (placeholder) {
      case "{{email}}":
        greetingByParameter = consumerInfo["email"];
        break;
      case "{{firstName}}":
        greetingByParameter = consumerInfo["firstName"];
        break;
      case "{{middleName}}":
        greetingByParameter = consumerInfo["middleName"];
        break;
      case "{{lastName}}":
        greetingByParameter = consumerInfo["lastName"];
        break;
      case "{{fullName}}":
        greetingByParameter += consumerInfo["firstName"] + " ";
        if (consumerInfo["middleName"]) {
          greetingByParameter += consumerInfo["middleName"] + " ";
        }
        greetingByParameter += consumerInfo["lastName"];
        break;
      case "{{companyName}}":
        greetingByParameter = consumerInfo["companyName"];
        break;
      default:
        greetingByParameter = consumerInfo["email"];
        break;
    }

    return parse(text.replace(placeholder, greetingByParameter));
  };
  return (
    <>
      {!resources?.prodEnvironment && (
        <Warning id="introWarningUat">Evaluation Version</Warning>
      )}
      {theme.settings?.intro?.greetingText?.visible && (
        <Header
          id="introHeader"
          className={`with-custom-font page-title
            ${theme.settings?.intro?.layout == "v2" ? "row col-md-12" : ""}
          `}
        >
          {getGreetingText(greatingText)}
        </Header>
      )}
    </>
  );
}

export default IntroHeaderComponent;
