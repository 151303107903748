import { createContext, useState } from "react";

type Context = {
  show: boolean;
  openSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
};

const SnackbarContext = createContext<Context>({
  show: false,
  openSnackbar() {},
});

const SnackbarProvider: React.FC = (props) => {
  const [show, openSnackbar] = useState(false);

  const value = { show, openSnackbar };

  return <SnackbarContext.Provider value={value} {...props} />;
};

export { SnackbarProvider, SnackbarContext };
