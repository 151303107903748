import { AxiosResponse } from "axios";
import { useState, useEffect } from "react";
import api from "../utils/api";
import { useError } from "./useError";
import { useOrchestrationToken } from "./useOrchestrationToken";

const useTextResource = (searchPattern: string) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useError();
  const [data, setData] = useState<null | any>(null);

  const orchestrationToken = useOrchestrationToken();

  const refetch = async () => {
    setLoading(true);
    setError!(null);
    setData("");
    doFetchTextResources();
  };

  const doFetchTextResources = async () => {
    try {
      const response = await fetchTextResources(
        orchestrationToken,
        searchPattern
      );

      setData(response.data);
    } catch (error) {
      setError!(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    doFetchTextResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    error,
    data,
    refetch,
  };
};

const fetchTextResources = (
  orchestrationToken: string,
  searchPattern: string
): Promise<AxiosResponse> =>
  api.get(
    `/api/orchestrations/${orchestrationToken}/resources/?searchPattern=${searchPattern}`
  );

export { useTextResource };
