import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";

const FallbackImageContainer = styled.div`
  height: 42px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  font-size: 16px;
`;

const Image: React.FC<{ alt: string; src: string }> = ({ alt, src }) => {
  const [loadedLogo, setLoadedLogo] = useState<boolean | null>(null);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (loadedLogo !== true) {
        setLoadedLogo(false);
      }
    }, 200);

    return () => clearTimeout(timeout);
  }, [loadedLogo]);

  return (
    <>
      <img
        alt={`${alt} logo`}
        src={src}
        onLoad={() => setLoadedLogo(true)}
        onError={() => setLoadedLogo(false)}
      />
      {loadedLogo === false && (
        <FallbackImageContainer>
          <FontAwesomeIcon icon={faImage} />
        </FallbackImageContainer>
      )}
    </>
  );
};

export { Image };
