import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { SwitchButtonContext } from "../../context/switchButton";

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .checkbox-label {
    cursor: pointer;
    &:hover {
      color: #0f6eb8;
    }
    &.left {
      margin-right: 10px;
      text-align: right;
    }
  }
`;

const SwitchCheckboxWrapper = styled.label<{
  $bgColor: string;
  $sliderBgColor: string;
  $state: boolean;
}>`
  position: relative;
  display: inline-block;
  width: 3.4rem;
  height: 1.4rem;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked {
      + .slider {
        &:before {
          -webkit-transform: translateX(15px);
          -ms-transform: translateX(15px);
          transform: translateX(15px);
        }
      }
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) =>
      props?.$sliderBgColor
        ? props?.$sliderBgColor
        : props?.$state
        ? "#688aba"
        : "lightgray"};
    -webkit-transition: 0.3s;
    transition: 0.3s;

    border-radius: 20px;

    &:before {
      position: absolute;
      border-radius: 50%;
      content: "";
      height: 2rem;
      width: 2rem;
      left: 0px;
      bottom: -3px;
      background-color: ${(props) =>
        props?.$bgColor
          ? props?.$bgColor
          : props?.$state
          ? "var(--exp-dark-blue)"
          : "#f1f1f1"};
      -webkit-transition: 0.3s;
      -webkit-transition: 0.3s;
      transition: 0.3s;
    }
  }
`;

interface Props {
  label: string;
  state: string;
}

const ToggleButton: React.FC<Props> = ({ label, state }) => {
  const { toggle, toggleFunction, setToggle } = useContext(SwitchButtonContext);
  const theme: any = useTheme();
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    toggleFunction();
  };

  useEffect(() => {
    if (state === "on") {
      setToggle(true);
    }
  }, [state]);

  const getBgColor = (state: boolean) => {
    return theme?.settings?.summary?.toggleButton?.[
      state ? "active" : "inActive"
    ]?.bgColor;
  };
  const getSliderBgColor = (state: boolean) => {
    return theme?.settings?.summary?.toggleButton?.[
      state ? "active" : "inActive"
    ]?.sliderBgColor;
  };
  return (
    <SwitchWrapper>
      <span className="checkbox-label left">{label}</span>
      <SwitchCheckboxWrapper
        $state={toggle}
        $bgColor={getBgColor(toggle)}
        $sliderBgColor={getSliderBgColor(toggle)}
      >
        <input type="checkbox" checked={toggle} onChange={handleChange} />
        <span className="slider"></span>
      </SwitchCheckboxWrapper>
    </SwitchWrapper>
  );
};

export default ToggleButton;
