import { AxiosResponse } from "axios";
import { useState, useContext } from "react";
import api from "../utils/api";
import { useError } from "./useError";
import { useOrchestrationToken } from "./useOrchestrationToken";
import { SnackbarContext } from "../context/snackbar";
const useAcceptTerms = (cb: () => void) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useError();
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const { openSnackbar } = useContext(SnackbarContext);
  const orchestrationToken = useOrchestrationToken();

  const refetch = async () => {
    setLoading(true);
    setError!(null);
    setTermsAccepted(false);
    doAcceptTerms();
  };

  const doAcceptTerms = async () => {
    try {
      await acceptTerms(orchestrationToken);
      setTermsAccepted(true);
      cb();
    } catch (error) {
      setError!(error);
      openSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    refetch,
    termsAccepted,
  };
};

const acceptTerms = (orchestrationToken: string): Promise<AxiosResponse<any>> =>
  api.put(`/api/orchestrations/${orchestrationToken}/terms`);

export { useAcceptTerms };
