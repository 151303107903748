import PrivacyPolicyModal from "../../../components/privacyPolicy/modal/PrivacyPolicyModal";

function PrivacyPolicyModalToggle({
  showModal,
  setShowModal,
  setAgreePrivacyPolicy,
}: any) {
  return (
    <>
      {showModal && (
        <PrivacyPolicyModal
          close={() => {
            setShowModal(false);
            setAgreePrivacyPolicy(false);
          }}
        />
      )}
    </>
  );
}

export default PrivacyPolicyModalToggle;
