import React from "react";
// Context has been created
const SwitchButtonContext = React.createContext<SwitchButtonContext>({
  toggle: false,
  toggleFunction() {},
  setToggle() {},
});

type SwitchButtonContext = {
  toggle: boolean;
  toggleFunction: () => void;
  setToggle: (arg: boolean) => void;
};

// Provider
const SwitchButtonProvider: React.FC = (props) => {
  const [toggle, setToggle] = React.useState(false);
  const toggleFunction = () => {
    setToggle(!toggle);
  };
  const value = { toggle, toggleFunction, setToggle };
  return <SwitchButtonContext.Provider value={value} {...props} />;
};
export { SwitchButtonContext, SwitchButtonProvider };
