import { useState, useCallback } from "react";

import api from "../utils/api";
import { AxiosResponse } from "axios";
import { useError } from "./useError";

type AcceptResponse = {};

const useExitJourney = (orchestrationToken: string) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useError();

  const clearError = useCallback(() => setError!(null), [setError]);
  const doExitJourney = useCallback(
    async (cb: () => void, systemMessage, instanceState) => {
      try {
        setLoading(true);
        clearError();

        await exitJourney(orchestrationToken, {
          systemMessage,
          instanceState,
        });
        cb();
      } catch (error) {
        setError!(error);
      } finally {
        setLoading(true);
      }
    },
    [orchestrationToken, setError, clearError]
  );

  const refetch = (
    cb: () => void,
    systemMessage: string,
    instanceState: string
  ) => {
    setLoading(true);
    setError!(null);
    doExitJourney(cb, systemMessage, instanceState);
  };

  return {
    loading,
    error,
    clearError,
    execute: doExitJourney,
    refetch,
  };
};

const exitJourney = (
  orchestrationToken: string,
  exitModel: object
): Promise<AxiosResponse<AcceptResponse>> =>
  api.put(`/api/orchestrations/${orchestrationToken}/exitJourney`, exitModel);

export { useExitJourney, exitJourney };
