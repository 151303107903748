import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled, { css, useTheme } from "styled-components";
import { ConsentedBanksContext } from "../../context/consentedBanks";
import { ResourcesContext } from "../../context/consumer";
import { SelectedBankContext } from "../../context/selectedBank";
import { useAddConsent } from "../../hooks/useAddConsent";
import { useBanksList, Bank } from "../../hooks/useBanksList";
import { useOrchestrationToken } from "../../hooks/useOrchestrationToken";
import { useTopBanks } from "../../hooks/useTopBanks";
import { Content } from "../../screens/layout/screens.layout";
import { getTextWithPopulatePlaceholders } from "../../utils/helpers";
import { scrollTop } from "../../utils/scrollTop";
import { Image } from "../image/image.component";
import Loader from "../loading/loading-small.component";

const BanksContentWrapper = styled(Content)`
  display: grid;
  margin-bottom: 1rem;
  width: 100%;
  justify-content: center;
  gap: 1rem;
  ${(props) =>
    props.theme.settings.select.layout === "v1" &&
    css`
      grid-template-columns: repeat(6, minmax(100px, 1fr));

      @media (max-width: 768px) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media (max-width: 500px) {
        grid-template-columns: repeat(3, 1fr);
      }
    `}

  ${(props) =>
    props.theme.settings.select.layout === "v2" &&
    css`
      grid-template-columns: repeat(3, minmax(100px, 1fr));

      @media (max-width: 478px) {
        grid-template-columns: repeat(3, minmax(70px, 1fr));
      }
    `}
`;

const BankContainer = styled.div<{ disabled: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #d7d7d7;
  padding: 0.2rem;
  cursor: pointer;
  text-align: center;
  & > img {
    height: 62px;
  }
  ${({ disabled }) => disabled && "opacity: .5; "}

  .tick {
    position: absolute;
    top: -8px;
    right: -8px;
    background: #fff;
    width: 20px;
    height: 20px;
    .tickIcon {
      width: 100%;
      display: block;
      height: 20px;
      background-size: 70%;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
`;
const TopBanksLoader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  & .spinner-border {
    color: #c3c3c3;
    width: 3rem !important;
    height: 3rem !important;
  }
`;
interface TopBanksProps {
  providers: Bank[];
}
function TopBanks({ providers }: TopBanksProps) {
  const { topBanks, loading } = useTopBanks(true);
  const { t } = useTranslation();
  const history = useHistory<any>();
  const orchestrationToken = useOrchestrationToken();
  const { setBank } = useContext(SelectedBankContext);
  const { resources } = useContext(ResourcesContext);
  const { consentedBanks } = useContext(ConsentedBanksContext);
  const { loading: loadingLockConsent, execute } =
    useAddConsent(orchestrationToken);
  const theme: any = useTheme();

  const completedBanksProviderCodes = consentedBanks.map((bank) => {
    if (bank.status === "Completed") {
      return bank.providerCode;
    }
  });

  const failedBanksProviderCodes = consentedBanks.map((bank) => {
    if (bank.status === "Error") {
      return bank.providerCode;
    }
  });
  const getProviderByTopBankProviderCode = (bank: Bank) => {
    return providers?.filter(
      (provider: { providerCode: string }) =>
        provider?.providerCode == bank?.providerCode
    )?.[0];
  };
  return (
    <>
      {topBanks?.length > 0 ? (
        <>
          <span style={theme.settings?.select?.topBanks?.sectionTitle?.styles}>
            {theme.settings?.select?.topBanks?.sectionTitle
              ? getTextWithPopulatePlaceholders(
                  theme.settings.select?.topBanks?.sectionTitle,
                  resources
                )
              : t("selectScreen.topBanksSectionTitle")}
          </span>
          <BanksContentWrapper className="mt-0 flex-row">
            {topBanks?.map((bank, index) => {
              return (
                <BankContainer
                  disabled={loadingLockConsent}
                  style={
                    completedBanksProviderCodes.includes(bank.providerCode)
                      ? theme.settings?.select?.topBanks?.styles?.success
                      : failedBanksProviderCodes.includes(bank.providerCode)
                      ? theme.settings?.select?.topBanks?.styles?.fail
                      : theme.settings?.select?.topBanks?.styles?.default
                  }
                  onClick={() => {
                    getProviderByTopBankProviderCode(bank) &&
                      setBank(getProviderByTopBankProviderCode(bank));
                    execute(
                      bank!.providerCode,
                      null,
                      null,
                      (consentToken: string) => {
                        scrollTop(() =>
                          history.push(
                            `/consent?orchestrationToken=${orchestrationToken}`,
                            {
                              consentToken,
                            }
                          )
                        );
                      }
                    );
                  }}
                >
                  <Image alt={bank?.name} src={bank?.logo} />
                  <span>{bank?.name}</span>
                  {completedBanksProviderCodes.includes(bank.providerCode) &&
                  theme.settings.select?.topBanks?.styles?.tick?.visible ? (
                    <span
                      className="tick"
                      style={theme.settings.select?.topBanks?.styles?.tick}
                    >
                      <span
                        className="tickIcon"
                        style={{
                          backgroundImage:
                            theme.settings.select?.topBanks?.styles?.tickIcon,
                        }}
                      ></span>
                    </span>
                  ) : null}
                  {failedBanksProviderCodes.includes(bank.providerCode) &&
                  theme.settings.select?.topBanks?.styles?.tick?.visible ? (
                    <span
                      className="tick"
                      style={
                        theme.settings.select?.topBanks?.styles?.tickFailedBank
                      }
                    >
                      <span
                        className="tickIcon"
                        style={{
                          backgroundImage:
                            theme.settings.select?.topBanks?.styles
                              ?.tickIconFailedBank,
                        }}
                      ></span>
                    </span>
                  ) : null}
                </BankContainer>
              );
            })}
            {loadingLockConsent && (
              <TopBanksLoader
                style={{
                  position: "absolute",
                }}
              >
                <Loader />
              </TopBanksLoader>
            )}
          </BanksContentWrapper>
        </>
      ) : null}
      {loading && (
        <TopBanksLoader>
          <Loader />
        </TopBanksLoader>
      )}
    </>
  );
}

export default TopBanks;
