import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import styled, { useTheme } from "styled-components/macro";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { TextResourcesContext } from "../../context/textResourcesContext";
import { getResource } from "../../utils/helpers";

const Container = styled.div`
  display: ${(props) =>
    props.theme.colors?.progressBar?.visible === true ? "flex" : "none"};
  justify-content: center;
  flex-direction: column;
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
  margin-top: 2rem;
  z-index: 1000;
  position: relative;
`;

const ItemsContainer = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  font-size: 12px;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  width: 127%;
  margin-left: -14%;
  @media (max-width: 360px) {
    width: 123%;
    margin-left: -12%;
  }

  @media (min-width: 360px) and (max-width: 426px) {
    width: 120%;
    margin-left: -10%;
  }
`;

const Item = styled.li<{
  last?: boolean;
  content: string;
  isPassStep?: boolean;
  isCurrent?: boolean;
  lineColor?: string;
  backgroundColor?: string;
  textColor?: string;
}>`
  list-style-type: none;
  flex: 1;
  float: left;
  position: relative;
  font-weight: 400;
  text-align: center;

  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  align-items: center;
  .chevron {
    display: ${({ isCurrent }) => (isCurrent ? "block" : "none")};
    position: absolute;
    top: -2rem;
    font-size: 1.7rem;
    color: ${(props) => props.theme.colors?.progressBar?.chevron};
  }

  &:before {
    ${({ content }) =>
      content !== "" ? `content: url(${content})` : `content: ""`};

    width: 24px;
    height: 24px;
    line-height: 23px;
    display: block;
    font-size: 14px;
    color: #ffffff;
    background: ${({ backgroundColor }) => backgroundColor};
    border-radius: 50%;
    margin: 0 auto 6px auto;
    padding: 2px;
  }
  ${({ last, lineColor }) => {
    return (
      !last &&
      `
    &:after {
    content: "";
    width: 100%;
    height: 2px;
    background:  ${lineColor ?? "#d9d9d9"};
    position: absolute;
    left: 50%;
    top: 12px;
    z-index: -1;
  }
    `
    );
  }}
`;
interface Props {
  isManage?: boolean;
  onLastStep?: boolean;
}
const Progressbar: React.FC<Props> = ({ isManage, onLastStep }) => {
  const { pathname } = useLocation();
  const { data } = useContext(TextResourcesContext);

  const theme: any = useTheme();
  const screens: { [key: string]: number } = {
    "/": 0,
    "/select": 1,
    "/consent": 2,
    "/complete": 3,
  };
  const checkIcon =
    "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDE0IDEwIiBmaWxsPSJub25lIj4NCjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMC43Mjc0NCA1Ljg5MTc3QzAuMzMwMzg0IDUuNTA5OTggMC4zMzAzODQgNC44NzQ2MyAwLjcyNzQ0IDQuNDkyODVDMS4xMDMwNyA0LjEzMTY2IDEuNjk2OTMgNC4xMzE2NiAyLjA3MjU2IDQuNDkyODVMNSA3LjMwNzY5TDExLjkyNzQgMC42NDY2OTFDMTIuMzAzMSAwLjI4NTUwNCAxMi44OTY5IDAuMjg1NTA1IDEzLjI3MjYgMC42NDY2OTJDMTMuNjY5NiAxLjAyODQ4IDEzLjY2OTYgMS42NjM4MyAxMy4yNzI2IDIuMDQ1NjJMNS42OTMxMSA5LjMzMzU1QzUuMzA2IDkuNzA1NzcgNC42OTQgOS43MDU3NyA0LjMwNjg5IDkuMzMzNTVMMC43Mjc0NCA1Ljg5MTc3WiIgZmlsbD0id2hpdGUiLz4NCjwvc3ZnPg==";

  const isPassStep = (currentPath: number, checkPath: number) => {
    if (currentPath > checkPath) {
      return true;
    }
    return false;
  };

  const isCurrent = (currentPath: number, checkPath: number) => {
    if (currentPath == checkPath) {
      return true;
    }
    return false;
  };

  const textStyle = (currentPath: number, checkPath: number) => {
    if (
      isCurrent(currentPath, checkPath) ||
      isPassStep(currentPath, checkPath)
    ) {
      return theme.colors?.progressBar?.text?.styles?.current;
    }
    return theme.colors?.progressBar?.text?.styles?.default;
  };

  const backgroundColor = (currentPath: number, checkPath: number) => {
    if (isCurrent(currentPath, checkPath)) {
      return theme.colors?.progressBar?.activeColor;
    }
    if (isPassStep(currentPath, checkPath)) {
      return theme.colors?.progressBar?.successColor;
    }
    return theme.colors?.progressBar?.inactiveColor;
  };

  const lineColor = (currentPath: number, checkPath: number) => {
    if (isPassStep(currentPath, checkPath)) {
      return theme.colors?.progressBar?.line?.passed;
    }
    return theme.colors.progressBar?.line?.default;
  };
  return (
    <Container>
      <ItemsContainer>
        <Item
          style={textStyle(screens[pathname], screens["/"])}
          content={screens[pathname] > screens["/"] ? `${checkIcon}` : ""}
          backgroundColor={backgroundColor(screens[pathname], screens["/"])}
          isCurrent={isCurrent(screens[pathname], screens["/"])}
          lineColor={lineColor(screens[pathname], screens["/"])}
          id="slugStart"
        >
          {getResource(data, "CJ_progressBar_start")}
          <FontAwesomeIcon icon={faChevronDown} className="chevron" />
        </Item>
        <Item
          content={
            isManage || screens[pathname] > screens["/select"]
              ? `${checkIcon}`
              : ""
          }
          style={textStyle(screens[pathname], screens["/select"])}
          backgroundColor={backgroundColor(
            screens[pathname],
            screens["/select"]
          )}
          isCurrent={isCurrent(screens[pathname], screens["/select"])}
          lineColor={lineColor(screens[pathname], screens["/select"])}
          id="slugConnect"
        >
          {getResource(data, "CJ_progressBar_connect")}
          <FontAwesomeIcon icon={faChevronDown} className="chevron" />
        </Item>
        <Item
          content={
            screens[pathname] > screens["/consent"] ? `${checkIcon}` : ""
          }
          style={textStyle(screens[pathname], screens["/consent"])}
          backgroundColor={backgroundColor(
            screens[pathname],
            screens["/consent"]
          )}
          isCurrent={isCurrent(screens[pathname], screens["/consent"])}
          lineColor={lineColor(screens[pathname], screens["/consent"])}
          id="slugConsent"
        >
          {getResource(data, "CJ_progressBar_manage")}
          <FontAwesomeIcon icon={faChevronDown} className="chevron" />
        </Item>

        <Item
          last
          content={onLastStep ? `${checkIcon}` : ""}
          style={textStyle(screens[pathname], screens["/complete"])}
          backgroundColor={backgroundColor(
            screens[pathname],
            screens["/complete"]
          )}
          isCurrent={isCurrent(screens[pathname], screens["/complete"])}
          lineColor={lineColor(screens[pathname], screens["/complete"])}
          id="slugFinish"
        >
          {getResource(data, "CJ_progressBar_finish")}
          <FontAwesomeIcon icon={faChevronDown} className="chevron" />
        </Item>
      </ItemsContainer>
    </Container>
  );
};

export default Progressbar;
