import TermsAndConditionsModal from "../../../components/terms/modal/TermsAndConditionsModal";

function TermsAndConditionsModalToggle({
  showModal,
  setShowModal,
  setAgreeTerms,
}: any) {
  return (
    <>
      {showModal && (
        <TermsAndConditionsModal
          acceptTermsAndConditions={() => {
            setShowModal(false);
            setAgreeTerms(true);
          }}
          closeTermsAndConditions={() => {
            setShowModal(false);
            setAgreeTerms(false);
          }}
        />
      )}
    </>
  );
}

export default TermsAndConditionsModalToggle;
