import { AxiosResponse } from "axios";
import { useState, useEffect, useContext, useCallback } from "react";
// import { OrchestratorContext } from "../context/orchestrator";
import { ConsentedBanksContext } from "../context/consentedBanks";
import api from "../utils/api";
import { useError } from "./useError";
import { useOrchestrationToken } from "./useOrchestrationToken";

export type ConsentStatus = "Accepted" | "Error" | "Completed";

export type Consent = {
  id: string;
  name: string;
  logo: string;
  status: ConsentStatus;
  providerCode: string;
};

const useSummaryData = (onInit = true) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useError();

  const [data, setData] = useState<any>(null);

  const { setConsentedBanks } = useContext(ConsentedBanksContext);

  const orchestrationToken = useOrchestrationToken();

  const refetch = async () => {
    setLoading(true);
    setError!(null);
    fetchData();
    setData(null);
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetchConsentedBanksList(orchestrationToken);
      setData(response.data);
      setConsentedBanks(
        response.data.consents.filter((bank) =>
          ["completed", "error"].includes(bank.status.toLocaleLowerCase())
        )
      );
    } catch (error) {
      setError!(error);
    } finally {
      setLoading(false);
    }
  }, [setConsentedBanks, orchestrationToken, setError]);

  useEffect(() => {
    if (onInit) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  return {
    loading,
    error,
    data,
    refetch,
  };
};

const fetchConsentedBanksList = (
  orchestrationToken: string
): Promise<AxiosResponse<{ consents: Consent[] }>> =>
  api.get(`/api/summary-data`);

export { useSummaryData, fetchConsentedBanksList };
