import { LabelContainer } from "../select/select.bank";
import styled, { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { Account } from "./interfaces";
import { format } from "date-fns";
import { SwitchButtonContext } from "../../context/switchButton";
import { useContext, useEffect } from "react";
import { TextResourcesContext } from "../../context/textResourcesContext";
import { getResource } from "../../utils/helpers";

const AccountRow = styled.div`
  display:flex;
  justify-content: space-between;
  align-items; center;
  margin-bottom:0.5rem;
  .account-label {
    color: ${(props) => props.theme.colors.primaryAccentText};
    font-weight: 500;
  }
  span{
    flex: 1;
  }
`;

interface AccountSummaryProps {
  accountSummary: Account;
}

const AccountSummary = ({ accountSummary }: AccountSummaryProps) => {
  const { t } = useTranslation();
  const { toggle, setToggle } = useContext(SwitchButtonContext);
  const theme: any = useTheme();
  const { data } = useContext(TextResourcesContext);
  useEffect(() => {
    if (!theme.settings.summary?.toggleButton) {
      setToggle(true);
    }
  }, [theme.settings.summary?.toggleButton]);

  const defaultFields = [
    { name: "description" },
    { name: "accountNumberEnding" },
  ];
  const summaryData =
    theme.settings.summary?.texts?.summaryData ?? defaultFields;
  const filteredSummaryData =
    accountSummary.lastTransaction === null
      ? summaryData.filter(function (item: { name: string }) {
          return ![
            "lastTransactionDate",
            "lastTransactionDescription",
            "lastTransactionAmount",
          ].includes(item?.name);
        })
      : summaryData;

  const summaryObject: {
    [key: string]: {
      fieldLabel: string;
      value: string | number | null;
    };
  } = {
    balance: {
      fieldLabel: t("summaryScreen.balance"),
      value: accountSummary?.balance?.toString()
        ? toggle
          ? accountSummary?.balance?.toString()
          : "********"
        : null,
    },
    transactionCount: {
      fieldLabel: t("summaryScreen.transactionsCount"),
      value: accountSummary?.transactionCount?.toString(),
    },

    accountNumberEnding: {
      fieldLabel: t("summaryScreen.accountNumber"),
      value: "**" + accountSummary?.accountNumberEnding,
    },
    description: {
      fieldLabel: t("summaryScreen.accountType"),
      value: accountSummary?.description,
    },
    lastTransactionDate: {
      fieldLabel: t("summaryScreen.lastTransaction.date"),
      value: !accountSummary?.lastTransaction?.date
        ? null
        : format(
            new Date(accountSummary?.lastTransaction?.date),
            "dd/MM/yyyy HH:mm:ss"
          ),
    },
    lastTransactionDescription: {
      fieldLabel: t("summaryScreen.lastTransaction.description"),
      value: accountSummary.lastTransaction?.description,
    },
    lastTransactionAmount: {
      fieldLabel: t("summaryScreen.lastTransaction.amount"),
      value: accountSummary?.lastTransaction?.amount
        ? toggle
          ? accountSummary?.lastTransaction?.amount
          : "********"
        : null,
    },
    currencyCode: {
      fieldLabel: t("summaryScreen.currencyCode"),
      value: accountSummary?.currencyCode,
    },
    iban: { fieldLabel: t("summaryScreen.iban"), value: accountSummary?.iban },
  };

  const getSummaryData = filteredSummaryData?.map((item: { name: string }) => {
    const { fieldLabel, value } = summaryObject[item.name];
    return (
      <>
        {value ? (
          <AccountRow key={item.name}>
            <span className="account-label">{fieldLabel}</span>
            <span className="text-right">{value}</span>
          </AccountRow>
        ) : null}
      </>
    );
  });

  return (
    <div>
      <LabelContainer>
        <span style={{ fontStyle: "italic" }}>
          {getResource(data, "CJ_summaryScreen_bankAccountDataHeader")}
        </span>
      </LabelContainer>
      {getSummaryData}
    </div>
  );
};
export default AccountSummary;
