import styled from "styled-components";
import AccountSummaries from "../accountSummaries/AccountSummaries";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Bank } from "./interfaces";
const BankSummaryContainer = styled.div`
    border-bottom: 2px solid var(--exp-black);
    margin-bottom: 2rem;
    margin-top: 2rem;
    padding-bottom: 2rem;
    &:last-child {
      border: none;
      padding-bottom: 0;
    }
    .image-header-container {
      display: flex;
      gap: 1rem;
      align-items: center;
    }
}
`;

const BankSummaryHeader = styled.div`
    display:flex;
    flex-direction: column;
    align-items; center;
    // background: ${(props) => props.theme.colors.secondaryAccentBackground};
    color: ${(props) => props.theme.colors.primaryAccentText};
    border-radius: 8px;
    font-weight: 500;

    span {
      flex: ${(props) =>
        props.theme.settings.summary.layout === "v2" ? "1" : ""};
    }
`;
const BankSummarySubHeader = styled.div`
  font-style: italic;
  color: ${(props) => props.theme.colors.text};
  padding: 5px 8px;
  font-size: 1.6rem;
  span {
    color: ${(props) => props.theme.colors.text};
  }
`;

const TotalBalance = styled.div`
  display:flex;
    justify-content: space-between;
    align-items; center;
    font-weight: 500;
`;

interface BankSummaryProps {
  bankSummary: Bank;
}

const BankSummary = ({ bankSummary }: BankSummaryProps) => {
  const { t } = useTranslation();
  return (
    <BankSummaryContainer>
      <div className="image-header-container">
        {bankSummary?.logo && (
          <img src={bankSummary?.logo} alt="" width={40} height={40} />
        )}

        <BankSummaryHeader>
          <span>{bankSummary?.bankName}</span>
          <span style={{ fontSize: "1.2rem" }}>
            {t("summaryScreen.accountsAccessed", {
              count: bankSummary?.accountSummaries?.length,
            })}
          </span>
        </BankSummaryHeader>
      </div>

      {/* <BankSummarySubHeader>
        {t("summaryScreen.consentProvidedOn")}:{" "}
        <span>
          {format(
            new Date(bankSummary.consentProvidedOn),
            "dd/MM/yyyy H:mm:ss"
          )}
        </span>
      </BankSummarySubHeader> */}

      <AccountSummaries accountSummaries={bankSummary.accountSummaries} />
      <hr />
      {/* <TotalBalance>
        <span
          style={{
            alignSelf:
              Object.keys(bankSummary.totalBalancePerCurrency).length > 1
                ? "center"
                : "auto",
          }}
        >
          {bankSummary.bankName} {t("summaryScreen.balance")}
        </span>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {Object.entries(bankSummary.totalBalancePerCurrency).map(
            (balance: any, index) => {
              console.log({ balance });
              return <span>{balance[1] + " " + balance[0]}</span>;
            }
          )}
        </div>
      </TotalBalance> */}
    </BankSummaryContainer>
  );
};

export default BankSummary;
