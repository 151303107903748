import { AxiosResponse } from "axios";
import { useState, useEffect, useContext, useCallback } from "react";
import { ConsentedBanksContext } from "../context/consentedBanks";
import api from "../utils/api";
import { useError } from "./useError";
import { useOrchestrationToken } from "./useOrchestrationToken";

export type ConsentStatus = "Accepted" | "Error" | "Completed" | "Processing";

export type Consent = {
  id: string;
  name: string;
  logo: string;
  status: ConsentStatus;
  providerCode: string;
  errorCode?: number;
  errorMessage?: string;
  jointAccountIndicator?: boolean;
  primaryBankIndicator?: boolean;
};

const useConsentedBanksList = (onInit = true) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useError();

  const [consents, setConsents] = useState<Consent[]>([]);

  const { setConsentedBanks } = useContext(ConsentedBanksContext);

  const orchestrationToken = useOrchestrationToken();

  const refetch = async () => {
    setLoading(true);
    setError!(null);
    fetchBanks();
    setConsents([]);
  };

  const fetchBanks = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetchConsentedBanksList(orchestrationToken);

      const consentsByBankStatus = response.data.consents.filter((bank) => {
        return ["completed", "error", "processing"].includes(
          bank.status.toLocaleLowerCase()
        );
      });
      setConsents(consentsByBankStatus);
      setConsentedBanks(consentsByBankStatus);
    } catch (error) {
      setError!(error);
    } finally {
      setLoading(false);
    }
  }, [setConsentedBanks, orchestrationToken, setError]);

  useEffect(() => {
    if (onInit) {
      fetchBanks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchBanks]);

  return {
    loading,
    error,
    consents,
    refetch,
  };
};

const fetchConsentedBanksList = (
  orchestrationToken: string
): Promise<AxiosResponse<{ consents: Consent[] }>> =>
  api.get(`/api/orchestrations/${orchestrationToken}/consents`);

export { useConsentedBanksList, fetchConsentedBanksList };
