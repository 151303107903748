import React, { useState } from "react";
import styled, { useTheme } from "styled-components/macro";
import { useLender } from "../../hooks/useLender";
import useMediaQuery from "../../hooks/useMediaQuery";
import { getFooterPosition } from "./helper";

const StyledFooter = styled.footer<{ $isMobile: boolean }>`
  height: auto;
  background: ${(props) => props.theme?.settings?.footer?.background};
  position: ${(props) => (props.$isMobile ? "relative" : "fixed")};
  bottom: 0;
  z-index: 1001;
  overflow: hidden;
  min-height: ${(props) =>
    props.theme?.settings?.footer?.minHeight ?? "var(--section-height)"};
  align-items: center;
  display: flex;
`;

const LogoContainer = styled.div<{ $position: string }>`
  width: 100%;
  max-width: 90%;
  height: 100%;
  display: flex;
  line-height: 17px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => props.$position};
  margin: 0 auto;
  div {
    color: ${(props) => props.theme.settings.footer.text};
  }
  & > img {
    height: 36px;
    margin-left: 0;
  }
`;

interface Props {}

const Footer: React.FC<Props> = () => {
  const { logoUrl, name } = useLender();
  const theme: any = useTheme();
  const [hasError, setHasError] = useState(false);
  const isMobile = useMediaQuery("(max-width: 480px)");
  return (
    <StyledFooter $isMobile={isMobile}>
      <LogoContainer $position={getFooterPosition(theme)}>
        <div>
          <div>{theme.settings.footer?.line1}</div>
          <div>{theme.settings.footer?.line2}</div>
          <div>{theme.settings.footer?.line3}</div>
          <div>{theme.settings.footer?.line4}</div>
        </div>
      </LogoContainer>
    </StyledFooter>
  );
};

export default Footer;
