import { AxiosError } from "axios";
import React, { createContext } from "react";
import { useTextResource } from "../hooks/useTextResource";
const TextResourcesContext = createContext<{
  data: any;
  error: AxiosError | null;
}>({ data: null, error: null });

const TextResourcesProvider: React.FC = (props: unknown) => {
  const { data, error } = useTextResource("CJ");

  const value = { data, error };

  return <TextResourcesContext.Provider value={value} {...props} />;
};

export { TextResourcesProvider, TextResourcesContext };
