import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import styled from "styled-components/macro";
import { snackBarInOut } from "../../styles/animations/animations";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  position: fixed;
  z-index: 1002;
  bottom: 10px;
  left: 50%;
  width: 355px;
  transform: translate(-50%, 0);
  animation: ${snackBarInOut};
  color: #fff;
  display: flex;
  padding: 6px 16px;
  flex-grow: 1;
  flex-wrap: wrap;
  font-size: 1rem;
  align-items: center;
  font-weight: 400;
  line-height: 1.43;
  border-radius: 4px;
  letter-spacing: 0.01071em;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  justify-content: center;
  @media (max-width: 480px) {
    max-width: 272px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  opacity: 0.9;
  padding: 7px 0;
  font-size: 16px;
  margin-right: 12px;
`;

const Message = styled.div`
  padding: 8px 0;
  font-size: 16px;
`;

interface Props {
  openSnackbar: Dispatch<SetStateAction<boolean>>;
}

const Snackbar: React.FC<Props> = ({ openSnackbar }) => {
  const { t } = useTranslation();
  let timeout = useRef<number>();
  useEffect(() => {
    timeout.current = window.setTimeout(() => {
      openSnackbar(false);
    }, 2600);
    return () => {
      clearTimeout(timeout.current);
    };
  }, [openSnackbar]);
  return (
    <Container id="snackbar" className="bg-danger" role="alert">
      <IconContainer>
        <FontAwesomeIcon icon={faExclamationCircle} />
      </IconContainer>
      <Message>{t("common.somethingWentWrong")}</Message>
    </Container>
  );
};

export default Snackbar;
