import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { OrchestratorProvider } from "./context/orchestrator";
import { SelectedBankProvider } from "./context/selectedBank";
import { ConsentedBanksProvider } from "./context/consentedBanks";
import { SnackbarProvider } from "./context/snackbar";
import { TimeoutProvider } from "./context/timeout";
import { ResourcesProvider } from "./context/consumer";
import { TextResourcesProvider } from "./context/textResourcesContext";
import Loader from "./components/loading/loading.component";
import { SwitchButtonProvider } from "./context/switchButton";

const Providers: React.FC = ({ children }) => (
  <Suspense fallback={<Loader />}>
    <Router basename={process.env.PUBLIC_URL}>
      <TimeoutProvider>
        <ResourcesProvider>
          <TextResourcesProvider>
            <OrchestratorProvider>
              <ConsentedBanksProvider>
                <SelectedBankProvider>
                  <SwitchButtonProvider>
                    <SnackbarProvider>{children}</SnackbarProvider>
                  </SwitchButtonProvider>
                </SelectedBankProvider>
              </ConsentedBanksProvider>
            </OrchestratorProvider>
          </TextResourcesProvider>
        </ResourcesProvider>
      </TimeoutProvider>
    </Router>
  </Suspense>
);

export { Providers };
