import React, { useEffect, useRef, useContext, useState } from "react";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { useTerms } from "../../hooks/useTerms";
import Loader from "../loading/loading-tc.component";
import TryAgain from "../tryagain/tryagain.component";

import { ResourcesContext } from "../../context/consumer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { TextResourcesContext } from "../../context/textResourcesContext";
import { getResource } from "../../utils/helpers";
import useMediaQuery from "../../hooks/useMediaQuery";

const TermsHeader = styled.span`
  text-decoration: underline;
  font-size: 1.6rem;
  color: ${(props) => props.theme.colors.text};
`;

const TermsContainer = styled.div`
  padding: 8px 0 0 8px;
`;

const TermsBody = styled.div`
  height: 300px;
  overflow-y: scroll;
  position: relative;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:vertical {
    width: 8px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(183, 183, 183, 0.5);
  }
  ::-webkit-scrollbar-track {
    background-color: rgb(239, 239, 239);
  }
`;

const TermsAction = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;

  & .spinner-border {
    width: 3rem !important;
    height: 3rem !important;
  }
`;

export const ReadMore = styled.div`
  display: flex;
  position: sticky;
  bottom: 10px;
  right: 33px;
  justify-content: center;
  margin-right: 10px;

  span {
    color: rgb(255, 255, 255);
    font-size: 14px;
    background-color: #cdcdcd;
    padding: 5px 10px;
    border-radius: 20px;

    svg {
      margin-left: 4px;
      position: relative;
      top: 1px;
    }
  }
`;

const CONTAINER_HEIGHT = 300;

const TermsAndConditions: React.FC<any> = ({ loadingTerms }) => {
  const { resources } = useContext(ResourcesContext);
  const {
    terms: { terms },
    loading,
    error,
    refetch,
  } = useTerms(resources?.termsAccepted);
  const { data } = useContext(TextResourcesContext);
  const [showScrollReadMore, setShowScrollReadMore] = useState(true);
  const termsTextRef = useRef<HTMLParagraphElement>(null);
  const isNotDesktop = useMediaQuery("(max-width: 1024px)");

  useEffect(() => {
    // if (terms && termsTextRef.current!.clientHeight < CONTAINER_HEIGHT) {
    //   setShowScrollReadMore(false);
    // }
  }, [terms]);

  useEffect(() => {
    if (!loading && loadingTerms) {
      loadingTerms();
    }
  }, [loading]);

  const { t } = useTranslation();

  const handleScroll = (e: any) => {
    const container = e.target;
    if (
      container.scrollHeight - container.scrollTop - container.clientHeight <
      2
    ) {
      setShowScrollReadMore(false);
    }
  };
  return (
    <div style={{ maxWidth: "768px", margin: "0 auto" }}>
      <TermsHeader id="introScreenTermsHeader">
        {getResource(data, "CJ_introScreen_TnCPopupTitle")}
      </TermsHeader>
      {loading && (
        <TermsAction>
          <Loader />
        </TermsAction>
      )}
      {!loading ? (
        <TermsContainer>
          <TermsBody
            onScroll={handleScroll}
            style={{
              height: isNotDesktop
                ? "calc(100vh - 35rem)"
                : "calc(100vh - 27rem)",
            }}
            tabIndex={0}
            id="termsContainer"
          >
            {error && (
              <TermsAction>
                <TryAgain refetch={() => refetch()} />
              </TermsAction>
            )}
            <div
              style={{
                wordBreak: "break-word",
                paddingRight: "10px",
              }}
              id="introTermsText"
              ref={termsTextRef}
            >
              {parse(terms)}
            </div>

            {terms && showScrollReadMore && (
              <ReadMore>
                <span>
                  {getResource(data, "CJ_introScreen_scrollDownToReadMore")}{" "}
                  <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
                </span>
              </ReadMore>
            )}
          </TermsBody>
        </TermsContainer>
      ) : (
        <TermsContainer>
          <div
            style={{
              height: isNotDesktop
                ? "calc(100vh - 35rem)"
                : "calc(100vh - 27rem)",
            }}
          ></div>
        </TermsContainer>
      )}
    </div>
  );
};

export default TermsAndConditions;
