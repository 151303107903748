import { useEffect } from "react";
import { ModalWrapper } from "../../screens/select/select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useNeedHelpData } from "../../hooks/useNeedHelpData";
import JsxParser from "react-jsx-parser";
import { Trans, useTranslation } from "react-i18next";
interface Props {
  onClose: () => void;
}

function NeedHelpPopup({ onClose }: Props) {
  const { template } = useNeedHelpData();
  console.log({ template });
  const { t } = useTranslation();

  useEffect(() => {
    let handler: any;
    if (template !== "") {
      setTimeout(() => {
        const handler = document.getElementById("closeButton");
        handler?.addEventListener("click", () => {
          onClose();
          document.body.style.overflow = "auto";
        });
      }, 1000);
    }

    return () => {
      handler?.removeEventListener("click", () => {
        onClose();
        document.body.style.overflow = "auto";
      });
    };
  }, [template]);
  return (
    <ModalWrapper className="visible">
      <div
        className="modal-body overflow-hidden"
        style={{ minHeight: "50%", width: "97%" }}
      >
        <div className="modal-header">
          <span
            className="close-btn ml-auto"
            onClick={() => {
              onClose();
              document.body.style.overflow = "auto";
            }}
          >
            <FontAwesomeIcon
              icon={faTimesCircle}
              size="lg"
              color="var(--exp-dark-grey) !important"
            />
          </span>
        </div>
        <div className="mt-3">
          <JsxParser
            allowUnknownElements={true}
            showWarnings
            components={{ Trans }}
            jsx={template}
          />
        </div>
        <div className="btns-wrapper"></div>
      </div>
    </ModalWrapper>
  );
}

export default NeedHelpPopup;
