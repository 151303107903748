export const getItemsPosition = (theme: any) => {
  const assuranceItemsPosition = theme?.settings?.assuranceSection?.position;
  let position = "";
  switch (assuranceItemsPosition) {
    case "center":
      position = "center";
      break;
    case "top":
      position = "flex-start";
      break;
    case "bottom":
      position = "flex-end";
      break;
    default:
      position = "center";
      break;
  }
  return position;
};
