import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme, css } from "styled-components";
import { LoadableButton } from "../../../components/button/loadable-button.component";
import { ResourcesContext } from "../../../context/consumer";
import { useExitJourney } from "../../../hooks/useExitJourney";
import { ReactComponent as ExitModalIcon } from "../../../assets/exit_icon.svg";
import {
  getResource,
  getModalIcon,
  getModalCloseIcon,
} from "../../../utils/helpers";
import { Footer, FooterContainer } from "../../layout/screens.layout";
import { useOrchestrationToken } from "../../../hooks/useOrchestrationToken";
import Modal from "../../../components/modal/Modal";
import { Button, ModalWrapper } from "../../select/select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { TextResourcesContext } from "../../../context/textResourcesContext";

const ActionButtonAnchor = styled(LoadableButton)`
  ${(props) =>
    props.theme.settings.intro.layout === "v1" &&
    css`
      width: 50%;
      max-width: calc(50% - 8px);
      margin-right: 1rem;
    `}

  ${(props) =>
    props.theme.settings.intro.layout === "v2" &&
    css`
      width: 100%;
      max-width: 90%;
      margin-bottom: 1rem;
    `}
  flex: 1;
  font-size: 1.7rem;

  @media (max-width: 320px) {
    padding: 0.8rem 1.8rem !important;
    font-size: 1.6rem;
  }
`;

const ButtonsFooter = styled(Footer)`
  ${(props) =>
    props.theme.settings.intro.layout === "v1" &&
    css`
      justify-content: flex-end;
      @media (max-width: 780px) {
        justify-content: center;
      }
    `}

  height: auto;
  padding: 1rem 0;

  ${(props) =>
    props.theme.settings.intro.layout === "v2" &&
    css`
      margin-bottom: 0 !important;
    `}
`;
const ButtonsFooterContainer = styled(FooterContainer)`
  ${(props) =>
    props.theme.settings.intro.layout === "v1" &&
    css`
      justify-content: flex-end;
      align-items: center;
      gap: 0.5rem;
      @media (max-width: 780px) {
        flex-direction: column;
        button {
          max-width: none;
          width: 100%;
          margin-right: 0;
        }
      }
    `}

  ${(props) =>
    props.theme.settings.intro.layout === "v2" &&
    css`
      margin-top: 0 !important;
    `}
`;
function ButtonsComponent({
  loadingAcceptTerms,
  acceptTerms,
  agreeTerms,
  loading,
}: any) {
  const { t } = useTranslation();
  const history = useHistory();
  const { resources } = useContext(ResourcesContext);
  const { data } = useContext(TextResourcesContext);
  const theme: any = useTheme();
  const orchestrationToken = useOrchestrationToken();
  const [showModal, setShowModal] = useState(false);
  const modal = useRef(null);
  const { execute: exitJourney, loading: exitJourneyLoading } =
    useExitJourney(orchestrationToken);
  return (
    <>
      <ButtonsFooter
        className={`mt-4 ${theme.settings.intro.layout == "v2" && "bg-none"}`}
      >
        <ButtonsFooterContainer
          className={`${
            theme.settings.intro.layout == "v2" &&
            "align-items-center flex-column mt-5"
          }`}
        >
          {theme.settings?.intro?.declineServiceVisible && (
            <ActionButtonAnchor
              id="introExit"
              className={`${
                !theme?.colors?.buttons?.secondary ? " exp-outline-default" : ""
              } btn text-uppercase-first-letter`}
              onClick={() => setShowModal(true)}
              isLoading={exitJourneyLoading}
              disabled={exitJourneyLoading}
              variant={
                theme.settings.intro?.texts?.introExitButton?.type === "link"
                  ? "link"
                  : "secondary"
              }
            >
              {getResource(data, "CJ_introScreen_Exit_Button")}
            </ActionButtonAnchor>
          )}
          <ActionButtonAnchor
            id="introContinue"
            className={`${
              !theme?.colors?.buttons?.primary ? " exp-default" : ""
            } btn text-uppercase-first-letter`}
            onClick={acceptTerms}
            isLoading={loadingAcceptTerms}
            disabled={!agreeTerms || loading || loadingAcceptTerms}
            variant={
              theme.settings.intro?.texts?.introContinueButton?.type === "link"
                ? "link"
                : "primary"
            }
          >
            {getResource(data, "CJ_introScreen_Continue_Button")}
          </ActionButtonAnchor>
        </ButtonsFooterContainer>
      </ButtonsFooter>
      <Modal>
        <ModalWrapper className={showModal ? "visible" : ""} ref={modal}>
          <div className="modal-body">
            <div className="modal-header">
              {getModalIcon(
                theme?.colors?.icons?.exitJourneyModalIcon,
                <ExitModalIcon />
              )}
              <span
                className="close-btn"
                onClick={() => {
                  setShowModal(false);
                  document.body.style.overflow = "auto";
                }}
              >
                {getModalCloseIcon(
                  theme?.colors?.icons?.modalCloseIcon,
                  theme?.colors?.icons?.default
                )}
              </span>
            </div>
            <div className="d-flex flex-column w-100">
              <h4 className="font-weight-bold">
                <span className="modal-title">
                  {getResource(data, "CJ_introScreen_exitPopupTitle")}{" "}
                </span>
              </h4>
              <h4>
                <span className="modal-subtitle">
                  {getResource(data, "CJ_introScreen_exitPopupSubtitle")}
                </span>
              </h4>
            </div>
            <div className="btns-wrapper">
              <Button
                id="yesExit"
                isLoading={exitJourneyLoading}
                disabled={exitJourneyLoading}
                onClick={() => {
                  !resources?.lender.url
                    ? exitJourney(
                        () =>
                          history.push(
                            `/finish?orchestrationToken=${orchestrationToken}`,
                            { status: "fail" }
                          ),
                        "ServiceDeclinedByUser",
                        "ProcessingFailed"
                      )
                    : exitJourney(
                        () => window.open(resources?.lender.url, "_self"),
                        "ServiceDeclinedByUser",
                        "ProcessingFailed"
                      );
                }}
                variant={
                  theme.settings.intro?.texts?.introYesExitButton?.type ===
                  "link"
                    ? "link"
                    : "secondary"
                }
                className="btn text-uppercase-first-letter text-center bg-white btn-block"
              >
                {getResource(data, "CJ_introScreen_ExitPopupYes_Button")}
              </Button>
              <Button
                id="noExit"
                disabled={false}
                isLoading={false}
                onClick={() => {
                  setShowModal(false);
                  document.body.style.overflow = "auto";
                }}
                variant={
                  theme.settings.intro?.texts?.introNoExitButton?.type ===
                  "link"
                    ? "link"
                    : "primary"
                }
                className="btn text-uppercase-first-letter text-center bg-white btn-block"
              >
                {getResource(data, "CJ_introScreen_ExitPopupNo_Button")}
              </Button>
            </div>
          </div>
        </ModalWrapper>
      </Modal>
    </>
  );
}

export default ButtonsComponent;
