import React, { useState } from "react";
import styled, { useTheme } from "styled-components/macro";
import { useLender } from "../../hooks/useLender";
import Modal from "../modal/Modal";
import NeedHelpPopup from "../needHelp/needHelpPopup";
import NeedHelpLinkComponent from "../needHelp/NeedHelpLinkComponent";
const StyledHeader = styled.header`
  background: ${(props) => props.theme?.settings?.header?.background};
  position: fixed;
  top: 0;
  z-index: 1001;
  overflow: hidden;
  height: ${(props) =>
    props.theme?.settings?.header?.minHeight ?? "var(--section-height)"};
  min-height: ${(props) =>
    props.theme?.settings?.header?.minHeight ?? "var(--section-height)"};
`;

const LogoContainer = styled.div`
  max-width: ${(props) =>
    props.theme?.settings?.screenContainer?.maxWidth ?? "90%"};
  padding: ${(props) =>
    props.theme?.settings?.screenContainer?.padding ?? "2rem"};
  height: 100%;
  display: flex;
  justify-content: ${(props) =>
    props.theme?.settings?.header?.logoAlignment
      ? "flex-end"
      : "space-between"};
  align-items: center;
  margin: 0 auto;

  a {
    font-size: 1.4rem;
    text-decoration: underline;
    color: ${(props) => props.theme?.settings?.header?.text};
  }

  .img-logo {
    max-width: ${(props) =>
      props.theme.settings?.header?.logo?.mobileWidth ?? "8.8rem"};
    max-height: ${(props) =>
      props.theme.settings?.header?.logo?.mobileHeight ?? "4.2rem"};

    @media (min-width: 1024px) {
      max-width: ${(props) =>
        props.theme.settings?.header?.logo?.desktopWidth ?? "12.8rem"};
      max-height: ${(props) =>
        props.theme.settings?.header?.logo?.desktopHeight ?? "4.2rem"};
    }
  }
`;

const Header: React.FC = () => {
  const { logoUrl, supportPage } = useLender();

  const [showNeedHelpModal, setShowNeedHelpModal] = useState(false);
  const theme: any = useTheme();
  return (
    <StyledHeader>
      <LogoContainer>
        {/* <Logo /> */}
        <img
          className="img-logo w-100 h-100"
          src={logoUrl}
          style={theme?.settings?.header?.logoAlignment?.styles}
        />
        {supportPage &&
          theme.settings?.header?.visible &&
          theme.settings?.needHelp?.type !== "popup" && (
            <a href={supportPage} target="_blank">
              <NeedHelpLinkComponent />
            </a>
          )}
        {supportPage &&
          theme.settings?.header?.visible &&
          theme.settings?.needHelp?.type == "popup" && (
            <a
              style={{
                cursor: "pointer",
                ...theme?.settings?.needHelpText?.styles,
              }}
              onClick={() => setShowNeedHelpModal(true)}
            >
              <NeedHelpLinkComponent />
            </a>
          )}

        {showNeedHelpModal ? (
          <Modal>
            <NeedHelpPopup onClose={() => setShowNeedHelpModal(false)} />
          </Modal>
        ) : null}
      </LogoContainer>
    </StyledHeader>
  );
};

export default Header;
