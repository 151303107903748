import { useContext } from "react";
import { ResourcesContext } from "../context/consumer";
import { BootstrapResponse } from "./useBootstrap";

const useConsumer = () => {
  const value = useContext(ResourcesContext) as Record<
    "resources",
    BootstrapResponse
  >;
  const {
    resources: {
      consumer: {
        email = "",
        firstName = "",
        middleName = "",
        lastName = "",
        phoneNumber = "",
        companyName = "",
        type = "",
      },
    },
  } = value;
  return {
    email,
    firstName,
    middleName,
    lastName,
    phoneNumber,
    companyName,
    type,
  };
};

export { useConsumer };
