import React from "react";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { ScreenContainer } from "../layout/screens.layout";

const StyledNumbers = styled.span`
  text-shadow: -8px 0 0 var(--exp-white);
`;

const H2 = styled.h2`
  font-family: cabin, sans-serif;
  font-size: 2rem;
  font-weight: 400;
  text-transform: uppercase;
  color: var(--exp-medium-grey);
  margin-top: 0;
  margin-bottom: 25px;
  position: relative;
  z-index: 2;
`;

const StyledContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  max-width: 520px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
`;

const StyledInnerWrapper = styled.div`
  position: relative;
  height: 240px;
`;

const H1 = styled.h1`
  font-family: montserrat, sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 252px;
  font-weight: 900;
  margin: 0;
  color: var(--exp-medium-grey);
  text-transform: uppercase;
  letter-spacing: -40px;
  margin-left: -20px;
`;
const H3 = styled.h3`
  font-family: cabin, sans-serif;
  position: relative;
  font-size: 1.6rem;
  font-weight: 700;
  bottom: 10px;
  text-transform: uppercase;
  color: var(--exp-medium-grey);
  margin: 0;
  letter-spacing: 3px;
  padding-left: 6px;
`;

interface Props {}

const NotFound: React.FC<Props> = () => {
  const history = useHistory();
  return (
    <ScreenContainer>
      <StyledContainer>
        <StyledInnerWrapper>
          <H3>Oops! Not Found!</H3>
          <H1>
            <StyledNumbers>4</StyledNumbers>
            <StyledNumbers>0</StyledNumbers>
            <StyledNumbers>4</StyledNumbers>
          </H1>
        </StyledInnerWrapper>
        <H2>
          Please{" "}
          <button
            onClick={() => history.replace("/")}
            className="btn btn-sm btn-exp-medium-grey"
          >
            Go Back
          </button>{" "}
        </H2>
      </StyledContainer>
    </ScreenContainer>
  );
};

export default NotFound;
