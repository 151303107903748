import { useState } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled, { css, useTheme } from "styled-components";
import { ResourcesContext } from "../../context/consumer";
import { useAddConsent } from "../../hooks/useAddConsent";
import { useOrchestrationToken } from "../../hooks/useOrchestrationToken";
import { Content } from "../../screens/layout/screens.layout";
import { getTextWithPopulatePlaceholders } from "../../utils/helpers";
import { Image } from "../image/image.component";
import Loader from "../loading/loading-small.component";

const BanksContentWrapper = styled(Content)`
  display: grid;
  margin-bottom: 1rem;
  width: 100%;
  justify-content: center;
  gap: 1rem;
  ${(props) =>
    props.theme.settings.select.layout === "v1" &&
    css`
      grid-template-columns: repeat(6, minmax(100px, 1fr));

      @media (max-width: 768px) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media (max-width: 500px) {
        grid-template-columns: repeat(3, 1fr);
      }
    `}

  ${(props) =>
    props.theme.settings.select.layout === "v2" &&
    css`
      grid-template-columns: repeat(3, minmax(100px, 1fr));

      @media (max-width: 478px) {
        grid-template-columns: repeat(3, minmax(70px, 1fr));
      }
    `}
`;

const BankContainer = styled.div<{ disabled: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #d7d7d7;
  padding: 1rem;
  cursor: pointer;
  text-align: center;
  & > img {
    height: 62px;
  }
  ${({ disabled }) => disabled && "opacity: .5; "}

  .tick {
    position: absolute;
    top: -8px;
    right: -8px;
    background: #fff;
    width: 20px;
    height: 20px;
    .tickIcon {
      width: 100%;
      display: block;
      height: 20px;
      background-size: 70%;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
`;
const TopBanksLoader = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5rem;

  & .spinner-border {
    color: #c3c3c3;
    width: 3rem !important;
    height: 3rem !important;
  }
`;
function GroupBanksComponent({ onFilterByGroup }: any) {
  const orchestrationToken = useOrchestrationToken();
  const { t } = useTranslation();
  const { resources } = useContext(ResourcesContext);
  const { loading: loadingLockConsent } = useAddConsent(orchestrationToken);
  const theme: any = useTheme();
  const [currentGroup, setCurrentGroup] = useState<string>("");

  return (
    <>
      {resources!.lender!.bankGroupsList!.length > 0 ? (
        <>
          <span
            style={theme.settings?.select?.bankGroups?.sectionTitle?.styles}
          >
            {theme.settings?.select?.bankGroups?.sectionTitle
              ? getTextWithPopulatePlaceholders(
                  theme.settings.select?.bankGroups?.sectionTitle,
                  resources
                )
              : t("selectScreen.bankGroupsSectionTitle")}
          </span>
          <BanksContentWrapper className="mt-0 flex-row">
            {resources?.lender?.bankGroupsList?.map((bankGroup, index) => {
              return (
                <BankContainer
                  disabled={loadingLockConsent}
                  style={
                    currentGroup === bankGroup.groupName
                      ? theme?.settings?.select?.bankGroups?.selected
                      : theme?.settings?.select?.bankGroups?.default
                  }
                  onClick={() => {
                    if (currentGroup === bankGroup?.groupName) {
                      setCurrentGroup("");
                      onFilterByGroup("");
                    } else {
                      setCurrentGroup(bankGroup?.groupName);
                      onFilterByGroup(bankGroup?.groupCode);
                    }
                  }}
                >
                  <Image
                    alt={bankGroup?.groupName}
                    src={bankGroup?.groupLogo}
                  />
                  <span>{bankGroup?.groupName}</span>
                </BankContainer>
              );
            })}
            {loadingLockConsent && (
              <TopBanksLoader>
                <Loader />
              </TopBanksLoader>
            )}
          </BanksContentWrapper>
        </>
      ) : null}
    </>
  );
}

export default GroupBanksComponent;
