import React, { ReactElement } from "react";
import styled from "styled-components";

interface Props {
  image: ReactElement;
  text: string;
}

const ParagraphContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
`;

function ParagraphComponent({ image, text }: Props) {
  return (
    <ParagraphContainer>
      {image}
      <p className="m-0">{text}</p>
    </ParagraphContainer>
  );
}

export default ParagraphComponent;
