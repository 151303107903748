import { css, keyframes } from "styled-components/macro";

export const snackBarIn = keyframes`
  from {bottom: 0; opacity: 0;}
  to {bottom: 10px; opacity: 1;}
`;

export const snackBarOut = keyframes`
  from {bottom: 10px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
`;

export const snackBarInOut = () => css`
  ${snackBarIn} 0.5s,
   ${snackBarOut} 0.5s 2.5s;
`;

export const fadeOutBgAnimation = keyframes`
  0% {
    background: rgba(255, 255, 255, 0);
  }
  100% {
    background: rgba(255, 255, 255, 0.7);
  }
`;

export const fadeInScaleUpAnimation = () => css`
  ${scaleUpAnimation} 1.5s linear infinite alternate,
  ${fadeAnimation} 1.5s ease-out infinite;
`;

export const scaleUpAnimation = keyframes`
  0% {
    transform: scale(0.7, 0.7);
  }
  100% {
    transform: scale(1, 1);
  }
`;

export const fadeAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

export const fade = keyframes`
  0% {
    opacity: 0
  }

  100% {
    opacity: 1;
  }
`;
