import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  height: 2rem;
  width: 100%;
  background-color: ${({ theme }) =>
    theme?.settings?.customProgressBar?.styles?.defaultBackground ?? "#e0e0de"};
  border-radius: ${({ theme }) =>
    theme?.settings?.customProgressBar?.styles?.borderRadius};
  margin-top: 1rem;
  overflow: hidden;
`;

const Filler = styled.div<{
  $completedPercentages?: number;
}>`
  height: 100%;
  width: ${(props) => props.$completedPercentages}%;
  background-color: ${({ theme }) =>
    theme?.settings?.customProgressBar?.styles?.activeBackground ??
    theme?.colors?.icons?.default};

  border-radius: ${({ theme }) =>
    theme?.settings?.customProgressBar?.styles?.borderRadius};
  text-align: right;
  transition: width 1s ease-in-out;
`;

interface Props {
  totalTimeInMinutes: number;
  hasProcessing: boolean | number;
  lastProcessedBankStatus?: string;
  onCompletedPercentages: (timeout?: boolean) => void;
}

function CustomProgressBar({
  totalTimeInMinutes,
  hasProcessing,
  lastProcessedBankStatus,
  onCompletedPercentages,
}: Props) {
  const [completedPercentages, setCompletedPercentages] = useState(0);
  const timeout = useRef<any>(null);
  useEffect(() => {
    if (completedPercentages === 100) {
      console.log({ completedPercentages });
      setTimeout(() => {
        onCompletedPercentages(true);
      }, 1000);
    }
  }, [completedPercentages]);
  useEffect(() => {
    const totalTime = totalTimeInMinutes * 60 * 1000; // 15 minutes in milliseconds
    let startTime = Date.now();

    const updateProgress = () => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTime;

      if (elapsedTime < totalTime) {
        const newProgress = (elapsedTime / totalTime) * 100;
        setCompletedPercentages(newProgress);
        timeout.current = setTimeout(updateProgress, 1000);
      } else {
        onCompletedPercentages();
      }
    };
    if (lastProcessedBankStatus === "Completed") {
      setCompletedPercentages(100);
    }
    if (lastProcessedBankStatus === "Error") {
      onCompletedPercentages();
    }
    if (hasProcessing) {
      updateProgress();
    }
    if (!hasProcessing) {
      setCompletedPercentages(100);
      setTimeout(() => {
        onCompletedPercentages(false);
      }, 1000);
    }
    return () => clearTimeout(timeout.current);
  }, [hasProcessing, lastProcessedBankStatus]);
  return (
    <Container>
      <Filler $completedPercentages={completedPercentages}></Filler>
    </Container>
  );
}

export default CustomProgressBar;
