import React from "react";
import styled from "styled-components/macro";

import Logo from "../logo/logo-loading.component";
import * as animations from "../../styles/animations/animations";

const LoaderContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  animation-name: ${animations.fadeOutBgAnimation};
  animation-duration: 0.5s;
  animation-timing-function: linear;

  svg {
    width: 120px;
    height: 120px;
  }
`;

const LoaderWrapper = styled.div`
  width: 140px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15%;
`;

/* animation-name: ${animations.fadeOutBgAnimation}; */

interface Props {
  inScreen?: boolean;
}

/** Spinner component */
const Loader: React.FC<Props> = ({ inScreen = false }) => (
  <LoaderContainer
    className={`loader${inScreen ? " in-screen" : ""}`}
    role="status"
  >
    <LoaderWrapper className="inner-wrapper">
      <Logo />
    </LoaderWrapper>
    {/* <FontAwesomeIcon size="3x" spin icon={faSpinner} />{' '} */}
  </LoaderContainer>
);

export default Loader;
