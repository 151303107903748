export const getFooterPosition = (theme: any) => {
  const footerItemsPosition = theme?.settings?.footer?.position;
  let position = "";
  switch (footerItemsPosition) {
    case "center":
      position = "center";
      break;
    case "left":
      position = "flex-start";
      break;
    case "right":
      position = "flex-end";
      break;
    default:
      position = "center";
      break;
  }
  return position;
};
