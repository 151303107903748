import React from "react";
import styled, { useTheme } from "styled-components";
import { ReactComponent as CheckboxSuccessful } from "../../assets/checkbox_successful.svg";
import { ReactComponent as CheckboxUnsuccessful } from "../../assets/checkbox_unsuccessful.svg";
// import checkbox_successful from "../../assets/checkbox_successful.svg";
// import checkbox_unsuccessful from "../../assets/checkbox_unsuccessful.svg";
interface Props {
  checked: any;
  onChange: () => void;
  disabled?: boolean;
  id?: string;
}
const CheckboxSuccessfulIcon = styled(CheckboxSuccessful)`
  //fill: ${(props) => props.theme.colors.icons.default};
`;

const CheckboxUnsuccessfulIcon = styled(CheckboxUnsuccessful)`
  // fill: ${(props) => props.theme.colors.icons.default};
`;
const Checkbox: React.FC<Props> = ({
  checked,
  onChange,
  disabled = false,
  ...props
}) => {
  const theme: any = useTheme();

  const checkIcon = theme?.colors?.icons?.checkIcon;
  const unCheckIcon = theme?.colors?.icons?.unCheckIcon;

  const getCheckIcon = (checkIcon: any) => {
    if (checkIcon) {
      return (
        <span
          style={{
            background: `url(${checkIcon})`,
            width: "24px",
            height: "24px",
            display: "inline-block",
          }}
        ></span>
      );
    }
    return <CheckboxSuccessfulIcon />;
  };
  const getUnCheckIcon = (unCheckIcon: any) => {
    if (unCheckIcon) {
      return (
        <span
          style={{
            background: `url(${unCheckIcon})`,
            width: "24px",
            height: "24px",
            display: "inline-block",
          }}
        ></span>
      );
    }
    return <CheckboxUnsuccessfulIcon />;
  };
  return (
    <div
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onChange();
        }
      }}
      className={`form-check exp-form-checkbox--simple`}
    >
      <label className={`${disabled ? "checkbox-disabled" : ""}`}>
        <input
          type="checkbox"
          checked={checked}
          {...props}
          onChange={onChange}
          disabled={disabled}
        />

        {checked ? getCheckIcon(checkIcon) : getUnCheckIcon(unCheckIcon)}
      </label>
    </div>
  );
};

export default Checkbox;
