import styled from "styled-components";
import { ReactComponent as PencilIcon } from "../../../assets/pencil.svg";

const Panel = styled.div`
  .icon-wrapper {
    background: ${(props) => props.theme.colors.body};
    padding: 0px 0px 0px 5px;
  }
`;
const ConsentContainer = styled.div`
  margin-bottom: 2rem;
  border: ${(props) => `1px solid ${props.theme.colors.icons.default}`};
  border-radius: 20px;
  padding: 20px 10px;
  font-size: 1.7rem;
  position: relative;
`;

function PanelComponent({ children }: any) {
  return (
    <Panel className="col-12 col-md-7 mt-5">
      <ConsentContainer>
        <div
          style={{
            position: "absolute",
            top: "-25px",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div className="icon-wrapper">
            <PencilIcon style={{ width: "40px" }} />
          </div>
        </div>
        {children}
      </ConsentContainer>
    </Panel>
  );
}

export default PanelComponent;
