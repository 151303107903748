import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router";

const OrchestratorContext = createContext("");

const OrchestratorProvider: React.FC = (props: unknown) => {
  const [orchestrationToken, setOrchestrantionId] = useState("");

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const orchestrationTokenParam = query.get("orchestrationToken");

  useEffect(() => {
    setOrchestrantionId(orchestrationTokenParam!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <OrchestratorContext.Provider value={orchestrationToken} {...props} />;
};

export { OrchestratorContext, OrchestratorProvider };
