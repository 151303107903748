import { useState, useCallback } from "react";

import api from "../utils/api";
import { AxiosResponse } from "axios";
import { useError } from "./useError";
import { useHistory } from "react-router";

type AcceptResponse = {
  url: string;
  isSuccess: boolean;
};

const useAcceptConsent = (consentToken: string) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useError();
  const history = useHistory();
  const clearError = useCallback(() => setError!(null), [setError]);

  const doAcceptConsent = useCallback(
    async (cb: (url: string) => void) => {
      try {
        setLoading(true);
        clearError();
        const response = await acceptConsent(consentToken);
        if (response.data.isSuccess) {
          cb(response.data.url);
        } else {
          history.goBack();
        }
      } catch (error) {
        setError!(error);
      } finally {
        setLoading(false);
      }
    },
    [consentToken, clearError, setError]
  );

  return {
    loading,
    error,
    clearError,
    execute: doAcceptConsent,
  };
};

const acceptConsent = (
  consentToken: string
): Promise<AxiosResponse<AcceptResponse>> =>
  api.put(`/api/consents/${consentToken}`);

export { useAcceptConsent, acceptConsent };
