import axios from "axios";

const isProd = process.env.NODE_ENV === "production";

const baseURL = isProd
  ? process?.env?.REACT_APP_API_ENDPOINT || undefined
  : "http://localhost:8088";

// android internal ip "http://10.0.2.2:8088";

const instance = axios.create({
  baseURL,
});

// instance.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     if (error.response.data.errors) {
//       const event = new CustomEvent("be-error", {
//         detail: {
//           errors: error.response.data.errors,
//         },
//       });
//       window.dispatchEvent(event);
//     } else {
//       const { statusText, status } = error.response;
//       const { url } = error.response.config;

//       const event = new CustomEvent("be-error", {
//         detail: {
//           errors: [{ url, statusText, status }],
//         },
//       });
//       window.dispatchEvent(event);
//     }

//     return Promise.reject(error);
//   }
// );

export default instance;
