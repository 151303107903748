import { createContext, useState, useCallback } from "react";
import { Consent } from "../hooks/useConsentedBanks";

type Context = {
  consentedBanks: Consent[];
  setConsentedBanks: (consent: Consent[]) => void;
  consentedBanksCount: number;
};

const ConsentedBanksContext = createContext<Context>({
  consentedBanks: [],
  consentedBanksCount: 0,
  setConsentedBanks: () => {},
});

const ConsentedBanksProvider: React.FC = (props) => {
  const [consentedBanks, setConsents] = useState<Consent[]>([]);

  const setConsentedBanks = useCallback((consents: Consent[]) => {
    setConsents(consents);
  }, []);

  const value = {
    consentedBanks,
    consentedBanksCount: consentedBanks.filter(
      (bank: any) => bank.status === "Completed"
    ).length,
    setConsentedBanks,
  };

  return <ConsentedBanksContext.Provider value={value} {...props} />;
};

export { ConsentedBanksProvider, ConsentedBanksContext };
