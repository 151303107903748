import { AxiosResponse } from "axios";
import { useState, useEffect, useContext } from "react";
import { ResourcesContext } from "../context/consumer";
import api from "../utils/api";
import { useError } from "./useError";
import { useOrchestrationToken } from "./useOrchestrationToken";

export type PrivacyPolicy = {
  text: "";
};

const usePrivacyPolicy = (privacyPolicyAccepted: boolean | undefined) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useError();
  const [privacyPolicy, setPrivacyPolicy] = useState<PrivacyPolicy>({
    text: "",
  });

  const orchestrationToken = useOrchestrationToken();
  const { resources } = useContext(ResourcesContext);

  const refetch = async () => {
    setLoading(true);
    setError!(null);
    setPrivacyPolicy({ text: "" });
    doPrivacyPolicy();
  };

  const doPrivacyPolicy = async () => {
    try {
      const response = await fetchPrivacyPolicy(
        orchestrationToken,
        resources!.language
      );
      setPrivacyPolicy(response.data);
    } catch (error) {
      setError!(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (privacyPolicyAccepted === false) {
      doPrivacyPolicy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    error,
    privacyPolicy,
    refetch,
  };
};

const fetchPrivacyPolicy = (
  orchestrationToken: string,
  language: string
): Promise<AxiosResponse<PrivacyPolicy>> =>
  api.get(`/api/orchestrations/${orchestrationToken}/privacy-notice`);

export { usePrivacyPolicy };
