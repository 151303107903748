import React, { useContext, useState, useEffect } from "react";
import styled, { ThemeProvider, useTheme } from "styled-components/macro";

import ErrorBoundary from "./containers/error_boundary/error_boundary.container";

import Header from "./components/header/header.component";
import Footer from "./components/footer/footer.component";
import Navbar from "./components/navbar/navbar.component";
import AnimatedScreens from "./AnimatedScreens";
import Snackbar from "./containers/snackbar";
import SplashScreen from "./screens/splash";
import ErrorScreen from "./screens/errors/index";
import TimeoutScreen from "./screens/timeout";

import { ResourcesContext } from "./context/consumer";
import initI18n from "./utils/i18n";

import { TimeoutContext } from "./context/timeout";

import * as themes from "../src/theme/schema.json";
import { GlobalStyles } from "../src/theme/GlobalStyles";

interface Props {}

const Layout = styled.div<{ $background?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background: ${(props) => props.$background};
  // background-position: 100% 85%;
`;

const App: React.FC<Props> = () => {
  const { resources, error } = useContext(ResourcesContext);
  const { timeout } = useContext(TimeoutContext);
  const hasResources = !!resources;

  const theme = useTheme();
  const [selectedTheme, setSelectedTheme] = useState<any>(null);

  useEffect(() => {
    if (resources?.lender?.customThemeSettings) {
      setSelectedTheme(
        resources?.lender?.customThemeSettings &&
          JSON.parse(resources?.lender?.customThemeSettings)
      );
    } else {
      setSelectedTheme(themes.data.experian);
    }
  }, [resources]);

  if (resources?.language) {
    initI18n(resources?.language);
  }

  const renderScreens = () => {
    if (timeout) {
      return <TimeoutScreen />;
    } else if (error) {
      return <ErrorScreen />;
    } else if (!hasResources) {
      return <SplashScreen />;
    } else if (hasResources) {
      return (
        <Layout $background={selectedTheme?.settings?.layout?.background}>
          {selectedTheme && (
            <ThemeProvider theme={selectedTheme}>
              <GlobalStyles />
              {selectedTheme.settings?.header?.visible && <Header />}

              <AnimatedScreens />
              {selectedTheme.settings?.footer?.visible && <Footer />}
              <Snackbar />
            </ThemeProvider>
          )}
        </Layout>
      );
    } else {
      throw new Error("Page not found");
    }
  };

  return <ErrorBoundary>{renderScreens()}</ErrorBoundary>;
};

export default App;
