import React, { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import Collapsable from "../../../components/collapse/collapse.component";
import { ReactComponent as UserIcon } from "../../../assets/user.svg";
import { ReactComponent as PaymentsIcon } from "../../../assets/payments.svg";
import { ReactComponent as BusinessIcon } from "../../../assets/business.svg";
import { CollapsableIconHeader } from "../../consent";
import {
  getResource,
  getTextWithPopulatePlaceholders,
} from "../../../utils/helpers";
import { ResourcesContext } from "../../../context/consumer";
import { TextResourcesContext } from "../../../context/textResourcesContext";

const Row = styled.p`
  margin-bottom: 1rem;
  padding: 0 1.2rem;
`;

const MoreInformationWrapper = styled.div`
  margin: ${(props) =>
    props.theme?.settings?.screenContainer?.maxWidth ? "0 3rem" : "0 auto"};
  white-space: pre-line;
`;

export const CollapsableIconContainer = styled.span`
  margin-right: 8px;
  & svg {
    margin-top: -4px;
    width: 100%;
    height: 100%;
    max-width: 20px;
    max-height: 20px;
  }
  height: 20px;
  width: 20px;
`;

const IconContainer = styled.span`
  display: inline-flex;
  margin-right: 8px;
  position: relative;
  top: 2px;
  & svg {
    width: 100%;
    height: 100%;
    max-width: 20px;
    max-height: 20px;
  }
  height: 20px;
  width: 20px;
`;
const BodyContainer = styled.span`
  margin-left: 0.5rem;
  font-size: 1.6rem;
`;

const HeaderContainer = styled.span`
  font-size: 1.6rem;
`;
function MoreInformationComponent({ lenderName }: any) {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const { resources } = useContext(ResourcesContext);
  const settings = theme?.settings?.consent?.whatNeedToShareSection;
  const { data } = useContext(TextResourcesContext);
  const sectionItems = [
    <div>
      <p style={settings?.header?.styles ?? { fontWeight: "bold" }}>
        <span>{getResource(data, "CJ_consentScreen_shareReasonTitle")}</span>
      </p>
      <p className="mb-0">
        <span>
          {getResource(
            data,
            "CJ_consentScreen_shareReasonParagraph1"
          ).replaceAll("{{lenderName}}", lenderName)}
        </span>
      </p>
      <p>
        {getResource(data, "CJ_consentScreen_shareReasonParagraph2").replaceAll(
          "{{lenderName}}",
          lenderName
        )}
      </p>
    </div>,
    <div>
      <p style={settings?.header?.styles ?? { fontWeight: "bold" }}>
        <span>{getResource(data, "CJ_consentScreen_sharedDataTitle")}</span>
      </p>
    </div>,

    <Row>
      <IconContainer
        style={{
          background: settings?.yourAccountHolderDetails?.icon ?? "none",
        }}
      >
        {!settings?.yourAccountHolderDetails?.icon && <UserIcon />}
      </IconContainer>
      <HeaderContainer
        style={settings?.header?.styles ?? { fontWeight: "bold" }}
      >
        {getResource(data, "CJ_consentScreen_accountHolderInfo_title")}
      </HeaderContainer>
      <BodyContainer>
        {" "}
        {getResource(data, "CJ_consentScreen_accountHolderInfo_text")}
      </BodyContainer>
    </Row>,

    <Row>
      <IconContainer
        style={{ background: settings?.yourRegularPayments?.icon ?? "none" }}
      >
        {!settings?.yourRegularPayments?.icon && <PaymentsIcon />}
      </IconContainer>
      <HeaderContainer
        style={settings?.header?.styles ?? { fontWeight: "bold" }}
      >
        {getResource(data, "CJ_consentScreen_regularPayments_title")}
      </HeaderContainer>
      <BodyContainer>
        {" "}
        {getResource(data, "CJ_consentScreen_regularPayments_text")}
      </BodyContainer>
    </Row>,
    <Row>
      <IconContainer
        style={{
          background: settings?.yourAccountTransactions?.icon ?? "none",
        }}
      >
        {!settings?.yourAccountTransactions?.icon && <BusinessIcon />}
      </IconContainer>
      <HeaderContainer
        style={settings?.header?.styles ?? { fontWeight: "bold" }}
      >
        {getResource(data, "CJ_consentScreen_accountTransactionsPeriod_title")}
      </HeaderContainer>

      <BodyContainer>
        {getResource(data, "CJ_consentScreen_accountTransactionsPeriod_text")}
      </BodyContainer>
    </Row>,
  ];

  return <MoreInformationWrapper>{sectionItems}</MoreInformationWrapper>;
}

export default MoreInformationComponent;
