import { useState, useCallback } from "react";

import api from "../utils/api";
import { AxiosResponse } from "axios";
import { useError } from "./useError";

type RejectResponse = {};

const useRejectConsent = (consentToken: string) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useError();

  const clearError = useCallback(() => setError!(null), [setError]);

  const doRejectConsent = useCallback(
    async (cb: () => void) => {
      try {
        setLoading(true);
        clearError();
        await rejectConsent(consentToken);
        cb();
      } catch (error) {
        setError!(error);
      } finally {
        setLoading(false);
      }
    },
    [consentToken, clearError, setError]
  );

  return {
    loading,
    error,
    clearError,
    execute: doRejectConsent,
  };
};

const rejectConsent = (
  consentToken: string
): Promise<AxiosResponse<RejectResponse>> =>
  api.patch(`/api/consents/${consentToken}/reject`);

export { useRejectConsent, rejectConsent };
