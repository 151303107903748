import { AxiosResponse } from "axios";
import { useState, useEffect, useContext } from "react";
import { ResourcesContext } from "../context/consumer";
import api from "../utils/api";
import { useError } from "./useError";
import { useOrchestrationToken } from "./useOrchestrationToken";

export type Terms = {
  terms: "";
};

const useTerms = (termsAccepted: boolean | undefined) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useError();
  const [terms, setTerms] = useState<Terms>({ terms: "" });

  const orchestrationToken = useOrchestrationToken();
  const { resources } = useContext(ResourcesContext);

  const refetch = async () => {
    setLoading(true);
    setError!(null);
    setTerms({ terms: "" });
    doFetchTerms();
  };

  const doFetchTerms = async () => {
    try {
      const response = await fetchTerms(
        orchestrationToken,
        resources!.language
      );
      setTerms(response.data);
    } catch (error) {
      setError!(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (termsAccepted === false) {
      doFetchTerms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    error,
    terms,
    refetch,
  };
};

const fetchTerms = (
  orchestrationToken: string,
  language: string
): Promise<AxiosResponse<Terms>> =>
  api.get(
    `/api/orchestrations/${orchestrationToken}/terms?language=${language}`
  );

export { useTerms };
