import { useState, useEffect, useContext, useCallback } from "react";
import { AxiosResponse } from "axios";
import { ConsentedBanksContext } from "../context/consentedBanks";
import api from "../utils/api";
import { Consent, fetchConsentedBanksList } from "./useConsentedBanks";
import { useError } from "./useError";
import { useOrchestrationToken } from "./useOrchestrationToken";
import { ResourcesContext } from "../context/consumer";

export type ConsentStatus = "Approved" | "Failed" | "Completed" | "Error";

export type Bank = {
  providerCode: string;
  consentInstructionText: string;
  name: string;
  logo: string;
  selected?: boolean;
  status: ConsentStatus;
  url: string;
  errorCode?: number;
  errorMessage?: string;
  countryCode?: string;
  groupCode: string;
  highlight?: string;
};

const useBanksList = (processCompleted: boolean | undefined) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useError();
  const [banks, setBanks] = useState<Bank[]>([]);
  const { setConsentedBanks } = useContext(ConsentedBanksContext);
  const { resources } = useContext(ResourcesContext);

  const orchestrationToken = useOrchestrationToken();

  const refetch = async (onlyConsents: boolean) => {
    setLoading(true);
    setError!(null);

    if (onlyConsents) {
      fetchConsentsOnly();
    } else {
      setBanks([]);
      fetchBanks();
    }
  };

  const fetchConsentsOnly = useCallback(async () => {
    try {
      const response = await fetchConsentedBanksList(orchestrationToken);

      const consents = response.data.consents.filter((bank) =>
        ["completed", "error", "processing"].includes(
          bank.status.toLocaleLowerCase()
        )
      );
      setConsentedBanks(consents);
    } catch (error) {
      setError!(error);
    } finally {
      setLoading(false);
    }
  }, [orchestrationToken, setConsentedBanks, setError]);

  const fetchBanks = useCallback(async () => {
    try {
      const response = await fetchBanksAndConsents(orchestrationToken);

      const consents = response[1].data.consents.filter((bank) =>
        ["completed", "error", "processing"].includes(
          bank.status.toLocaleLowerCase()
        )
      );

      const banks = transformBankData(response[0].data, consents);

      setBanks(banks);
      setConsentedBanks(consents);
    } catch (error) {
      setError!(error);
    } finally {
      setLoading(false);
    }
  }, [orchestrationToken, setConsentedBanks, setError]);

  useEffect(() => {
    if (resources?.processCompleted === false) {
      fetchBanks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchBanks, orchestrationToken]);

  return {
    loading,
    error,
    banks,
    refetch,
  };
};

const fetchBanksAndConsents = (orchestrationToken: string) =>
  Promise.all([
    fetchBanksList(orchestrationToken),
    fetchConsentedBanksList(orchestrationToken),
  ]);

const fetchBanksList = (
  orchestrationToken: string
): Promise<AxiosResponse<Bank[]>> =>
  api.get(`/api/orchestrations/${orchestrationToken}/providers/`);

const transformBankData = (banks: Bank[], selected: Consent[]) => {
  const selectedBanksByProviderCode = transformSelectedBanks(selected);

  return banks.map((bank) => ({
    ...bank,
    selected: selectedBanksByProviderCode[bank.providerCode] ? true : false,
  }));
};

const transformSelectedBanks = (
  consents: Consent[]
): { [id: string]: Consent } => {
  return consents.reduce<{ [providerCode: string]: Consent }>(
    (transformedConsents, consent) => {
      return {
        ...transformedConsents,
        [consent.providerCode]: consent,
      };
    },
    {}
  );
};

export { useBanksList, fetchBanksList, fetchBanksAndConsents };
