import BankSummary from "./BankSummary";
import { useConsentedBanksList } from "../../hooks/useConsentedBanks";
import { BankSummariesProps, Bank } from "./interfaces";

const BankSummaries = ({ bankSummaries }: BankSummariesProps) => {
  const { consents } = useConsentedBanksList(true);
  return (
    <div>
      {consents &&
        bankSummaries?.map((bankSummary: Bank, index: number) => {
          console.log({ bankSummary });
          bankSummary.logo = consents?.filter(
            (consent: { name: string }) => consent.name === bankSummary.bankName
          )[0]?.logo;
          return (
            <>
              <BankSummary
                key={`${bankSummary.bankName}_${index}`}
                bankSummary={bankSummary}
              />
            </>
          );
        })}
    </div>
  );
};

export default BankSummaries;
