import { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router";

//hooks
import { useConsumer } from "../../hooks/useConsumer";
import { useLender } from "../../hooks/useLender";
import { useConsentedBanksList } from "../../hooks/useConsentedBanks";
import { useAcceptTerms } from "../../hooks/useAcceptTerms";

// import FooterComponent from "../../components/footer/footer.component";
import Loading from "../../components/loading/loading.component";

//layout components
import {
  ScreenContentWrapper,
  Content,
  ScreenContainer,
} from "../layout/screens.layout";
import PrivacyPolicyModalToggle from "./components/PrivacyPolicyModalToggle";
//utils
import { scrollTop } from "../../utils/scrollTop";
import { useOrchestrationToken } from "../../hooks/useOrchestrationToken";
import { useExitConsent } from "../../hooks/useExitConsent";

//contexts
import { ConsentedBanksContext } from "../../context/consentedBanks";
import Progressbar from "../../components/progressbar/progressbar.component";
import Navbar from "../../components/navbar/navbar.component";
import MoreInformationComponent from "./components/MoreInformationComponent";
import TermsAndConditionsModalToggle from "./components/TermsAndConditionsModalToggle";
import ButtonsComponent from "./components/ButtonsComponent";
import IntroHeaderComponent from "./components/IntroHeaderComponent";
import ParagraphsComponent from "./components/ParagraphsComponent";
import CheckboxesComponent from "./components/CheckboxesComponent";
import { useTheme } from "styled-components";
import PanelComponent from "./components/PanelComponent";
import AssuranceItems from "../../components/assuranceSection/AssuranceItems";
import useScreenSize from "../../hooks/useScreenSize";
import { getResource } from "../../utils/helpers";
import { TextResourcesContext } from "../../context/textResourcesContext";

const IntroScreen = () => {
  const history = useHistory();

  const { name } = useLender();
  const screenSize = useScreenSize();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const resume = query.get("resume");
  const orchestrationToken = useOrchestrationToken();
  const { loading } = useExitConsent(orchestrationToken);
  const theme: any = useTheme();
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalPrivacyPolicy, setShowModalPrivacyPolicy] = useState(false);
  const { data } = useContext(TextResourcesContext);
  const { consentedBanksCount } = useContext(ConsentedBanksContext);
  const { loading: loadingConsentedBanksList } = useConsentedBanksList();
  const { loading: loadingAcceptTerms, refetch: acceptTerms } = useAcceptTerms(
    () =>
      scrollTop(() =>
        history.push(`/select?orchestrationToken=${orchestrationToken}`)
      )
  );
  useEffect(() => {
    document.title = getResource(data, "CJ_introScreen_tabTitle");
  }, [data]);
  useEffect(() => {
    //logic for entering this screen is from resume link
    if (!loadingConsentedBanksList) {
      if (`${resume}`.toLowerCase() === "true") {
        return history.push(
          `/select?orchestrationToken=${orchestrationToken}&resume=${resume}`
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consentedBanksCount, loadingConsentedBanksList]);

  return !loadingConsentedBanksList ? (
    <ScreenContentWrapper
      $currentScreenWidth={screenSize.width}
      style={{ ...theme.settings.screenContainer, minHeight: "auto" }}
    >
      {theme?.settings.customBackground ? (
        <div
          className="custom-bg"
          style={theme.settings.customBackground?.styles}
        >
          {" "}
        </div>
      ) : null}
      <div>
        <Navbar />
        <ScreenContainer style={{ minHeight: "auto" }}>
          <div>
            <Progressbar />
          </div>
          <Content className="mt-4">
            <IntroHeaderComponent name={name} />

            {theme.settings.intro.layout == "v1" ? (
              <ParagraphsComponent name={name} />
            ) : null}

            {theme.settings.intro.layout == "v1" ? (
              <>
                <CheckboxesComponent
                  setAgreeTerms={setAgreeTerms}
                  setShowModal={setShowModal}
                  setShowModalPrivacyPolicy={setShowModalPrivacyPolicy}
                  agreeTerms={agreeTerms}
                />

                <ButtonsComponent
                  loadingAcceptTerms={loadingAcceptTerms}
                  acceptTerms={acceptTerms}
                  agreeTerms={agreeTerms}
                  loading={loading}
                />
              </>
            ) : (
              <PanelComponent name={name}>
                <CheckboxesComponent
                  setAgreeTerms={setAgreeTerms}
                  setShowModal={setShowModal}
                  agreeTerms={agreeTerms}
                  setShowModalPrivacyPolicy={setShowModalPrivacyPolicy}
                />

                <ButtonsComponent
                  loadingAcceptTerms={loadingAcceptTerms}
                  acceptTerms={acceptTerms}
                  agreeTerms={agreeTerms}
                  loading={loading}
                />
              </PanelComponent>
            )}

            <TermsAndConditionsModalToggle
              showModal={showModal}
              setShowModal={setShowModal}
              setAgreeTerms={setAgreeTerms}
            />

            <PrivacyPolicyModalToggle
              showModal={showModalPrivacyPolicy}
              setShowModal={setShowModalPrivacyPolicy}
              setAgreePrivacyPolicy={setAgreeTerms}
            />
            <MoreInformationComponent />
          </Content>
          {theme.settings?.assuranceSection?.visible && (
            <Content className="mt-0">
              <AssuranceItems />
            </Content>
          )}
        </ScreenContainer>
      </div>
    </ScreenContentWrapper>
  ) : (
    <Loading />
  );
};

export default IntroScreen;
