import { useState, useCallback } from "react";
import { Bank } from "./useBanksList";

import api from "../utils/api";
import { AxiosResponse } from "axios";
import { useError } from "./useError";

type AddConsent = {
  consentToken: string;
  jointAccountIndicator: boolean;
  primaryBankIndicator: boolean;
};

const useAddConsent = (orchestrationToken: string) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useError();

  const clearError = useCallback(() => setError!(null), [setError]);

  const doAddConsent = useCallback(
    async (
      providerCode: Bank["providerCode"] | null,
      jointAccountIndicator,
      primaryBankIndicator,
      cb: (url: string) => void
    ) => {
      try {
        setLoading(true);
        clearError();
        const response = await addConsent(
          orchestrationToken,
          jointAccountIndicator,
          primaryBankIndicator,
          providerCode!
        );
        cb(response.data.consentToken);
      } catch (error) {
        setError!(error);
      } finally {
        setLoading(false);
      }
    },
    [orchestrationToken, clearError, setError]
  );

  return {
    loading,
    error,
    clearError,
    execute: doAddConsent,
  };
};

const addConsent = (
  orchestrationToken: string,
  jointAccountIndicator: boolean,
  primaryBankIndicator: boolean,
  providerCode: Bank["providerCode"]
): Promise<AxiosResponse<AddConsent>> =>
  api.post(`/api/consents`, {
    orchestrationToken,
    jointAccountIndicator,
    primaryBankIndicator,
    providerCode,
  });

export { useAddConsent, addConsent };
