import React, { ReactComponentElement, ReactElement } from "react";
import styled, { useTheme } from "styled-components";
import { ReactComponent as HappyFace } from "../../assets/happy_face.svg";
import { getItemsPosition } from "./helper";

interface AssuranceItemProps {
  itemIndex: number;
  title: string;
  text: string;
  image: ReactElement;
  imageStyle?: ElementCSSInlineStyle;
}

const AssuranceItemContainer = styled.div<{ $position: string }>`
  flex: 1 1 0;
  display: flex;
  width: 0;
  align-items: ${(props) => props.$position};
  @media (max-width: 768px) {
    flex-direction: column;
    flex: 1;

    flex-direction: row;
    width: auto;
  }
  p {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
`;

function AssuranceItem({
  itemIndex,
  title,
  text,
  image,
  imageStyle,
}: AssuranceItemProps) {
  const theme: any = useTheme();

  return (
    <AssuranceItemContainer
      $position={getItemsPosition(theme)}
      className="justify-center "
      style={theme?.settings?.assuranceSection?.items?.[itemIndex]?.style}
    >
      <div
        style={{ ...{ width: "30px", height: "30px" }, ...imageStyle }}
        className="mr-3"
      >
        {image}
      </div>

      <div className="d-flex flex-column">
        <span
          style={{
            ...{ fontSize: "16px", fontWeight: "bold", color: "#000" },
            ...theme.settings?.assuranceSection?.items?.[itemIndex]?.titleStyle,
          }}
        >
          {title}
        </span>
        <p className="mb-0">{text}</p>
      </div>
    </AssuranceItemContainer>
  );
}

export default AssuranceItem;
