import { AxiosResponse } from "axios";
import { useState, useCallback } from "react";
import api from "../utils/api";
import { getTimeZoneId } from "../utils/helpers";

export type ConsentStatus = "Accepted" | "Error";

export type Consent = {
  id: string;
  name: string;
  logo: string;
  status: ConsentStatus;
  providerCode: string;
};

const useDownloadPdf = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(false);

  const refetch = async () => {
    setLoading(true);
    setError!(null);
    sendOrchestrationFinish();
  };

  const sendOrchestrationFinish = useCallback(async () => {
    try {
      setLoading(true);
      const response: any = await downloadPdf();

      const linkSource = `data:application/pdf;base64,${response.data.documents}`;

      const link = document.createElement("a");
      link.href = linkSource;
      link.setAttribute("download", response.data.fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [setError]);

  return {
    loading,
    error,
    refetch,
  };
};

const downloadPdf = (): Promise<AxiosResponse<{}>> =>
  api.post(
    `/api/summary-data.pdf`,
    { timeZoneId: getTimeZoneId() },
    {
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

export { useDownloadPdf, downloadPdf };
