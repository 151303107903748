import styled, { useTheme } from "styled-components";
import ParagraphsComponent from "./ParagraphsComponent";
import { ReactComponent as PencilIcon } from "../../../assets/pencil.svg";
import { Trans, useTranslation } from "react-i18next";
import {
  getResource,
  getTextWithPopulatePlaceholders,
} from "../../../utils/helpers";
import { useContext } from "react";
import { ResourcesContext } from "../../../context/consumer";
import { TextResourcesContext } from "../../../context/textResourcesContext";

const Panel = styled.div`
  border: ${(props) => `1px solid ${props.theme.colors.icons.default}`};
  border-radius: 20px;
  padding: 20px 10px;
  position: relative;
  .icon-wrapper {
    background: ${(props) => props.theme.colors.body};
    padding: 0px 0px 0px 5px;
  }
`;
const Paragraph = styled.p`
  line-height: 2rem;
`;

function PanelComponent({ name, children }: any) {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const { resources } = useContext(ResourcesContext);
  const { data } = useContext(TextResourcesContext);
  return (
    <div className="row mb-3 mx-0">
      <div className="col-12 col-md-5 mb-4">
        <ParagraphsComponent name={name} />
      </div>
      <Panel className="col-12 col-md-7">
        <div
          style={{
            position: "absolute",
            top: "-25px",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div className="icon-wrapper">
            <PencilIcon style={{ width: "40px" }} />
          </div>
        </div>
        <div>
          <Paragraph
            id="introTermsHeaderB"
            style={{ marginBottom: "1rem", padding: "10px" }}
          >
            {getResource(data, "CJ_introScreen_pleaseRead")}
          </Paragraph>
        </div>

        {children}
      </Panel>
    </div>
  );
}

export default PanelComponent;
