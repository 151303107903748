import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import Checkbox from "../../../components/checkbox";
import { TextResourcesContext } from "../../../context/textResourcesContext";
import { getResource } from "../../../utils/helpers";
import {
  ActionFooter,
  ActionFooterContainer,
  ActionFooterWrapper,
} from "../../layout/screens.layout";

const ActionText = styled.span`
  font-size: 1.4rem;
  line-height: 2rem;
  margin-left: 8px;
  cursor: pointer;
  text-align: left;
`;

function CheckboxesComponent({
  agree,
  consent,
  setAgree,
  setConsent,
  lenderName,
}: any) {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const { data } = useContext(TextResourcesContext);
  return (
    <ActionFooterWrapper>
      <ActionFooter
        className={theme.settings.consent.layout == "v2" ? "bg-none" : ""}
      >
        <ActionFooterContainer style={{ alignItems: "flex-start" }}>
          <Checkbox
            checked={consent}
            onChange={() => setConsent((c: any) => !c)}
          />
          <ActionText
            className="small-text"
            onClick={() => setConsent((c: any) => !c)}
            id="consentConsent"
          >
            {getResource(data, "CJ_consentScreen_psd2Consent_checkbox")}
          </ActionText>
        </ActionFooterContainer>
      </ActionFooter>
      <ActionFooter
        className={theme.settings.consent.layout == "v2" ? "bg-none" : ""}
      >
        <ActionFooterContainer style={{ alignItems: "flex-start" }}>
          <Checkbox
            id="consentAccept"
            checked={agree}
            onChange={() => setAgree((a: any) => !a)}
          />
          <ActionText
            className="small-text"
            onClick={() => setAgree((a: any) => !a)}
            id="consentAgree"
          >
            {getResource(
              data,
              "CJ_consentScreen_gdprConsent_checkbox"
            ).replaceAll("{{lenderName}}", lenderName)}
          </ActionText>
        </ActionFooterContainer>
      </ActionFooter>
    </ActionFooterWrapper>
  );
}

export default CheckboxesComponent;
