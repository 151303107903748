import { AxiosResponse } from "axios";
import { useState, useEffect, useContext, useCallback } from "react";
import { ConsentedBanksContext } from "../context/consentedBanks";
import api from "../utils/api";
import { Bank } from "./useBanksList";
import { useError } from "./useError";
import { useOrchestrationToken } from "./useOrchestrationToken";

export type ConsentStatus = "Accepted" | "Error" | "Completed" | "Processing";

export type Consent = {
  id: string;
  name: string;
  logo: string;
  status: ConsentStatus;
  providerCode: string;
  errorCode?: number;
  errorMessage?: string;
  jointAccountIndicator?: boolean;
  primaryBankIndicator?: boolean;
};

const useTopBanks = (onInit = true) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useError();

  const [topBanks, setTopBanks] = useState<Bank[]>([]);

  const orchestrationToken = useOrchestrationToken();

  const refetch = async () => {
    setLoading(true);
    setError!(null);
    fetchBanks();
    setTopBanks([]);
  };

  const fetchBanks = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetchTopBanksList(orchestrationToken);

      const topBanks = response.data;
      setTopBanks(topBanks);
    } catch (error) {
      setError!(error);
    } finally {
      setLoading(false);
    }
  }, [setTopBanks, orchestrationToken, setError]);

  useEffect(() => {
    if (onInit) {
      fetchBanks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchBanks]);

  return {
    loading,
    error,
    topBanks,
    refetch,
  };
};

const fetchTopBanksList = (
  orchestrationToken: string
): Promise<AxiosResponse<Bank[]>> =>
  api.get(`/api/orchestrations/${orchestrationToken}/providers/top`);

export { useTopBanks };
