import AccountSummary from "./AccountSummary";
import { AccountSummariesProps, Account } from "./interfaces";

const AccountSummaries = ({ accountSummaries }: AccountSummariesProps) => {
  return (
    <div>
      {accountSummaries?.map((accountSummary: Account, index: number) => {
        return (
          <AccountSummary
            key={`${accountSummary.accountNumberEnding}_${index}`}
            accountSummary={accountSummary}
          />
        );
      })}
    </div>
  );
};
export default AccountSummaries;
