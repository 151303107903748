import { AxiosResponse } from "axios";
import { useState, useEffect, useContext } from "react";
import { ResourcesContext } from "../context/consumer";
import api from "../utils/api";
import { useError } from "./useError";
import { useOrchestrationToken } from "./useOrchestrationToken";

const useNeedHelpData = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useError();
  const [template, setTemplate] = useState("");

  const orchestrationToken = useOrchestrationToken();
  const { resources } = useContext(ResourcesContext);

  const refetch = async () => {
    setLoading(true);
    setError!(null);
    setTemplate("");
    doFetchTemplate();
  };

  const doFetchTemplate = async () => {
    try {
      const response = await fetchTemplate(orchestrationToken);
      console.log({ response: response.data });

      setTemplate(response.data.text);
    } catch (error) {
      setError!(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    doFetchTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    error,
    template,
    refetch,
  };
};

const fetchTemplate = (orchestrationToken: string): Promise<AxiosResponse> =>
  api.get(
    `/api/orchestrations/${orchestrationToken}/resources/NeedHelpTemplate`
  );

export { useNeedHelpData };
