import React from "react";

const Loader: React.FC = () => (
  <div className="scale-up-tc">
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export default Loader;
