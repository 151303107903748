import styled, { useTheme } from "styled-components";
import AssuranceItem from "./AssuranceItem";
import { useTranslation } from "react-i18next";
import { ReactComponent as Shield } from "../../assets/shield.svg";
import { ReactComponent as ThumbsUp } from "../../assets/thumbs_up.svg";
import { ReactComponent as Clock } from "../../assets/clock.svg";
import {
  getResource,
  getTextWithPopulatePlaceholders,
} from "../../utils/helpers";
import { ResourcesContext } from "../../context/consumer";
import React, { ReactElement, useContext } from "react";

import { getItemsPosition } from "./helper";
import { useTextResource } from "../../hooks/useTextResource";
import { TextResourcesContext } from "../../context/textResourcesContext";
const AssuranceItemsContainer = styled.div<{ $position: string }>`
  display: flex;
  justify-content: space-between;
  align-items: ${(props) => props.$position};
  gap: 1rem;
  background: ${(props) =>
    props.theme.settings?.assuranceSection?.style?.background};
  padding: 0.5rem 1rem;

  min-height: 7rem;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
  }
`;

export const ImageContainer = styled.div<{ $icon?: string }>`
  background-image: ${(props) => props.$icon};
  background-repeat: no-repeat;
  height: 3rem;
  min-width: 3rem;
`;
function AssuranceItems() {
  const theme: any = useTheme();
  const { resources } = useContext(ResourcesContext);
  const assuranceSectionItems = theme.settings?.assuranceSection?.items;
  let items: Array<{
    title: string;
    text: string;
    image: ReactElement;
    imageStyle?: ElementCSSInlineStyle;
  }> = [];
  const { data } = useContext(TextResourcesContext);

  // Printing the output

  const formatBackgroundUrl = (icon: string) => {
    let formatedIcon = "";
    formatedIcon = icon.replace('url("', "");
    formatedIcon = formatedIcon.replace('")', "");

    return formatedIcon;
  };

  //if there are no setting at all
  if (!assuranceSectionItems) {
    items = [
      {
        title: getResource(data, "CJ_assuranceSection_firstTitle"),
        text: getResource(data, "CJ_assuranceSection_firstParagraph"),
        image: <Shield />,
      },
      {
        title: getResource(data, "CJ_assuranceSection_secondTitle"),
        text: getResource(data, "CJ_assuranceSection_secondParagraph"),
        image: <ThumbsUp />,
      },
      {
        title: getResource(data, "CJ_assuranceSection_thirdTitle"),
        text: getResource(data, "CJ_assuranceSection_thirdParagraph"),
        image: <Clock />,
      },
    ];
  } else {
    items = [
      {
        title: getResource(data, "CJ_assuranceSection_firstTitle"),
        text: getResource(data, "CJ_assuranceSection_firstParagraph"),
        imageStyle: assuranceSectionItems[0]?.iconStyle,
        image: assuranceSectionItems?.[0]?.icon ? (
          <img
            src={formatBackgroundUrl(assuranceSectionItems[0].icon)}
            width="30"
            height="30"
          />
        ) : (
          <Shield />
        ),
      },
      {
        title: getResource(data, "CJ_assuranceSection_secondTitle"),
        text: getResource(data, "CJ_assuranceSection_secondParagraph"),
        imageStyle: assuranceSectionItems[1]?.iconStyle,
        image: assuranceSectionItems?.[1]?.icon ? (
          <img
            src={formatBackgroundUrl(assuranceSectionItems[1].icon)}
            width="30"
            height="30"
          />
        ) : (
          <ThumbsUp />
        ),
      },
      {
        title: getResource(data, "CJ_assuranceSection_thirdTitle"),
        text: getResource(data, "CJ_assuranceSection_thirdParagraph"),
        imageStyle: assuranceSectionItems[2]?.iconStyle,
        image: assuranceSectionItems?.[2]?.icon ? (
          <img
            src={formatBackgroundUrl(assuranceSectionItems[2].icon)}
            width="30"
            height="30"
          />
        ) : (
          <Clock />
        ),
      },
    ];
  }

  return (
    <AssuranceItemsContainer $position={getItemsPosition(theme)}>
      {items?.map((item, index: number) => {
        return (
          <AssuranceItem
            key={index}
            itemIndex={index}
            title={item.title}
            text={item.text}
            image={item.image}
            imageStyle={item.imageStyle}
          />
        );
      })}
    </AssuranceItemsContainer>
  );
}

export default AssuranceItems;
