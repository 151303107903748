import { useContext } from "react";
import styled, { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { Header } from "../../intro/components/IntroHeaderComponent";
import { getResource } from "../../../utils/helpers";
import { ResourcesContext } from "../../../context/consumer";
import { TextResourcesContext } from "../../../context/textResourcesContext";

interface PermissionHeaderProps {
  bankName: string;
  bankLogo: string | undefined;
}

function PermissionHeader({ bankName, bankLogo }: PermissionHeaderProps) {
  const theme: any = useTheme();

  const { data } = useContext(TextResourcesContext);

  const getConsentPragraph = () => {
    let text = getResource(data, "CJ_consentScreen_paragraph");

    text = text.replaceAll("{{bankName}}", bankName);
    text = text.replaceAll("{{serviceName}}", "Experian Ireland");

    return text;
  };

  return (
    <>
      <Header
        id="consentPermission"
        className="with-custom-font page-title"
        color={theme.settings?.consent?.header?.title?.styles?.color}
      >
        {getResource(data, "CJ_consentScreen_pageTitle")}
      </Header>

      <div className="d-flex align-items-center">
        <img src={bankLogo} width="60" height="60" alt="" />
        <p className="font-weight-bold mb-0 ml-3">{bankName}</p>
      </div>
      <p
        id="consentHeader"
        style={{
          ...theme.settings?.consent?.header?.subTitle?.styles,
          color: theme.colors.text.toString(),
        }}
      >
        {getConsentPragraph()}
      </p>
    </>
  );
}

export default PermissionHeader;
