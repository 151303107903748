import { AxiosResponse } from "axios";
import { useState, useCallback } from "react";
import api from "../utils/api";
import { Bank } from "./useBanksList";
import { useError } from "./useError";
import { useOrchestrationToken } from "./useOrchestrationToken";

const useAddMissingBank = (onInit = true) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useError();

  const [bankName, setBankName] = useState<any>(null);
  const [country, setCountry] = useState<any>(null);

  const orchestrationToken = useOrchestrationToken();

  const refetch = async () => {
    setLoading(true);
    setError!(null);
    addMissingBank();
  };
  const addMissingBank = useCallback(async () => {
    try {
      setLoading(true);

      await addBank(orchestrationToken, bankName, country);
    } catch (error) {
      setError!(error);
    } finally {
      setLoading(false);
    }
  }, [bankName, country, orchestrationToken, setError]);

  return {
    loading,
    error,
    bankName,
    setBankName,
    country,
    setCountry,
    refetch,
    addMissingBank,
  };
};

const addBank = (
  orchestrationToken: string,
  bankName: string | null,
  country: string | null
): Promise<AxiosResponse<Bank[]>> => {
  console.log({ bankName, country });
  return api.post(`/api/orchestrations/${orchestrationToken}/Providers`, {
    name: bankName,
    country,
  });
};

export { useAddMissingBank };
