import { useContext } from "react";
import { ResourcesContext } from "../context/consumer";
import { BootstrapResponse } from "./useBootstrap";

const useLender = () => {
  const value = useContext(ResourcesContext) as Record<
    "resources",
    BootstrapResponse
  >;

  if (value.resources === null) {
    return { logoUrl: "", url: "", name: "", supportPage: "" };
  }
  const {
    resources: {
      lender: {
        logoUrl = "",
        url = "",
        name = "",
        customerSupportEmail = "",
        supportPage = "",
        singleConsentMode = false,
        lenderPrivacyPolicyLink,
      },
    },
  } = value;
  return {
    url,
    logoUrl,
    name,
    customerSupportEmail,
    singleConsentMode,
    supportPage,
    lenderPrivacyPolicyLink,
  };
};

export { useLender };
