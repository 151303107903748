import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
import { useTranslation, Trans } from "react-i18next";
import styled, { useTheme } from "styled-components/macro";
import { useHistory, useLocation } from "react-router";
import parse from "html-react-parser";
//contexts
import { SnackbarContext } from "../../context/snackbar";
import { SelectedBankContext } from "../../context/selectedBank";
import { ResourcesContext } from "../../context/consumer";
import { ConsentedBanksContext } from "../../context/consentedBanks";

//hooks
import { Bank, useBanksList } from "../../hooks/useBanksList";
import { useAddConsent } from "../../hooks/useAddConsent";
import { useConsumer } from "../../hooks/useConsumer";
import { Consent, useConsentedBanksList } from "../../hooks/useConsentedBanks";
import useMediaQuery from "../../hooks/useMediaQuery";

//layout components
import {
  ScreenContentWrapper,
  Footer as FooterContainer,
  ScreenContainer,
  Content,
} from "../layout/screens.layout";

//components
import TryAgain from "../../components/tryagain/tryagain.component";
import Progressbar from "../../components/progressbar/progressbar.component";
import {
  Input,
  InputContainer,
  Select,
} from "../../components/select/select.bank";
import { LoadableButton } from "../../components/button/loadable-button.component";
import Loading from "../../components/loading/loading.component";
import WaitForBankData from "./components/WaitForBankData";
//utils
import { scrollTop } from "../../utils/scrollTop";
import { useOrchestrationToken } from "../../hooks/useOrchestrationToken";

//hooks
import { useLender } from "../../hooks/useLender";
import { useLockConsents } from "../../hooks/useLockConsents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { LabelContainer } from "../../components/select/select.bank";
import { darken } from "polished";
import Navbar from "../../components/navbar/navbar.component";
import { ReactComponent as BuildingIcon } from "../../assets/building.svg";
import { ReactComponent as SuccessBankIcon } from "../../assets/successBankIcon.svg";
import { ReactComponent as FailedBankIcon } from "../../assets/failedBankIcon.svg";
import {
  getModalCloseIcon,
  getModalIcon,
  getResource,
  getTextWithPopulatePlaceholders,
} from "../../utils/helpers";
import Modal from "../../components/modal/Modal";
import ExitButton from "../../components/select/exitButton";
import { useExitJourney } from "../../hooks/useExitJourney";
import { Header } from "../intro/components/IntroHeaderComponent";
import AssuranceItems from "../../components/assuranceSection/AssuranceItems";
import AddMissingBank from "./components/AddMissingBank";
import { useAddMissingBank } from "../../hooks/useAddMissingBank";
import useScreenSize from "../../hooks/useScreenSize";
import { TextResourcesContext } from "../../context/textResourcesContext";
import { ReactComponent as MissingBankModalIcon } from "../../assets/missing_bank.svg";
import { ReactComponent as ErrorModalIcon } from "../../assets/error_modal_icon.svg";
import { ReactComponent as SuccessModalIcon } from "../../assets/success_modal_icon.svg";
import { ReactComponent as FinishModalIcon } from "../../assets/finish_modal_icon.svg";

const ProgressbarContainer = styled.div``;

const HeaderContainer = styled.div`
  font-size: 1.6rem;
  line-height: 2rem;
  margin-bottom: 12px;
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    margin-bottom: 0.5rem;
  }
  @media (max-width: 640px) {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
`;

const SelectContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
  //   border: 1px solid #cfcfcf;
  border-radius: 8px;
  padding: 0 15px;
`;

const ErrorContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
`;

export const Button = styled(LoadableButton)`
  /* padding: 1rem 9rem !important; */
`;

const ButtonsFooterContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  & .btn {
    padding: 0.8rem 3rem;
    margin-top: 0;
    max-width: calc(50% - 8px);
  }
`;

export const ModalWrapper = styled.div`
  position: fixed;
  z-index: 1001;
  top: 0px !important;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
  display: none;

  &.visible {
    display: block;
  }

  .modal-body {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 85%;
    max-width: 530px;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.7);
    padding: 2rem 2vw;
    h4 {
      font-size: 20px;
      width: 100%;
      text-align: center;
      margin: 5px auto 15px auto;
    }

    .btns-wrapper {
      display: flex;
      margin: 10px auto 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      button {
        margin: 0 5px;
      }
    }
  }

  .modal-header {
    display: flex;
    justify-content: center;

    .close-btn {
      cursor: pointer;
      position: absolute;
      right: 2rem;
      top: 2rem;
    }
  }
`;

const InteractionContainer = styled.div`
  border: 1px solid #cfcfcf;
  //   border-radius: 8px;
  margin-bottom: 20px;
  margin-top: 2rem;
`;

const DetailsSection = styled.div`
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-direction: column;

  padding: 15px 5px 0;
  // span {
  //   color: ${(props) => props.theme.colors.text};
  // }
  .questions {
    display: flex;
    margin-top: 1rem;
    position: relative;

    @media (max-width: 425px) {
      flex-direction: column;
    }
    .btn {
      line-height: 20px;
      margin-left: auto;
      max-width: max-content;
      @media (max-width: 425px) {
        width: 100%;
      }
    }
  }

  .question-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    margin-bottom: 10px;
    > span {
      padding-right: 10px;
      text-align: justify;
    }
  }
  .bank-info {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .bank-logo {
    width: 42px;
    height: 42px;
    margin-bottom: 10px;
  }

  .bank-name {
    margin-bottom: 10px;
    font-weight: bold;
  }

  .btns-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .btn {
    line-height: 20px;
    margin-left: auto;
    max-width: max-content;
  }
`;

const StatusSection = styled.div`
  font-size: 16px;

  padding: 0 10px;
  flex-direction: column;
  display: flex;
  // span {
  //   color: ${(props) => props.theme.colors.text};
  // }
  .bank-country-name {
    position: absolute;
    right: 1rem;
    top: -1rem;
    font-style: italic;
  }
  div.d-flex {
    // border-bottom: 1px solid #888;
    width: 100%;

    p {
      display: flex;
      min-height: 40px;
      align-items: center;
      @media (max-width: 425px) {
        padding-left: 0px;
      }
    }
  }
  @media (max-width: 425px) {
    padding: 0px 15px;
  }
`;

const Panel = styled.div`
  border: ${(props) => `1px solid ${props.theme.colors.icons.default}`};
  border-radius: 20px;
  padding: 20px 10px;
  position: relative;
  .icon-wrapper {
    background: ${(props) => props.theme.colors.body};
    padding: 0;
  }
`;

const BankInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;

  .input-wrapper {
    min-width: 300px;
  }
`;
interface Props {
  shouldRedirect: boolean;
}

const SelectScreenV2: React.FC<Props> = ({ shouldRedirect = false }) => {
  const { type: consumerType } = useConsumer();
  const history = useHistory<any>();
  const [selectedBank, setSelectedBank] = useState<Bank | null>(null);
  const { openSnackbar } = useContext(SnackbarContext);
  const { setBank, bank } = useContext(SelectedBankContext);
  const [bankName, setBankName] = useState<string | null>(null);
  const { t, i18n } = useTranslation();
  const {
    bankName: missingBank,
    country,
    setBankName: setMissingBank,
    setCountry,
    addMissingBank,
  } = useAddMissingBank();
  const orchestrationToken = useOrchestrationToken();
  const [showZoomedImg, setShowZoomedImg] = useState(false);
  const [showBankIsNotListedModal, setShowBankIsNotListedModal] =
    useState(false);
  const isMobile = useMediaQuery("(max-width: 425px)");
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const screenSize = useScreenSize();
  const resume = query.get("resume");
  const { name: lenderName } = useLender();
  const { resources } = useContext(ResourcesContext);
  const { consentedBanks } = useContext(ConsentedBanksContext);
  const { loading: loadingConsentedBanksList } = useConsentedBanksList();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);

  const [showSelectPanel, setShowSelectPanel] = useState(false);
  const [showWaitForData, setWaitForData] = useState(true);
  const [shouldShowWaitForData, setShouldShowWaitForData] = useState(true);

  const [modalPrimaryBankIndicatorOpen, setModalPrimaryBankIndicatorOpen] =
    useState<boolean>(false);

  const [jointAccountIndicator, setJointAccountIndicator] = useState<
    boolean | null | undefined
  >(null);
  const [primaryBankIndicator, setPrimaryBankIndicator] = useState<
    boolean | null | undefined
  >(null);
  const [primaryYesClicked, setPrimaryYesClicked] = useState<boolean>(false);
  const [primaryNoClicked, setPrimaryNoClicked] = useState<boolean>(false);

  const { data } = useContext(TextResourcesContext);
  const modalRef = useRef(null);
  const modalRef2 = useRef(null);
  const modalRef3 = useRef(null);
  const modalRef4 = useRef(null);

  const theme: any = useTheme();

  console.log({ theme });

  const {
    loading,
    error,
    banks = [],
    refetch,
  } = useBanksList(resources?.processCompleted);
  const { execute: exitJourney, loading: exitJourneyLoading } =
    useExitJourney(orchestrationToken);
  const { url, singleConsentMode } = useLender();
  const [providers, setProviders] = useState<Bank[]>([]);

  useEffect(() => {
    setProviders(banks);
  }, [banks]);

  const lockConsents = useLockConsents(orchestrationToken);
  useEffect(() => {
    document.title = getResource(data, "CJ_selectScreen_tabTitle");
  }, [data]);
  const {
    loading: loadingLockConsent,
    error: errorConsent,
    clearError: clearConsentError,
    execute,
  } = useAddConsent(orchestrationToken);

  useEffect(() => {
    if (error || errorConsent) {
      setSelectedBank(null);
      setBank(null);
      openSnackbar(true);
    }
  }, [error, errorConsent, openSnackbar, setBank, resources]);

  const myRef = useRef<null | HTMLDivElement>(null);

  const checkIfCanBeSelected = (
    selectedBank: Bank | null
  ): boolean | undefined => {
    const inConsents = consentedBanks.find(
      (c) => c.providerCode === selectedBank?.providerCode
    );
    return (
      ["Completed", "Processing"].includes(inConsents?.status || "") ||
      loadingLockConsent ||
      (selectedBank === null && !loadingLockConsent) ||
      !!error ||
      !!errorConsent ||
      (hasCompleted > 0 && singleConsentMode)
    );
  };

  const onSelect = (bank: Bank | null) => {
    console.log({ onSelect: bank });
    errorConsent && clearConsentError();
    if (!checkIfCanBeSelected(bank) && !selectedBank) {
      setSelectedBank(bank);
      setBank(bank);
      execute(bank!.providerCode, null, null, (consentToken: string) => {
        scrollTop(() =>
          history.push(`/consent?orchestrationToken=${orchestrationToken}`, {
            consentToken,
          })
        );
      });
    }

    setTimeout(() => {
      myRef?.current?.scrollIntoView(true);
    }, 50);
  };
  const timeout = useRef<any>(null);

  const hasProcessing = useMemo(() => {
    return consentedBanks.filter((bank) => bank?.status === "Processing")
      .length;
  }, [consentedBanks]);
  const hasError = useMemo(() => {
    return consentedBanks.filter((bank) => bank?.status === "Error").length;
  }, [consentedBanks]);
  const hasCompleted = useMemo(() => {
    return consentedBanks.filter((bank) => bank?.status === "Completed").length;
  }, [consentedBanks]);

  useEffect(() => {
    if (
      !lockConsents.loading &&
      lockConsents &&
      hasCompleted > 0 &&
      singleConsentMode
    ) {
      lockConsents.execute(() => {
        document.body.style.overflow = "auto";
        setWaitForData(false);
        history.push(`/complete?orchestrationToken=${orchestrationToken}`);
      });
    }
  }, [
    lockConsents,
    history,
    hasCompleted,
    singleConsentMode,
    orchestrationToken,
  ]);
  const lastProcessedBank = useMemo(() => {
    if (consentedBanks.length) {
      return consentedBanks[0].status;
    }
  }, [consentedBanks]);
  useEffect(() => {
    if (!loadingConsentedBanksList) {
      if (consentedBanks.length === 0) {
        console.log({ consentedBanks });

        setShowSelectPanel(true);
      } else {
        shouldShowWaitForData &&
          lastProcessedBank === "Processing" &&
          setWaitForData(true);

        if (lastProcessedBank === "Error") {
          setTimeout(() => {
            setShowSelectPanel(true);
            setWaitForData(false);
          }, 1000);
        }

        if (!singleConsentMode) {
          if (
            lastProcessedBank === "Completed" ||
            lastProcessedBank === "Error"
          ) {
            setTimeout(() => {
              setShowSelectPanel(true);
              setWaitForData(false);
            }, 1000);
          }
        }
      }
    }
  }, [
    singleConsentMode,
    lastProcessedBank,
    consentedBanks,
    loadingConsentedBanksList,
  ]);

  useEffect(() => {
    if (hasProcessing) {
      timeout.current = window.setInterval(() => {
        refetch(true);
      }, 5000);
    }

    return () => clearInterval(timeout.current);
  }, [hasProcessing, refetch]);

  const completedBanksLength = useMemo(() => {
    return consentedBanks.filter((bank: any) => bank?.status === "Completed")
      .length;
  }, [consentedBanks]);

  useEffect(() => {
    lastProcessedBank === "Error" &&
      setTimeout(() => {
        setErrorModal(true);
      }, 1000);
  }, [lastProcessedBank, setErrorModal]);

  useEffect(() => {
    !singleConsentMode &&
      lastProcessedBank === "Completed" &&
      setTimeout(() => {
        setSuccessModal(true);
      }, 1000);
  }, [hasCompleted, setSuccessModal]);

  const addToLocalStorage = (id: string, status: string) => {
    window.sessionStorage.setItem(
      id,
      JSON.stringify({ status, disabled: true })
    );
  };

  const checkIfExistsInLocalStorage = (id: string, status: string): boolean => {
    if (window.sessionStorage.getItem(id)) {
    }
    const item =
      window.sessionStorage.getItem(id) !== null &&
      JSON.parse(window.sessionStorage.getItem(id) || "");

    if (item?.status === status && item?.disabled) {
      return false;
    }
    return true;
  };

  const checkIfErrorExists = (errorMsg: any) => {
    return i18n.exists(`errors.${errorMsg}`)
      ? `errors.${errorMsg}`
      : `errors.UnknownError`;
  };
  const renderHeader = useMemo(() => {
    if (!banks.length) return null;

    return (
      <HeaderContainer id="selectHeaderConsumer">
        {/* //NO BANKS */}
        {!consentedBanks.length && (
          <>
            {theme.settings.select?.searchForYourProviderText?.visible && (
              <Header>
                <span className="with-custom-font page-title">
                  {theme.settings?.select?.texts?.titleSelect
                    ? getTextWithPopulatePlaceholders(
                        theme.settings.select.texts.titleSelect,
                        resources
                      )
                    : t("selectScreen.search")}
                </span>
              </Header>
            )}
            {resume?.toLowerCase() === "true" && (
              <Trans i18nKey="selectScreen.welcomeBack">
                <p></p>
              </Trans>
            )}

            {theme.settings.select?.texts?.pleaseSelectProvider ? (
              getTextWithPopulatePlaceholders(
                theme.settings.select.texts.pleaseSelectProvider,
                resources
              )
            ) : (
              <Trans i18nKey="selectScreen.pleaseSelectProvider">
                <p className="test">
                  Please select the bank account provider you are giving access
                  to.
                </p>
              </Trans>
            )}

            {theme.settings.select?.texts?.titleCJconsumerNoBanks &&
              getTextWithPopulatePlaceholders(
                theme.settings.select.texts.titleCJconsumerNoBanks,
                resources
              )}
            {!theme.settings.select?.texts?.titleCJconsumerNoBanks && (
              <Trans i18nKey="selectScreen.titleCJconsumerNoBanks">
                <p className="test">
                  It's best to make sure you are providing access to the bank
                  accounts in which you receive income and use for payments.
                </p>
              </Trans>
            )}

            {!singleConsentMode &&
              theme.settings.select?.texts?.moreThanOneProvider &&
              getTextWithPopulatePlaceholders(
                theme.settings.select.texts.moreThanOneProvider,
                resources
              )}
            {!singleConsentMode &&
              !theme.settings.select?.texts?.moreThanOneProvider && (
                <Trans i18nKey="selectScreen.moreThanOneProvider">
                  <p className="test">
                    If you use more than one provider, you`ll be able to add
                    them later.
                  </p>
                </Trans>
              )}
          </>
        )}

        {/* //IF COMPLETED OR Processing*/}

        {(lastProcessedBank === "Completed" ||
          lastProcessedBank === "Processing") && (
          <>
            <div className="d-flex align-items-center my-3">
              <SuccessBankIcon
                className="mr-3"
                style={{ minWidth: "2.7rem" }}
              />
              <p>
                {t(
                  singleConsentMode
                    ? "selectScreen.titleCJconsumerHasBanks_with_singleConsentMode"
                    : "selectScreen.titleCJconsumerHasBanks",
                  { bankName }
                )}
              </p>
            </div>
          </>
        )}

        {lastProcessedBank === "Error" && (
          //IF FAIL
          <div className="d-flex align-items-center my-3">
            <FailedBankIcon className="mr-3" style={{ minWidth: "2.7rem" }} />
            <p className="mb-0">
              {t("selectScreen.titleCJconsumerHasBanks_FailBank.first", {
                bankName,
              })}
              <br />

              {t(
                checkIfErrorExists(
                  consentedBanks.filter((bank) => bank?.status === "Error")[0]
                    ?.errorMessage
                )
              )}
            </p>
          </div>
          //
        )}
      </HeaderContainer>
    );
  }, [banks, completedBanksLength, bankName]);

  const isCompletedOrProcessing = (selectedBank: Bank | null) => {
    let status = consentedBanks.find(
      (bank: Consent) => bank.providerCode === selectedBank?.providerCode
    )?.status;

    let completed =
      !!selectedBank && ["Completed", "Processing"].includes(status || "");

    return completed;
  };

  const completedBank = (selectedBank: Bank | null) => {
    let status = consentedBanks.find(
      (bank: Consent) => bank.providerCode === selectedBank?.providerCode
    )?.status;
    if (status === "Completed") return true;

    return false;
  };
  useEffect(() => {
    if (selectedBank) {
      const selectedBankInConsents = consentedBanks.find(
        (consentedBank) => consentedBank?.providerCode === bank?.providerCode
      );
      if (selectedBankInConsents) {
        setJointAccountIndicator(selectedBankInConsents?.jointAccountIndicator);
        setPrimaryBankIndicator(selectedBankInConsents?.primaryBankIndicator);
      } else {
        setJointAccountIndicator(null);
        setPrimaryBankIndicator(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBank]);

  useEffect(() => {
    consentedBanks.length && setBankName(consentedBanks[0].name);
  }, [consentedBanks]);

  useEffect(() => {
    if (shouldRedirect) {
      window.location.href = url;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCompletedPercentages = () => {
    setShouldShowWaitForData(false);
    !singleConsentMode && setWaitForData(false);
    !singleConsentMode && setShowSelectPanel(true);
  };
  const getMessageByStatus = (selectedBankInConsents: any, errorMsg: any) => {
    if (selectedBankInConsents?.status === "Processing") {
      return "selectScreen.bankProcessing";
    }
    if (selectedBankInConsents?.status === "Error") {
      return checkIfErrorExists(errorMsg);
    }
    return "selectScreen.bankAdded";
  };

  const getStyleByStatus = (selectedBankInConsents: { status: string }) => {
    if (selectedBankInConsents?.status === "Processing") {
      return darken(0.5, theme?.colors.banks.processingBankBackground);
    }
    if (selectedBankInConsents?.status === "Error") {
      return darken(0.5, theme?.colors.banks.failedBankBackground);
    }
    return darken(0.5, theme?.colors.banks.successBankBackground);
  };

  return loadingConsentedBanksList ? (
    <Loading />
  ) : (
    <ScreenContentWrapper
      $currentScreenWidth={screenSize.width}
      style={{
        ...theme.settings.screenContainer,
        minHeight: "auto",
        minWidth: !showSelectPanel ? "50vw" : null,
      }}
    >
      {theme?.settings.customBackground ? (
        <div
          className="custom-bg"
          style={theme.settings.customBackground?.styles}
        >
          {" "}
        </div>
      ) : null}
      <div className="w-100">
        <ScreenContainer style={{ minHeight: "auto" }}>
          <Navbar />
          <ProgressbarContainer>
            <Progressbar isManage={consentedBanks.length > 0} />
          </ProgressbarContainer>
          {consentedBanks.length > 0 && showWaitForData ? (
            <WaitForBankData
              hasProcessing={hasProcessing}
              lastProcessedBankStatus={lastProcessedBank}
              onCompletedPercentages={onCompletedPercentages}
            />
          ) : null}

          {showSelectPanel && (
            <>
              <Content className="mt-4">
                <div className="row mx-0">
                  <div className="col-12 col-md-5">{renderHeader}</div>

                  <Panel className="col-12 col-md-7 mt-3 pb-0">
                    <div
                      style={{
                        position: "absolute",
                        top: "-25px",
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        left: 0,
                      }}
                    >
                      <div className="icon-wrapper">
                        <BuildingIcon style={{ width: "40px" }} />
                      </div>
                    </div>
                    <SelectContainer>
                      <span ref={myRef}></span>
                      <Select
                        id="selectBank"
                        value={selectedBank}
                        consents={consentedBanks}
                        singleConsentMode={singleConsentMode as boolean}
                        options={providers.length > 0 ? providers : banks}
                        placeholder={""}
                        onGroupSelected={(groupCode) => {
                          setProviders(
                            banks.filter((bank) => bank.groupCode === groupCode)
                          );
                        }}
                        onGroupUnselected={() => {
                          setProviders([]);
                        }}
                        fieldPlaceholder={
                          theme.settings?.select?.texts?.selectBankInputDefault
                            ? getTextWithPopulatePlaceholders(
                                theme.settings.select.texts
                                  .selectBankInputDefault,
                                resources
                              )
                            : t("selectScreen.type")
                        }
                        onSelect={onSelect}
                        isLoading={hasProcessing ? false : loading}
                        onSelectLoading={loadingLockConsent}
                      />
                      {hasCompleted == 0 && (
                        <Content className="my-0 w-100">
                          <p>
                            <span
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                                ...theme?.settings?.select?.texts
                                  ?.myBankIsNotListedButton?.styles,
                              }}
                              onClick={() => {
                                setShowBankIsNotListedModal(true);
                                const ref: any = modalRef3;
                                if (ref?.current) {
                                  ref.current.style.top = `${
                                    (window.pageYOffset ||
                                      document.documentElement.scrollTop ||
                                      0) - 76
                                  }px`;
                                }
                                document.body.style.overflow = "hidden";
                              }}
                            >
                              {theme.settings?.select?.texts?.bankNotListedLink
                                ? getTextWithPopulatePlaceholders(
                                    theme.settings.select.texts
                                      .bankNotListedLink,
                                    resources
                                  )
                                : t("selectScreen.bankIsNotListedLink")}
                            </span>
                          </p>
                        </Content>
                      )}
                      {error && (
                        <ErrorContainer>
                          <TryAgain refetch={() => refetch(false)} />
                        </ErrorContainer>
                      )}
                      {/* {selectedBank && (
                      <InteractionContainer>
                        <>
                          <DetailsSection>
                            <div className="bank-info">
                              <img
                                className="bank-logo"
                                src={selectedBank.logo}
                              />
                              <span className="bank-name">
                                {selectedBank?.name}
                              </span>
                            </div>
                          </DetailsSection>
                          <StatusSection>
                            <LabelContainer>
                              <span className="rotated">
                                {t("selectScreen.status")}
                              </span>
                            </LabelContainer>
                            <div className="d-flex position-relative">
                              <p>
                                {(() => {
                                  const selectedBankInConsents =
                                    consentedBanks.find(
                                      (consentedBank) =>
                                        consentedBank?.providerCode ===
                                        bank?.providerCode
                                    );
                                  if (selectedBankInConsents) {
                                    const bankFromProviders = banks.find(
                                      (b) =>
                                        b.providerCode ===
                                        selectedBankInConsents.providerCode
                                    );
                                    if (bankFromProviders) {
                                      const errorMsg =
                                        selectedBankInConsents?.errorMessage ||
                                        null;
                                      return (
                                        <span
                                          style={{
                                            color: getStyleByStatus(
                                              selectedBankInConsents
                                            ),
                                          }}
                                          className="mt-4"
                                        >
                                          <Trans
                                            i18nKey={getMessageByStatus(
                                              selectedBankInConsents,
                                              errorMsg
                                            )}
                                            tOptions={{
                                              bank: bankFromProviders.name,
                                            }}
                                          >
                                            <b></b>
                                          </Trans>
                                        </span>
                                      );
                                    }
                                  }

                                  return (
                                    <span
                                      className="mt-2"
                                      style={{
                                        color: darken(
                                          0.5,
                                          theme?.colors.banks
                                            .processingBankBackground
                                        ),
                                      }}
                                    >
                                      {t("selectScreen.bankHasNotBeenAdded")}
                                    </span>
                                  );
                                })()}
                              </p>
                              <span className="bank-country-name">
                                {t(
                                  `selectScreen.countryCodes.${selectedBank?.countryCode}`
                                )}
                              </span>
                            </div>
                          </StatusSection>

                          <div
                            style={{
                              padding: "0 10px 15px",
                            }}
                          >
                            <Button
                              id="selectContinue"
                              onClick={() => {
                                execute(
                                  selectedBank!.providerCode,
                                  jointAccountIndicator,
                                  primaryBankIndicator,
                                  (consentToken: string) => {
                                    scrollTop(() =>
                                      history.push(
                                        `/consent?orchestrationToken=${orchestrationToken}`,
                                        {
                                          consentToken,
                                        }
                                      )
                                    );
                                  }
                                );
                              }}
                              isLoading={
                                loadingLockConsent && !singleConsentMode
                              }
                              className="btn exp-default  text-uppercase-first-letter text-center  btn-block"
                              disabled={(() => {
                                const inConsents = consentedBanks.find(
                                  (c) =>
                                    c.providerCode ===
                                    selectedBank?.providerCode
                                );
                                return (
                                  ["Completed", "Processing"].includes(
                                    inConsents?.status || ""
                                  ) ||
                                  loadingLockConsent ||
                                  (selectedBank === null &&
                                    !loadingLockConsent) ||
                                  !!error ||
                                  !!errorConsent ||
                                  (hasCompleted > 0 && singleConsentMode)
                                );
                              })()}
                            >
                              {(() => {
                                const inConsents = consentedBanks.find(
                                  (c) =>
                                    c.providerCode ===
                                    selectedBank?.providerCode
                                );

                                if (inConsents?.status === "Error") {
                                  return t("common.retry");
                                } else {
                                  let bankName =
                                    consentedBanks.find(
                                      (c) =>
                                        c.providerCode ===
                                        selectedBank?.providerCode
                                    )?.status !== "Error" && selectedBank?.name;
                                  if (
                                    !theme.settings.select?.texts
                                      ?.proceedBankButton
                                  )
                                    return (
                                      t("common.connectTo") + " " + bankName
                                    );

                                  return getTextWithPopulatePlaceholders(
                                    theme.settings.select.texts
                                      .proceedBankButton,
                                    resources,
                                    theme.settings.select.texts
                                      .proceedBankButton.withBankName
                                      ? bankName
                                      : null
                                  );
                                }
                              })()}
                            </Button>
                          </div>
                        </>
                      </InteractionContainer>
                    )} */}
                    </SelectContainer>
                  </Panel>
                </div>
              </Content>
              {!singleConsentMode && (
                <FooterContainer
                  style={{
                    flexDirection: "column",
                    minHeight: "64px",
                    height: "auto",
                  }}
                >
                  <ButtonsFooterContainer>
                    <Button
                      id="selectFinish"
                      onClick={() => {
                        if (!singleConsentMode) {
                          setModalOpen(true);
                        } else {
                          lockConsents.execute(() => {
                            document.body.style.overflow = "auto";
                            history.push(
                              `/complete?orchestrationToken=${orchestrationToken}`
                            );
                          });
                        }
                        const ref: any = modalRef2;
                        if (ref?.current) {
                          ref.current.style.top = `${
                            (window.pageYOffset ||
                              document.documentElement.scrollTop ||
                              0) - 76
                          }px`;
                        }
                        document.body.style.overflow = "hidden";
                      }}
                      isLoading={lockConsents.loading}
                      disabled={
                        hasProcessing ? false : loading || !completedBanksLength
                      }
                      variant={
                        theme.settings?.select?.texts?.selectContinueButton
                          ?.type === "link"
                          ? "link"
                          : "primary"
                      }
                      className="btn text-uppercase-first-letter text-center  btn-block"
                    >
                      {theme.settings?.select?.texts?.selectContinueButton
                        ? getTextWithPopulatePlaceholders(
                            theme.settings.select.texts.selectContinueButton,
                            resources
                          )
                        : t("common.continue")}
                    </Button>
                  </ButtonsFooterContainer>
                </FooterContainer>
              )}
            </>
          )}

          {loading || hasProcessing || hasCompleted
            ? null
            : (!theme.settings?.select?.texts?.selectExitButton ||
                theme.settings?.select?.texts?.selectExitButton?.visible) && (
                <ExitButton
                  lenderUrl={resources?.lender?.url}
                  hasFailedBank={hasError}
                />
              )}

          {theme.settings?.assuranceSection?.visible && (
            <Content className="mt-0">
              <AssuranceItems />
            </Content>
          )}
        </ScreenContainer>
      </div>
      <Modal>
        <ModalWrapper
          className={
            errorModal &&
            checkIfExistsInLocalStorage(
              consentedBanks[0].id,
              consentedBanks[0].status
            )
              ? "visible"
              : ""
          }
          ref={modalRef3}
        >
          <div className="modal-body">
            <div className="modal-header">
              {getModalIcon(
                theme?.colors?.icons?.errorModalIcon,
                <ErrorModalIcon />
              )}

              <span
                className="close-btn"
                onClick={() => {
                  setErrorModal(false);
                  addToLocalStorage(
                    consentedBanks[0].id,
                    consentedBanks[0].status
                  );
                  document.body.style.overflow = "auto";
                }}
              >
                {getModalCloseIcon(
                  theme?.colors?.icons?.modalCloseIcon,
                  theme?.colors?.icons?.default
                )}
              </span>
            </div>
            <div>
              <h4>
                <span className="modal-title">
                  {t("selectScreen.titleCJconsumerHasBanks_FailBank.first", {
                    bankName: (() => {
                      return consentedBanks.filter(
                        (bank) => bank?.status === "Error"
                      )[0]?.name;
                    })(),
                  })}
                  <br />
                  {t(
                    checkIfErrorExists(
                      consentedBanks.filter(
                        (bank) => bank?.status === "Error"
                      )[0]?.errorMessage
                    )
                  )}
                </span>

                <br />
                <br />
                <span className="modal-subtitle">
                  {t("selectScreen.titleCJconsumerHasBanks_FailBank.second")}
                </span>
              </h4>
            </div>
            <div className="btns-wrapper">
              <Button
                id="cancelBtn"
                disabled={false}
                isLoading={loadingLockConsent}
                onClick={() => {
                  const errorBankProviderCode = consentedBanks.filter(
                    (bank) => bank?.status === "Error"
                  )[0].providerCode;
                  const bank = banks.filter(
                    (bank) => bank.providerCode === errorBankProviderCode
                  )[0];
                  setBank(bank);
                  addToLocalStorage(
                    consentedBanks[0].id,
                    consentedBanks[0].status
                  );
                  execute(
                    errorBankProviderCode,
                    jointAccountIndicator,
                    primaryBankIndicator,
                    (consentToken: string) => {
                      scrollTop(() =>
                        history.push(
                          `/consent?orchestrationToken=${orchestrationToken}`,
                          {
                            consentToken,
                          }
                        )
                      );
                    }
                  );
                }}
                variant={
                  theme.settings?.select?.texts?.failedAddedBankModal
                    ?.retryButton?.type === "link"
                    ? "link"
                    : "primary"
                }
                className="btn text-uppercase-first-letter text-center bg-white btn-block"
              >
                {theme.settings?.select?.texts?.failedAddedBankModal
                  ?.retryButton
                  ? getTextWithPopulatePlaceholders(
                      theme.settings.select.texts.failedAddedBankModal
                        .retryButton,
                      resources
                    )
                  : t("common.retry")}
              </Button>
              <Button
                id="continueOrExitBtn"
                isLoading={lockConsents.loading}
                disabled={loadingLockConsent}
                onClick={() => {
                  setErrorModal(false);
                  addToLocalStorage(
                    consentedBanks[0].id,
                    consentedBanks[0].status
                  );
                  document.body.style.overflow = "auto";
                }}
                variant={
                  theme.settings?.select?.texts?.failedAddedBankModal
                    ?.tryAnotherBankButton?.type === "link"
                    ? "link"
                    : "primary"
                }
                className="btn text-uppercase-first-letter text-center bg-white btn-block"
              >
                {" "}
                {theme.settings?.select?.texts?.failedAddedBankModal
                  ?.tryAnotherBankButton
                  ? getTextWithPopulatePlaceholders(
                      theme.settings.select.texts.failedAddedBankModal
                        .tryAnotherBankButton,
                      resources
                    )
                  : t("selectScreen.tryAnotherBank")}
              </Button>
            </div>
          </div>
        </ModalWrapper>
        <ModalWrapper
          className={
            !singleConsentMode &&
            successModal &&
            checkIfExistsInLocalStorage(
              consentedBanks[0].id,
              consentedBanks[0].status
            )
              ? "visible"
              : ""
          }
          ref={modalRef3}
        >
          <div className="modal-body">
            <div className="modal-header">
              {getModalIcon(
                theme?.colors?.icons?.successModalIcon,
                <SuccessModalIcon />
              )}

              <span
                className="close-btn"
                onClick={() => {
                  setSuccessModal(false);
                  addToLocalStorage(
                    consentedBanks[0].id,
                    consentedBanks[0].status
                  );
                  document.body.style.overflow = "auto";
                }}
              >
                {getModalCloseIcon(
                  theme?.colors?.icons?.modalCloseIcon,
                  theme?.colors?.icons?.default
                )}
              </span>
            </div>
            <div className="d-flex flex-column" style={{ margin: "auto" }}>
              <h4 style={{ whiteSpace: "pre-line", lineHeight: "1.5rem" }}>
                <span className="modal-title">
                  {t("selectScreen.bankAdded.title", {
                    bank: consentedBanks?.[0]?.name,
                  })}
                </span>
                <br />
                <br />
                <span className="modal-subtitle">
                  {t("selectScreen.bankAdded.subTitle")}
                </span>
              </h4>
            </div>
            <div className="btns-wrapper">
              <Button
                id="cancelBtn"
                disabled={false}
                isLoading={false}
                onClick={() => {
                  setSuccessModal(false);
                  addToLocalStorage(
                    consentedBanks[0].id,
                    consentedBanks[0].status
                  );
                  document.body.style.overflow = "auto";
                }}
                variant={
                  theme.settings?.select?.texts?.successAddedBankModal
                    ?.addAnotherBankButon?.type == "link"
                    ? "link"
                    : "primary"
                }
                className="btn text-uppercase-first-letter text-center bg-white btn-block"
              >
                {theme.settings?.select?.texts?.successAddedBankModal
                  ?.addAnotherBankButon
                  ? getTextWithPopulatePlaceholders(
                      theme.settings.select.texts.successAddedBankModal
                        .addAnotherBankButon,
                      resources
                    )
                  : t("selectScreen.addBank")}
              </Button>
              <Button
                id="continueOrExitBtn"
                isLoading={lockConsents.loading}
                disabled={lockConsents.loading}
                onClick={() => {
                  lockConsents.execute(() => {
                    document.body.style.overflow = "auto";
                    history.push(
                      `/complete?orchestrationToken=${orchestrationToken}`
                    );
                  });
                }}
                variant={
                  theme.settings?.select?.texts?.successAddedBankModal
                    ?.finishButon?.type == "link"
                    ? "link"
                    : "primary"
                }
                className="btn text-uppercase-first-letter text-center bg-white btn-block"
              >
                {theme.settings?.select?.texts?.successAddedBankModal
                  ?.finishButon
                  ? getTextWithPopulatePlaceholders(
                      theme.settings.select.texts.successAddedBankModal
                        .finishButon,
                      resources
                    )
                  : t("common.finish")}
              </Button>
            </div>
          </div>
        </ModalWrapper>
        <ModalWrapper
          className={showBankIsNotListedModal ? "visible" : ""}
          ref={modalRef3}
        >
          <div className="modal-body">
            <div className="modal-header">
              {getModalIcon(
                theme?.colors?.icons?.missingBankModalIcon,
                <MissingBankModalIcon />
              )}

              <span
                className="close-btn"
                onClick={() => {
                  setShowBankIsNotListedModal(false);
                  document.body.style.overflow = "auto";
                }}
              >
                {getModalCloseIcon(
                  theme?.colors?.icons?.modalCloseIcon,
                  theme?.colors?.icons?.default
                )}
              </span>
            </div>
            <div>
              <h4 style={{ whiteSpace: "pre-line", lineHeight: "1.5rem" }}>
                <span className="modal-title">
                  {theme.settings?.select?.texts?.bankNotListedTitle
                    ? getTextWithPopulatePlaceholders(
                        theme.settings.select.texts.bankNotListedTitle,
                        resources
                      )
                    : t("selectScreen.bankIsNotListedTitle")}
                </span>
                <br />
                <br />
                <span className="modal-subtitle">
                  {theme.settings?.select?.texts?.bankNotListedQuestion
                    ? getTextWithPopulatePlaceholders(
                        theme.settings.select.texts.bankNotListedQuestion,
                        resources
                      )
                    : t("selectScreen.bankIsNotListedHeader")}
                </span>
              </h4>
            </div>
            <AddMissingBank
              country={country}
              setBankName={setMissingBank}
              setCountry={setCountry}
            />
            <div className="btns-wrapper">
              <Button
                id="continueBtn"
                isLoading={exitJourneyLoading}
                disabled={exitJourneyLoading}
                onClick={() => {
                  missingBank && addMissingBank();
                  setShowBankIsNotListedModal(false);
                  document.body.style.overflow = "auto";
                }}
                variant={
                  theme.settings.select?.texts
                    ?.selectTryAnotherBankContinueButton?.type === "link"
                    ? "link"
                    : "secondary"
                }
                className="btn text-uppercase-first-letter text-center bg-white btn-block"
              >
                {theme.settings?.select?.texts
                  ?.selectTryAnotherBankContinueButton ? (
                  getTextWithPopulatePlaceholders(
                    theme.settings.select.texts
                      .selectTryAnotherBankContinueButton,
                    resources
                  )
                ) : (
                  <Trans
                    i18nKey="common.continue"
                    tOptions={{
                      lenderName,
                    }}
                  >
                    <b></b>
                  </Trans>
                )}
              </Button>

              <Button
                id="ExitBtn"
                isLoading={exitJourneyLoading}
                disabled={exitJourneyLoading}
                onClick={() => {
                  missingBank && addMissingBank();
                  !resources?.lender.url
                    ? exitJourney(
                        () =>
                          history.push(
                            `/finish?orchestrationToken=${orchestrationToken}`,
                            { status: "fail" }
                          ),
                        "BankProviderNotFound",
                        "ProcessingFailed"
                      )
                    : exitJourney(
                        () => window.open(resources?.lender.url, "_self"),
                        "BankProviderNotFound",
                        "ProcessingFailed"
                      );
                }}
                variant={
                  theme.settings.select?.texts?.selectTryAnotherBankExitButton
                    ?.type === "link"
                    ? "link"
                    : "primary"
                }
                className="btn text-uppercase-first-letter text-center bg-white btn-block"
              >
                {theme.settings?.select?.texts
                  ?.selectTryAnotherBankExitButton ? (
                  getTextWithPopulatePlaceholders(
                    theme.settings.select.texts.selectTryAnotherBankExitButton,
                    resources
                  )
                ) : (
                  <Trans
                    i18nKey="selectScreen.submitAndExit"
                    tOptions={{
                      lenderName,
                    }}
                  >
                    <b></b>
                  </Trans>
                )}
              </Button>
            </div>
          </div>
        </ModalWrapper>
        <ModalWrapper className={modalOpen ? "visible" : ""} ref={modalRef2}>
          <div className="modal-body">
            <div className="modal-header">
              {getModalIcon(
                theme?.colors?.icons?.finishModalIcon,
                <FinishModalIcon />
              )}

              <span
                className="close-btn"
                onClick={() => {
                  setModalOpen(false);
                  document.body.style.overflow = "auto";
                }}
              >
                {getModalCloseIcon(
                  theme?.colors?.icons?.modalCloseIcon,
                  theme?.colors?.icons?.default
                )}
              </span>
            </div>
            <div>
              <h4 className="modal-title">{t("selectScreen.modalQuestion")}</h4>
            </div>
            <div className="btns-wrapper">
              <Button
                id="finishYes"
                disabled={lockConsents.loading}
                isLoading={lockConsents.loading}
                onClick={() =>
                  lockConsents.execute(() => {
                    document.body.style.overflow = "auto";
                    history.push(
                      `/complete?orchestrationToken=${orchestrationToken}`
                    );
                  })
                }
                variant={
                  theme.settings?.select?.texts?.selectYesFinishButton?.type ===
                  "link"
                    ? "link"
                    : "primary"
                }
                className="btn text-uppercase-first-letter text-center bg-white btn-block"
              >
                {theme.settings?.select?.texts?.selectYesFinishButton
                  ? getTextWithPopulatePlaceholders(
                      theme.settings.select.texts.selectYesFinishButton,
                      resources
                    )
                  : t("common.yes")}
              </Button>
              <Button
                id="finishNo"
                isLoading={false}
                disabled={lockConsents.loading}
                onClick={() => {
                  setModalOpen(false);
                  document.body.style.overflow = "auto";
                }}
                variant={
                  theme.settings?.select?.texts?.selectNoFinishButton?.type ===
                  "link"
                    ? "link"
                    : "primary"
                }
                className="btn text-uppercase-first-letter text-center bg-white btn-block"
              >
                {theme.settings?.select?.texts?.selectNoFinishButton
                  ? getTextWithPopulatePlaceholders(
                      theme.settings.select.texts.selectNoFinishButton,
                      resources
                    )
                  : t("common.no")}
              </Button>
            </div>
          </div>
        </ModalWrapper>
      </Modal>
    </ScreenContentWrapper>
  );
};

export default SelectScreenV2;
