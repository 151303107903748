import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  refetch: (arg0?: boolean) => void;
}

const TryAgain: React.FC<Props> = ({ refetch }) => {
  const { t } = useTranslation();
  return (
    <>
      <br />
      <p
        className="text-danger text-center mt-5"
        style={{ cursor: "pointer" }}
        onClick={() => refetch()}
      >
        {t("common.somethingWentWrong") + "! "}
        {t("common.pleaseTryAgain") + "!"}
        <br />
        <br />

        <FontAwesomeIcon icon={faRedo} size="2x" />
      </p>
    </>
  );
};

export default TryAgain;
